<template>
  <vue-draggable-resizable
    class="d-flex align-items-center justify-content-center"
    :class="{ selected: index === activeBlockIndex }"
    @activated="onActivated(index, item)"
    :active="index === activeBlockIndex"
    :lock-aspect-ratio="item.aspectRatio"
    :style="{
      zIndex: item.zIndex,
      borderRadius: `${item.borderRadiusValue.topLeft}px ${item.borderRadiusValue.topRight}px ${item.borderRadiusValue.bottomRight}px ${item.borderRadiusValue.bottomLeft}px`,
      backgroundColor: item.style.backgroundColor,
      border:
        item.borderValue !== undefined && item.borderValue.borderWidth !== 0
          ? `${item.borderValue.borderWidth}px solid ${item.borderValue.color} !important`
          : ''
    }"
    @dragstop="onDragStop"
    @dragging="onDragging"
    @resizestop="onResizeStop"
    @resizing="onResize"
    :grid="smartAsset.grid"
    :x="item.x"
    :y="item.y"
    :w="item && item.w >= 0 ? item.w : null"
    :h="item && item.h >= 0 ? item.h : null"
  >
    <div
      class="content-editable-container content-editable w-100 h-100"
      :style="{
        borderRadius: `${item.borderRadiusValue.topLeft}px ${item.borderRadiusValue.topRight}px ${item.borderRadiusValue.bottomRight}px ${item.borderRadiusValue.bottomLeft}px`,
        color: item.textStyle.color,
        fontFamily: item.textStyle.fontFamily,
        fontWeight: item.textStyle.fontWeight,
        lineHeight: item.textStyle.lineHeight,
        letterSpacing: item.textStyle.letterSpacing,
        textShadow: item.textStyle.textShadow,
        alignItems: item.style.alignItems,
        justifyContent: item.style.justifyContent,
        flexDirection: item.style.flexDirection ? item.style.flexDirection : 'column',
        whiteSpace: 'pre-line',
        display: item.style.display ? item.style.display : 'flex',
        overflow: 'hidden'
      }"
      contenteditable="false"
    >
      <div
        v-if="!item.isWeeklyForecast && dailyForecastData"
        id="daily-forecast"
        class="d-flex w-100 align-items-center justify-content-around"
      >
        <div class="d-flex align-items-center justify-content-center">
          <span
            :style="{
              fontSize: item.textStyle.fontSize,
              lineHeight: item.textStyle.lineHeight
            }"
          >
            {{ locationName }}
          </span>
        </div>
        <div
          :style="{
            width: `${this.conditionWeatherImgWidth}px`,
            minWidth: `${this.conditionWeatherImgWidth}px`
          }"
          class="condition-weather-img"
        >
          <img class="w-100" :src="currentConditionWeatherImg" />
        </div>
        <div class="d-flex flex-column justify-content-center">
          <span
            v-if="item.unit === 'fahrenheit'"
            :style="{
              fontSize: item.textStyle.fontSize,
              lineHeight: item.textStyle.lineHeight,
              whiteSpace: 'nowrap'
            }"
            >{{ dailyForecastData.main.temp }} °F</span
          >
          <span
            v-else
            :style="{
              fontSize: item.textStyle.fontSize,
              lineHeight: item.textStyle.lineHeight,
              whiteSpace: 'nowrap'
            }"
            >{{ dailyForecastData.main.temp }} °C</span
          >
          <span
            :style="{
              fontSize: `calc(${item.textStyle.fontSize} / 2)`,
              lineHeight: item.textStyle.lineHeight
            }"
            :title="currentForecastWeatherTitle"
            >{{ currentForecastWeather.main }}</span
          >
        </div>
      </div>
      <div
        class="container-fluid h-100"
        v-if="item.isWeeklyForecast && weeklyForecastData"
        id="weekly-forecast"
      >
        <div class="row h-100">
          <template v-for="(date, index) in weeklyForecastDataFormat">
            <div
              :key="date.dt"
              :style="{
                backgroundColor: item.style.backgroundColor
              }"
              :class="{ mask: index % 2 }"
              class="col-4 weekly-forecast-item"
            >
              <div class="d-flex flex-column align-items-center">
                <span
                  :style="{
                    fontSize: item.textStyle.fontSize,
                    lineHeight: item.textStyle.lineHeight
                  }"
                  >{{
                    getDateText(date.dt_txt) !== 'TODAY' && getDateText(date.dt_txt) !== 'TOMORROW'
                      ? getDayOfWeek(date.dt_txt)
                      : getDateText(date.dt_txt)
                  }}</span
                >
                <span
                  :style="{
                    fontSize: item.textStyle.fontSize,
                    lineHeight: item.textStyle.lineHeight
                  }"
                  v-if="
                    getDateText(date.dt_txt) !== 'TODAY' && getDateText(date.dt_txt) !== 'TOMORROW'
                  "
                  >{{ getDateText(date.dt_txt) }}</span
                >
              </div>
              <div class="condition-weather-weekly-img">
                <img class="w-100" :src="getConditionWeatherImg(date.weather[0].icon)" />
              </div>
              <div>
                <div>
                  <span
                    :style="{
                      fontSize: item.textStyle.fontSize,
                      lineHeight: item.textStyle.lineHeight,
                      whiteSpace: 'nowrap'
                    }"
                    >{{ convertToCelsius(date.main.temp) }} °C
                  </span>
                  <span
                    :style="{
                      fontSize: `calc(${item.textStyle.fontSize} / 2)`,
                      lineHeight: item.textStyle.lineHeight,
                      whiteSpace: 'nowrap'
                    }"
                    >/{{ ` ${date.main.temp}` }} °F</span
                  >
                </div>
                <div>
                  <span
                    :style="{
                      fontSize: item.textStyle.fontSize,
                      lineHeight: item.textStyle.lineHeight
                    }"
                    >{{ date.weather[0].main }}</span
                  >
                </div>
              </div>
            </div>
          </template>
          <div class="col-4 weekly-forecast-item mask"></div>
        </div>
      </div>
    </div>
  </vue-draggable-resizable>
</template>
<script>
import * as WeatherService from '@/services/weather-smart-asset.service'
import { FONT_SIZE_DAILY_FORECAST_WEATHER } from '@/constant'

export default {
  name: 'Weather',
  data() {
    return {
      dailyForecastData: null,
      weeklyForecastData: null
    }
  },
  props: {
    item: {
      type: Object
    },
    index: {
      type: Number
    },
    activeBlockIndex: {
      type: [Number, Object]
    },
    smartAsset: {
      type: Object
    }
  },
  created() {
    this.fetchWeather()
  },
  computed: {
    locationName() {
      return !this.item.isWeeklyForecast
        ? this.dailyForecastData
          ? this.dailyForecastData.name
          : ''
        : this.weeklyForecast
        ? this.weeklyForecast.name
        : ''
    },
    currentForecastWeather() {
      return this.dailyForecastData ? this.dailyForecastData.weather[0] : {}
    },
    currentConditionWeatherImg() {
      return this.currentForecastWeather
        ? this.getConditionWeatherImg(this.currentForecastWeather.icon)
        : ''
    },
    currentForecastWeatherTitle() {
      return this.currentForecastWeather.description.toUpperCase()
    },
    weeklyForecastDataFormat() {
      if (this.weeklyForecastData) {
        const hour = this.findCurrentTime(this.weeklyForecastData.list).dt_txt.slice(-8)
        const result = this.weeklyForecastData.list.filter((p) => p.dt_txt.includes(hour))
        return result
      }
      return null
    },
    conditionWeatherImgWidth() {
      if (!this.item) return 100
      return (100 * parseInt(this.item.textStyle.fontSize, 10)) / FONT_SIZE_DAILY_FORECAST_WEATHER
    }
  },
  methods: {
    async fetchWeeklyForecast() {
      try {
        const res = await WeatherService.getWeeklyForecast({
          lat: this.item.lat,
          lon: this.item.lon
        })
        this.weeklyForecastData = res.data
        this.$emit('updateJsonData', this.index, JSON.stringify(res.data))
      } catch (error) {}
    },
    async fetchDailyForecast() {
      try {
        const res = await WeatherService.getDailyForecast({
          lat: this.item.lat,
          lon: this.item.lon,
          unit: this.item.unit === 'fahrenheit' ? 'imperial' : 'metric'
        })
        this.dailyForecastData = res.data
        this.$emit('updateJsonData', this.index, JSON.stringify(res.data))
      } catch (error) {}
    },
    onDragStop(x, y) {
      this.$emit('onDragStop', x, y)
    },
    onDragging(x, y) {
      this.$emit('onDragging', x, y)
    },
    onResizeStop(x, y, w, h) {
      this.$emit('onResizeStop', x, y, w, h)
    },
    onResize(x, y, w, h) {
      this.$emit('onResize', x, y, w, h)
    },
    onActivated(index, item) {
      this.$emit('onActivated', index, item)
    },
    fetchWeather() {
      if (this.item.isWeeklyForecast) {
        this.fetchWeeklyForecast()
      } else this.fetchDailyForecast()
    },
    findCurrentTime(data) {
      const currentDate = new Date()
      let startTime = null
      let endTime = null
      const result = data.find((p) => {
        endTime = new Date(p.dt_txt)
        let isBetween = false
        isBetween = currentDate >= startTime && currentDate <= endTime
        if (!isBetween) startTime = new Date(p.dt_txt)
        return isBetween
      })
      return result
    },
    getConditionWeatherImg(icon) {
      return `https://openweathermap.org/img/wn/${icon}@2x.png`
    },
    convertToCelsius(fahrenheit) {
      return ((fahrenheit - 32) / 1.8).toFixed(1)
    },
    getDayOfWeek(text) {
      const dayOfWeek = new Date(text).getDay()
      const daysOfWeek = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ]
      return daysOfWeek[dayOfWeek]
    },
    getDateText(text) {
      const date = new Date(text)
      const currentDate = new Date()
      const isToday = date.toDateString() === currentDate.toDateString()
      const tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)
      const isTomorrow = date.toDateString() === tomorrow.toDateString()
      if (isToday) {
        return 'TODAY'
      } else if (isTomorrow) {
        return 'TOMORROW'
      } else {
        const day = new Date(text).getDate()
        return day + this.getNthSuffix(day)
      }
    },
    getNthSuffix(date) {
      switch (date) {
        case 1:
        case 21:
        case 31:
          return 'st'
        case 2:
        case 22:
          return 'nd'
        case 3:
        case 23:
          return 'rd'
        default:
          return 'th'
      }
    }
  },
  watch: {
    'item.isWeeklyForecast'(val) {
      if (val) {
        this.fetchWeeklyForecast()
      } else this.fetchDailyForecast()
    },
    'item.unit'(val) {
      if (val) {
        this.fetchDailyForecast()
      }
    },
    'item.location'(val) {
      this.fetchWeather()
    }
  }
}
</script>
<style lang="scss" scoped>
.condition-weather-img {
  margin-right: 24px;
}
.condition-weather-weekly-img {
  width: 35%;
  min-width: 35%;
}
#weekly-forecast {
  .weekly-forecast-item {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px;
    height: 50%;
  }
  .mask {
    position: relative;
  }
  .mask:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }
}
</style>
