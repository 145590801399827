<template>
  <vue-draggable-resizable
    class="d-flex align-items-center justify-content-center"
    :class="{ selected: index === activeBlockIndex }"
    @activated="onActivated(index, item)"
    :active="index === activeBlockIndex"
    :lock-aspect-ratio="item && item.aspectRatio ? item.aspectRatio : null"
    :style="{
      zIndex: item.zIndex,
      borderRadius: `${item.borderRadiusValue.topLeft}px ${item.borderRadiusValue.topRight}px ${item.borderRadiusValue.bottomRight}px ${item.borderRadiusValue.bottomLeft}px`,
      backgroundColor: item.style.backgroundColor
    }"
    @dragstop="onDragStop"
    @dragging="onDragging"
    @resizestop="onResizeStop"
    @resizing="onResize"
    :grid="smartAsset.grid"
    :x="item.x"
    :y="item.y"
    :w="item && item.w >= 0 ? item.w : null"
    :h="item && item.h >= 0 ? item.h : null"
  >
    <div
      class="content-editable-container content-editable w-100 h-100"
      :style="{
        padding: `${item.paddingValue.top}px ${item.paddingValue.right}px ${item.paddingValue.bottom}px ${item.paddingValue.left}px`,
        borderRadius: `${item.borderRadiusValue.topLeft}px ${item.borderRadiusValue.topRight}px ${item.borderRadiusValue.bottomRight}px ${item.borderRadiusValue.bottomLeft}px`,
        color: item.textStyle.color,
        fontFamily: item.textStyle.fontFamily,
        fontWeight: item.textStyle.fontWeight,
        lineHeight: item.textStyle.lineHeight,
        letterSpacing: item.textStyle.letterSpacing,
        textShadow: item.textStyle.textShadow,
        alignItems: item.style.alignItems,
        justifyContent: item.style.justifyContent,
        flexDirection: item.style.flexDirection ? item.style.flexDirection : 'column',
        whiteSpace: 'pre-line',
        display: item.style.display ? item.style.display : 'flex'
      }"
      contenteditable="false"
    >
      <div class="d-flex w-100 h-75 flex-column justify-content-between">
        <div class="d-flex justify-content-center">
          <h1
            class="title"
            :style="{
              fontSize: `calc( ${item.textStyle.fontSize} + ${item.textStyle.fontSize} )`,
              color: item.textStyle.color
            }"
          >
            {{ item.event }}
          </h1>
        </div>
        <div class="countdown-group" :style="{ fontSize: item.textStyle.fontSize }">
          <div class="item">
            <span>{{ days }}</span>
            <span>Days</span>
          </div>
          <div class="item">
            <span>{{ hours }}</span>
            <span>{{ capitalizeFirstLetter($t('hours')) }}</span>
          </div>
          <div class="item">
            <span>{{ minutes }}</span>
            <span>{{ capitalizeFirstLetter($t('minutes')) }}</span>
          </div>
          <div class="item">
            <span>{{ seconds }}</span>
            <span>{{ $t('seconds') }}</span>
          </div>
        </div>
      </div>
    </div>
  </vue-draggable-resizable>
</template>
<script>
import { capitalizeFirstLetter } from '@/helper/helper'
import get from 'lodash/get'

export default {
  data() {
    return {
      timeCurrentTimer: null,
      currentTime: null,
      event: null,
      days: null,
      hours: null,
      minutes: null,
      seconds: null
    }
  },
  props: {
    item: {
      type: Object
    },
    index: {
      type: Number
    },
    activeBlockIndex: {
      type: [Number, Object]
    },
    smartAsset: {
      type: Object
    },
    timeTarget: {
      type: Date
    }
  },
  computed: {},
  methods: {
    capitalizeFirstLetter,
    onDragStop(x, y) {
      this.$emit('onDragStop', x, y)
    },
    onDragging(x, y) {
      this.$emit('onDragging', x, y)
    },
    onResizeStop(x, y, w, h) {
      this.$emit('onResizeStop', x, y, w, h)
    },
    onResize(x, y, w, h) {
      this.$emit('onResize', x, y, w, h)
    },
    onActivated(index, item) {
      this.$emit('onActivated', index, item)
    },
    formatNumber(number) {
      return number >= 10 ? number : `0${number}`
    },
    startCountdown() {
      clearInterval(this.timeCurrentTimer)
      const timeString = get(this.smartAsset, `blocks[${this.index}].time`, '')
      const date = new Date(get(this.smartAsset, `blocks[${this.index}].date`, ''))
      const year = date.getFullYear()
      const month = date.getMonth()
      const day = date.getDate()
      const [hours, minutes] = timeString.split(':')
      const combinedDateTime = new Date(year, month, day, hours, minutes)
      const countdownDate = new Date(combinedDateTime).getTime()
      this.timeCurrentTimer = setInterval(() => {
        const now = new Date().getTime()
        const distance = countdownDate - now
        this.days = this.formatNumber(Math.floor(distance / (1000 * 60 * 60 * 24)))
        this.hours = this.formatNumber(
          Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        )
        this.minutes = this.formatNumber(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)))
        this.seconds = this.formatNumber(Math.floor((distance % (1000 * 60)) / 1000))

        if (distance <= 0) {
          this.days = '00'
          this.hours = '00'
          this.minutes = '00'
          this.seconds = '00'
          clearInterval(this.timeCurrentTimer)
        }
      }, 1000)
    }
  },
  mounted() {
    this.startCountdown()
  },
  watch: {
    'item.time': {
      deep: true,
      handler(value) {
        this.startCountdown()
      }
    },
    'item.date': {
      deep: true,
      handler(value) {
        this.startCountdown()
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.timeCurrentTimer)
  }
}
</script>
<style lang="scss" scoped>
.title {
  text-align: center;
  margin-bottom: 16px;
  word-break: break-word;
  overflow-wrap: break-word;
}
.countdown-group {
  width: 100%;
  height: 75%;
  display: flex;
  flex-shrink: 0;
  .item {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
}
</style>
