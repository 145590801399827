export const API_URL = process.env.VUE_APP_API
export const API_ASSET_URL = process.env.VUE_APP_ASSET_API
export const DOMAIN_TITLE = 'Screenfluence'
export const SITE_DOMAIN = process.env.VUE_APP_SITE
export const WEB_SOCKET = process.env.VUE_APP_WEB_SOCKET
export const IG_CLIENT_ID = '581481562798275'
export const IG_CLIENT_SECCRET = '76d21796d6f9a578065fda7e266f667f'
export const VUE_APP_VERSION = 'v1.5.2'
export const CHARGEOVER_URL = process.env.VUE_APP_CHARGEOVER_URL
export const VUE_APP_CO_INSTANCE = process.env.VUE_APP_CO_INSTANCE
export const VUE_APP_CO_TOKEN = process.env.VUE_APP_CO_TOKEN
export const VUE_APP_OPEN_WEATHER_MAP = process.env.VUE_APP_OPEN_WEATHER_MAP
export const VUE_APP_OPEN_WEATHER_MAP_API_KEY = process.env.VUE_APP_OPEN_WEATHER_MAP_API_KEY
