export const SMART_ASSET_TYPE_TEXT = 'text'
export const SMART_ASSET_TYPE_PARAGRAPH = 'paragraph'
export const SMART_ASSET_TYPE_DATE_TIME = 'datetime'
export const SMART_ASSET_TYPE_WEATHER = 'weather'
export const SMART_ASSET_TYPE_COUNTDOWN = 'countdown'
export const FONT_SIZE_DATE_TIME_DEFAULT = 36
export const FONT_SIZE_COUNTDOWN_DEFAULT = 36
export const WIDTH_24H_DATE_TIME_DEFAULT = 300
export const WIDTH_COUNTDOWN_DEFAULT = 800
export const WIDTH_12H_DATE_TIME_DEFAULT = 450
export const HEIGHT_DATE_TIME_DEFAULT = 150
export const FONT_SIZE_DAILY_FORECAST_WEATHER = 36
export const FONT_SIZE_WEEKLY_FORECAST_WEATHER = 12
export const WIDTH_DAILY_FORECAST_WEATHER = 600
export const WIDTH_WEEKLY_FORECAST_WEATHER = 800
export const HEIGHT_WEATHER_DEFAULT = 150
export const HEIGHT_COUNTDOWN_DEFAULT = 300
export const LINKED_TYPE = {
  LOOP: 'loop',
  CAMPAIGN: 'campaign',
  PLAYER: 'player'
}

export const DEFAULT_SLIDE_TEXT_FONT = 'Roboto'
export const DEFAULT_SLIDE_TEXT_COLOR = '#223144'
export const DEFAULT_SLIDE_BACKGROUND_COLOR = '#ffffff'
export const FONT_SIZE_LARGE_OPTIONS = [
  { id: '12px', text: '12px' },
  { id: '14px', text: '14px' },
  { id: '16px', text: '16px' },
  { id: '18px', text: '18px' },
  { id: '20px', text: '20px' },
  { id: '22px', text: '22px' },
  { id: '24px', text: '24px' },
  { id: '26px', text: '26px' },
  { id: '28px', text: '28px' },
  { id: '30px', text: '30px' },
  { id: '32px', text: '32px' },
  { id: '34px', text: '34px' },
  { id: '36px', text: '36px' },
  { id: '38px', text: '38px' },
  { id: '40px', text: '40px' },
  { id: '45px', text: '45px' },
  { id: '50px', text: '50px' },
  { id: '52px', text: '52px' },
  { id: '54px', text: '54px' },
  { id: '56px', text: '56px' },
  { id: '58px', text: '58px' },
  { id: '60px', text: '60px' },
  { id: '62px', text: '62px' },
  { id: '64px', text: '64px' },
  { id: '66px', text: '66px' },
  { id: '68px', text: '68px' },
  { id: '70px', text: '70px' },
  { id: '72px', text: '72px' },
  { id: '74px', text: '74px' },
  { id: '76px', text: '76px' },
  { id: '78px', text: '78px' },
  { id: '80px', text: '80px' },
  { id: '82px', text: '82px' },
  { id: '84px', text: '84px' },
  { id: '86px', text: '86px' },
  { id: '88px', text: '88px' },
  { id: '90px', text: '90px' },
  { id: '92px', text: '92px' },
  { id: '94px', text: '94px' },
  { id: '96px', text: '96px' },
  { id: '98px', text: '98px' },
  { id: '100px', text: '100px' }
]
export const FONT_SIZE_OPTIONS = [
  { id: '12px', text: '12px' },
  { id: '14px', text: '14px' },
  { id: '16px', text: '16px' },
  { id: '18px', text: '18px' },
  { id: '20px', text: '20px' },
  { id: '22px', text: '22px' },
  { id: '24px', text: '24px' },
  { id: '26px', text: '26px' },
  { id: '28px', text: '28px' },
  { id: '30px', text: '30px' },
  { id: '32px', text: '32px' },
  { id: '34px', text: '34px' },
  { id: '36px', text: '36px' },
  { id: '38px', text: '38px' },
  { id: '40px', text: '40px' },
  { id: '45px', text: '45px' },
  { id: '50px', text: '50px' },
  { id: '52px', text: '52px' },
  { id: '54px', text: '54px' },
  { id: '56px', text: '56px' },
  { id: '58px', text: '58px' },
  { id: '60px', text: '60px' }
]
