import axios from 'axios'
import { ResponseWrapper, ErrorWrapper } from './util'
import { VUE_APP_OPEN_WEATHER_MAP, VUE_APP_OPEN_WEATHER_MAP_API_KEY } from '../.env'

export function getDailyForecast(data) {
  const lat = data.lat || 0
  const lon = data.lon || 0
  const unit = data.unit || 'imperial'
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${VUE_APP_OPEN_WEATHER_MAP}/weather?lat=${lat}&lon=${lon}&appid=${VUE_APP_OPEN_WEATHER_MAP_API_KEY}&units=${unit}`,
        data
      )
      .then((response) => {
        return resolve(new ResponseWrapper(response, response.data, response.data.message))
      })
      .catch((error) => {
        reject(new ErrorWrapper(error))
      })
  })
}

export function getWeeklyForecast(data) {
  const lat = data.lat || 0
  const lon = data.lon || 0
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${VUE_APP_OPEN_WEATHER_MAP}/forecast?lat=${lat}&lon=${lon}&appid=${VUE_APP_OPEN_WEATHER_MAP_API_KEY}&units=imperial`,
        data
      )
      .then((response) => {
        return resolve(new ResponseWrapper(response, response.data, response.data.message))
      })
      .catch((error) => {
        reject(new ErrorWrapper(error))
      })
  })
}
