var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vue-draggable-resizable',{staticClass:"d-flex align-items-center justify-content-center",class:{ selected: _vm.index === _vm.activeBlockIndex },style:({
    zIndex: _vm.item.zIndex,
    borderRadius: `${_vm.item.borderRadiusValue.topLeft}px ${_vm.item.borderRadiusValue.topRight}px ${_vm.item.borderRadiusValue.bottomRight}px ${_vm.item.borderRadiusValue.bottomLeft}px`,
    backgroundColor: _vm.item.style.backgroundColor,
    border:
      _vm.item.borderValue !== undefined && _vm.item.borderValue.borderWidth !== 0
        ? `${_vm.item.borderValue.borderWidth}px solid ${_vm.item.borderValue.color} !important`
        : ''
  }),attrs:{"active":_vm.index === _vm.activeBlockIndex,"lock-aspect-ratio":_vm.item.aspectRatio,"grid":_vm.smartAsset.grid,"x":_vm.item.x,"y":_vm.item.y,"w":_vm.item && _vm.item.w >= 0 ? _vm.item.w : null,"h":_vm.item && _vm.item.h >= 0 ? _vm.item.h : null},on:{"activated":function($event){return _vm.onActivated(_vm.index, _vm.item)},"dragstop":_vm.onDragStop,"dragging":_vm.onDragging,"resizestop":_vm.onResizeStop,"resizing":_vm.onResize}},[_c('div',{staticClass:"content-editable-container content-editable w-100 h-100",style:({
      borderRadius: `${_vm.item.borderRadiusValue.topLeft}px ${_vm.item.borderRadiusValue.topRight}px ${_vm.item.borderRadiusValue.bottomRight}px ${_vm.item.borderRadiusValue.bottomLeft}px`,
      color: _vm.item.textStyle.color,
      fontFamily: _vm.item.textStyle.fontFamily,
      fontWeight: _vm.item.textStyle.fontWeight,
      lineHeight: _vm.item.textStyle.lineHeight,
      letterSpacing: _vm.item.textStyle.letterSpacing,
      textShadow: _vm.item.textStyle.textShadow,
      alignItems: _vm.item.style.alignItems,
      justifyContent: _vm.item.style.justifyContent,
      flexDirection: _vm.item.style.flexDirection ? _vm.item.style.flexDirection : 'column',
      whiteSpace: 'pre-line',
      display: _vm.item.style.display ? _vm.item.style.display : 'flex',
      overflow: 'hidden'
    }),attrs:{"contenteditable":"false"}},[(!_vm.item.isWeeklyForecast && _vm.dailyForecastData)?_c('div',{staticClass:"d-flex w-100 align-items-center justify-content-around",attrs:{"id":"daily-forecast"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('span',{style:({
            fontSize: _vm.item.textStyle.fontSize,
            lineHeight: _vm.item.textStyle.lineHeight
          })},[_vm._v(" "+_vm._s(_vm.locationName)+" ")])]),_c('div',{staticClass:"condition-weather-img",style:({
          width: `${this.conditionWeatherImgWidth}px`,
          minWidth: `${this.conditionWeatherImgWidth}px`
        })},[_c('img',{staticClass:"w-100",attrs:{"src":_vm.currentConditionWeatherImg}})]),_c('div',{staticClass:"d-flex flex-column justify-content-center"},[(_vm.item.unit === 'fahrenheit')?_c('span',{style:({
            fontSize: _vm.item.textStyle.fontSize,
            lineHeight: _vm.item.textStyle.lineHeight,
            whiteSpace: 'nowrap'
          })},[_vm._v(_vm._s(_vm.dailyForecastData.main.temp)+" °F")]):_c('span',{style:({
            fontSize: _vm.item.textStyle.fontSize,
            lineHeight: _vm.item.textStyle.lineHeight,
            whiteSpace: 'nowrap'
          })},[_vm._v(_vm._s(_vm.dailyForecastData.main.temp)+" °C")]),_c('span',{style:({
            fontSize: `calc(${_vm.item.textStyle.fontSize} / 2)`,
            lineHeight: _vm.item.textStyle.lineHeight
          }),attrs:{"title":_vm.currentForecastWeatherTitle}},[_vm._v(_vm._s(_vm.currentForecastWeather.main))])])]):_vm._e(),(_vm.item.isWeeklyForecast && _vm.weeklyForecastData)?_c('div',{staticClass:"container-fluid h-100",attrs:{"id":"weekly-forecast"}},[_c('div',{staticClass:"row h-100"},[_vm._l((_vm.weeklyForecastDataFormat),function(date,index){return [_c('div',{key:date.dt,staticClass:"col-4 weekly-forecast-item",class:{ mask: index % 2 },style:({
              backgroundColor: _vm.item.style.backgroundColor
            })},[_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('span',{style:({
                  fontSize: _vm.item.textStyle.fontSize,
                  lineHeight: _vm.item.textStyle.lineHeight
                })},[_vm._v(_vm._s(_vm.getDateText(date.dt_txt) !== 'TODAY' && _vm.getDateText(date.dt_txt) !== 'TOMORROW' ? _vm.getDayOfWeek(date.dt_txt) : _vm.getDateText(date.dt_txt)))]),(
                  _vm.getDateText(date.dt_txt) !== 'TODAY' && _vm.getDateText(date.dt_txt) !== 'TOMORROW'
                )?_c('span',{style:({
                  fontSize: _vm.item.textStyle.fontSize,
                  lineHeight: _vm.item.textStyle.lineHeight
                })},[_vm._v(_vm._s(_vm.getDateText(date.dt_txt)))]):_vm._e()]),_c('div',{staticClass:"condition-weather-weekly-img"},[_c('img',{staticClass:"w-100",attrs:{"src":_vm.getConditionWeatherImg(date.weather[0].icon)}})]),_c('div',[_c('div',[_c('span',{style:({
                    fontSize: _vm.item.textStyle.fontSize,
                    lineHeight: _vm.item.textStyle.lineHeight,
                    whiteSpace: 'nowrap'
                  })},[_vm._v(_vm._s(_vm.convertToCelsius(date.main.temp))+" °C ")]),_c('span',{style:({
                    fontSize: `calc(${_vm.item.textStyle.fontSize} / 2)`,
                    lineHeight: _vm.item.textStyle.lineHeight,
                    whiteSpace: 'nowrap'
                  })},[_vm._v("/"+_vm._s(` ${date.main.temp}`)+" °F")])]),_c('div',[_c('span',{style:({
                    fontSize: _vm.item.textStyle.fontSize,
                    lineHeight: _vm.item.textStyle.lineHeight
                  })},[_vm._v(_vm._s(date.weather[0].main))])])])])]}),_c('div',{staticClass:"col-4 weekly-forecast-item mask"})],2)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }