<template>
  <div id="loop-page">
    <div class="top-header row mx-0">
      <div class="col-6 d-flex">
        <div class="mr-3">
          <SfInputSearch
            v-model="dataSearch"
            :placeholder="$t('search')"
            v-debounce:500ms="searchInputChange"
            :height="'40px'"
          />
        </div>
        <multiselect
          style="width: 150px"
          v-model="filterOrientation"
          :options="optionOrientations"
          :searchable="false"
          :allow-empty="false"
          deselectLabel=""
          selectLabel=""
          selectedLabel=""
          label="name"
          track-by="name"
          class="sf-multiselect"
          @select="changeOrientation"
        >
        </multiselect>
        <div class="ml-3">
          <button
            type="button"
            v-if="!selectAllButton && items.length > 0"
            @click="selectAllButton = !selectAllButton"
            class="sf-primary btn btn-block multiselect sf-multiselect select-btn"
          >
            <img class="data-image" :src="squareCheck" /> Select
          </button>
          <button
            type="button"
            v-if="selectAllButton && items.length > 0"
            @click="
              selectAllButton = !selectAllButton
              selectedItems = []
            "
            class="sf-primary btn btn-block multiselect sf-multiselect select-btn"
          >
            <img class="data-image" :src="squareCross" /> Unselect
          </button>
        </div>
      </div>
      <div class="col-6 d-none d-sm-block">
        <div class="group-button d-flex justify-content-end align-items-center">
          <button
            class="btn btn-show switcher_grid"
            id="btn-gridview"
            :class="{ active: viewType == 'grid-view' }"
            @click="viewType = 'grid-view'"
          ></button>
          <button
            class="btn btn-show switcher_list"
            id="btn-listview"
            :class="{ active: viewType == 'list-view' }"
            @click="viewType = 'list-view'"
          ></button>
          <div class="btn-group btn-group-header" role="group">
            <button class="sf-sort" type="button" @click="sortByName">
              {{ $t('name') }}
            </button>
            <button class="sf-sort" type="button" @click="sortByDate">
              {{ $t('date') }}
            </button>
          </div>
          <button class="sf-primary-add ml-3" type="button" @click="showAddNewLoop = true">
            {{ $t('create-new-loop') }}
          </button>
        </div>
      </div>
    </div>
    <div class="content-page-loop">
      <div class="content">
        <div class="top-block row">
          <div class="sf-breadcrumbs col-6 d-none d-sm-block" id="breadcrumb">
            <ul>
              <template v-if="breadcrumb.length">
                <li v-for="item in breadcrumb" :key="item.url" @click="clickBreadcrumb(item)">
                  <a class="sf-link"
                    ><span>{{ item.name }}</span></a
                  >
                </li>
              </template>
              <template v-else>
                <li
                  @click="
                    clickBreadcrumb({
                      url: '/apisite/v1/loop_assets',
                      type: 'main'
                    })
                  "
                >
                  <a class="sf-link"
                    ><span>{{ $t('loops') }}</span></a
                  >
                </li>
              </template>
            </ul>
          </div>
          <div
            v-if="!isEmpty && !loading"
            class="col-12 col-sm-6 d-flex justify-content-end align-items-center"
          >
            <b-pagination
              v-model="currentPage"
              :pills="true"
              :total-rows="total"
              per-page="1"
              :class="'sf-b-pagination'"
              limit="6"
              @change="changePage"
            >
            </b-pagination>
            <SfOptionLimitPage @changeLimitPage="changeLimitPage" :limitPage.sync="limitPage" />
          </div>
        </div>
        <div class="slim-div">
          <div v-if="isEmpty && !loading">
            <p class="d-flex justify-content-center">{{ $t('no-data-available-in-table') }}</p>
          </div>
          <itemFolder
            :selectAllButton="selectAllButton"
            :viewType="viewType"
            :nodes="items"
            :loading.sync="loading"
            :limitPage="limitPage"
            @edit:folder="editFolder"
            @edit:loop_asset="editLoop"
            @edit:shareFolder="shareFolder"
            @preview="itemPreview"
            @loopEditor="itemLoopEditor"
            @delete="itemDelete"
            @openFolder="openFolder"
            @deleteItemBulk="deleteItemBulk"
          />
        </div>
      </div>

      <div class="d-none d-sm-block" id="tree-view">
        <div class="button-create">
          <p>{{ $t('folder') }}</p>
          <button class="btn btn-default" id="create-new" @click="addFolder">
            <icon-plus />
          </button>
        </div>
        <div class="folder_default" @click="clickFolderDefault">
          <img src="~@/assets/images/loops-tree.svg" />
          <div class="wrap_p">
            <p>{{ $t('loops') }}</p>
          </div>
        </div>
        <vueTree
          :nodesTree="treeFolder"
          @selectedFolder="onSelect"
          @dataChanged="treeFolderChange"
          @drop="onDropItem"
          @editNameItem="editNameFolder"
          @deleteItem="deleteItemInFolder"
          @openFolder="openFolder"
        />
      </div>
    </div>
    <ModalDeleteItem
      :show.sync="showDeleteItem"
      :data.sync="dataModal"
      @update:show="updateShow('showDeleteItem')"
      @modal:delete="confirmDeleteItem"
    />
    <ModalAddNewLoop
      :show.sync="showAddNewLoop"
      :data.sync="dataModal"
      @update:show="updateShow('showAddNewLoop')"
      @modal:save="confirmAddLoop"
    />
    <ModalEditFolder
      :show.sync="showEditFolder"
      :data.sync="dataModal"
      @modal:save="confirmEditFolder"
      @update:show="updateShow('showEditFolder')"
    />
    <ModalLoopEditor
      :show.sync="showLoopEditor"
      :data.sync="dataModal"
      @modal:save="confirmEditLoop"
      @modal:clone="confirmCloneLoop"
      @update:show="updateShow('showLoopEditor')"
    />
    <ModalViewBox
      :show.sync="showViewBox"
      :data.sync="dataModal"
      @update:show="updateShow('showViewBox')"
    />
    <ModalDeleteItemInFolder
      :show.sync="showDeleteItemInFolder"
      :data.sync="dataModal"
      @update:show="updateShow('showDeleteItemInFolder')"
      @modal:delete="confirmDeleteItemInFolder"
    />
    <ModalShareFolder
      :show.sync="showShareFolder"
      :data.sync="dataModal"
      @update:show="updateShow('showShareFolder')"
      @modal:save="confirmEditFolder"
    />
    <ModalDeleteItemBulk
      :show.sync="showDeleteItemBulk"
      :data.sync="dataModal"
      @update:show="updateShow('showDeleteItemBulk')"
      @modal:delete="confirmDeleteItemBulk"
    />
    <SfWarningModal
      :title="$t('modal.title.warning')"
      closeOnOverlay
      :show.sync="isDeleteFolderBySomeOne"
    >
      <div class="content-delete">
        <p class="cantact-number">{{ $t('someone-else-delete-folder') }}</p>
      </div>
      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isDeleteFolderBySomeOne = false">
          {{ $t('close') }}
        </button>
      </div>
    </SfWarningModal>
    <loading :active.sync="isLoading" backgroundColor="rgba(0,0,0,0.6)" color="#126CDB"></loading>
  </div>
</template>

<script>
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import Multiselect from 'vue-multiselect'
import vueTree from '@/components/VueTree'
import ItemFolder from '@/components/itemFolder/Loops'
import LoopAssetsService from '@/services/loop-assets.service'
import prepareQueryParamsMixin from '@/mixins/prepareQueryParamsMixin'
import prepareFetchParamsMixin from '@/mixins/prepareFetchParamsMixin'
import iconPlus from '@/assets/images/plus.svg?inline'
import Folder from '@/services/folder.service'
import ModalDeleteItem from '@/components/loop/ModalDeleteItem'
import ModalAddNewLoop from '@/components/loop/ModalAddNewLoop'
import ModalEditFolder from '@/components/loop/ModalEditFolder'
import ModalLoopEditor from '@/components/loop/ModalLoopEditor'
import ModalShareFolder from '@/components/loop/ModalShareFolder'
import ModalViewBox from '@/components/loop/ModalViewBox'
import ModalDeleteItemInFolder from '@/components/loop/ModalDeleteItemInFolder'
import SfInputSearch from '@/components/SfInputSearch.vue'
import SfOptionLimitPage from '@/components/SfOptionLimitPage.vue'
import squareCheck from '@/assets/square-check.svg'
import squareCross from '@/assets/square-cross.svg'
import ModalDeleteItemBulk from '@/components/library/ModalDeleteItemBulk.vue'
import isEmpty from 'lodash/isEmpty'
import { textWithoutNonBreakingSpaceEntities } from '@/helper/helper'
import SfWarningModal from '@/components/SfWarningModal.vue'
import Loading from 'vue-loading-overlay'

export default {
  name: 'LoopsPage',
  mixins: [prepareQueryParamsMixin, prepareFetchParamsMixin],
  components: {
    Multiselect,
    iconPlus,
    vueTree,
    ItemFolder,
    ModalDeleteItem,
    ModalAddNewLoop,
    ModalEditFolder,
    ModalLoopEditor,
    ModalViewBox,
    ModalDeleteItemInFolder,
    ModalShareFolder,
    SfInputSearch,
    SfOptionLimitPage,
    ModalDeleteItemBulk,
    SfWarningModal,
    Loading
  },
  props: {},
  data() {
    return {
      text: 'Loop page Component',
      result: '',
      perpage: 1,
      currentPage: 1,
      limitItem: 6,
      viewType: 'grid-view',
      showDeleteItem: false,
      showAddNewLoop: false,
      showEditFolder: false,
      showLoopEditor: false,
      showViewBox: false,
      showShareFolder: false,
      showDeleteItemInFolder: false,
      currentFolderID: null,
      dataModal: {},
      dataSearch: '',
      folderData: {
        id: ''
      },
      typeShowSmartAsset: 'main',
      filterOrientation: {
        name: this.$t('both'),
        value: ''
      },
      limitPage: {
        name: `25 / ${this.$t('page')}`,
        value: '25'
      },
      user: '',
      subscribedData: '',
      oldFolderID: null,
      loaded: false,
      selectedNode: null,
      selectAllButton: false,
      squareCheck,
      squareCross,
      showDeleteItemBulk: false,
      sortedInfo: {
        sorted: false,
        type: '',
        isIncrease: true
      },
      isDeleteFolderBySomeOne: false,
      isLoading: false,
      isUpdatedFromSocket: false
    }
  },

  watch: {
    dataModal(newVal) {},
    '$i18n.locale'() {
      this.translateMultiselect()
    }
  },

  methods: {
    ...mapActions('loop', {
      getListLoopAsset: 'getListLoopAsset',
      getListTreeFolder: 'getListFolder',
      getTreeFolderChildren: 'getTreeFolderChildById',
      getListSmartAssetInFolder: 'getListSmartAssetInFolder',
      moveContentFolderToItems: 'moveContentFolderToItems'
    }),
    ...mapMutations('loop', {
      setItem: 'SET_ITEMS',
      setTreeFolder: 'SET_TREE_FOLDER_CHANGED',
      setTreeFolderById: 'SET_TREE_FOLDER_BY_ID',
      setItemsFiter: 'SET_ITEMS_DROP_BY_ID',
      setLoopAsset: 'SET_LOOP_ASSET',
      setNewLoop: 'SET_NEW_LOOP',
      addNewFolderItem: 'SET_NEW_FOLDER_ITEMS',
      addNewFolderTreeFolder: 'SET_NEW_FOLDER_TREE_FOLDER',
      updateItem: 'SET_UPDATE_ITEM',
      updateItemWithFolder: 'SET_UPDATE_ITEM_WITH_FOLDER',
      removeFolderItems: 'REMOVE_FOLDER_ITEMS',
      removeItemItems: 'REMOVE_ITEM_ITEMS',
      updateFolder: 'SET_UPDATE_FOLDER',
      setOpenFolderHasChildren: 'SET_OPEN_FOLDER_HAS_CHILDREN',
      sortNameFolder: 'SORT_NAME_FOLDER',
      addNewItemTreeFolder: 'SET_NEW_ITEM_TREE_FOLDER',
      addNewLoopItem: 'ADD_NEW_LOOP_ITEM',
      createFromSocket: 'CREATE_FROM_SOCKET',
      deleteFromSocket: 'DELETE_FROM_SOCKET',
      updateFromSocket: 'UPDATE_FROM_SOCKET',
      setDefaultBreadcrumb: 'SET_DEFAULT_BREADCRUMB',
      dragFromSocket: 'DRAG_FROM_SOCKET'
    }),
    async openFolder(node) {
      const data = node.data ? node.data : node
      window.slVueTree.unSelect()
      const oldFolderID = this.folderData.id
      this.folderData.id = node.data ? node.data.id : node.id
      this.typeShowSmartAsset = 'folder'
      this.currentFolderID = node.id || node.data.id
      if (!node.data) {
        // open folder in list items
        await this.getListAsset(1, this.limitPage.value)
        await this.getTreeFolderChildren(this.folderData.id)
        this.setOpenFolderHasChildren({ parent: this.folderData.id, type: 'item' })
      }

      if (node.data && node.data.has_children && oldFolderID !== this.folderData.id) {
        // open folder in tree folder
        await this.getListAsset(
          1,
          this.limitPage.value,
          this.dataSearch,
          this.filterOrientation.value
        )
        await this.getTreeFolderChildren(this.folderData.id)
      }
      if (data.folder_type === 'organizations') {
        this.currentOrganization = data.source_id
        this.removeSocket()
        this.subscribeChannel()
      } else {
        if (this.currentOrganization !== data.organization_id) {
          this.currentOrganization = data.organization_id
          this.removeSocket()
          this.subscribeChannel()
        }
      }
    },
    setDivHeight() {
      const breadcrumbHeight = document.getElementById('breadcrumb').offsetHeight
      document.documentElement.style.setProperty('--div-height', `${breadcrumbHeight}px`)
    },
    clickFolderDefault() {
      this.setDefaultBreadcrumb()
      this.typeShowSmartAsset = 'main'
      window.slVueTree.unSelect()
      this.folderData.id = ''
      this.selectedNode = null
      this.getListAsset(1, this.limitPage.value)
      if (this.currentOrganization !== this.user.organization_id) {
        this.currentOrganization = this.user.organization_id
        this.removeSocket()
        this.subscribeChannel()
      }
    },
    editNameFolder(node, name) {
      const data = cloneDeep(node.data)
      data.id = get(node, 'data.folder.id', node.data.id)
      delete data.folder
      Folder.editFolderByID({ ...data, name }, get(node, 'data.folder.id', node.data.id))
        .then((res) => {
          this.handleUpdateFromSocket(res.data)
        })
        .catch((err) => {
          // will revert name of the node if error occurs
          window.slVueTree.updateNode(node.path, {
            data: {
              ...node.data,
              isEdit: false
            }
          })
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    onSelect(node) {
      const data = node.data
      window.slVueTree.unSelect()
      if (node.data.file_type === 'folder' && node.data.id !== this.folderData.id) {
        this.currentFolderID = node.data.id
        this.folderData.id = node.data.id
        this.typeShowSmartAsset = 'folder'
        this.getListAsset(1, this.limitPage.value)
      }
      if (this.currentOrganization !== data.organization_id) {
        this.currentOrganization = data.organization_id
        this.removeSocket()
        this.subscribeChannel()
      }
      if (!node.data.isGetData && node.data.has_children) {
        this.getTreeFolderChildren(node.data.id)
      }
      this.selectedNode = [node]
    },
    treeFolderChange(treeFolder) {
      this.setTreeFolder(treeFolder)
    },
    onDropItem(data) {
      this.oldFolderID = get(data, 'dropNode[0].data.parent_id', null)
        ? get(data, 'dropNode[0].data.parent_id', null)
        : get(data, 'dropNode[0].data.folder.parent_id', null)
      this.currentFolderID =
        get(data, 'cursorPosition.node.data.file_type', null) !== 'folder'
          ? get(data, 'cursorPosition.node.data.parent_id', null)
          : get(data, 'cursorPosition.node.data.id', null)
      if (get(data, 'dropNode[0].data.folder.parent_id', null) === this.currentFolderID) return
      if (get(data, 'dropNode[0].data.shared_folder', null)) {
        this.$toast.error('This folder belongs to your parent organization.')
        return
      }
      if (data.dropNode[0].data.id !== data.cursorPosition.node.data.id) {
        if (data.dropNode[0].data.hasOwnProperty('newNode')) {
          let parentId = -1

          if (
            data.cursorPosition.placement === 'before' ||
            data.cursorPosition.placement === 'after'
          ) {
            const nodeSource = data.cursorPosition.node
            const tempPathNodeSource = [...nodeSource.path]
            tempPathNodeSource.pop()
            const parentNode = window.slVueTree.getNode(tempPathNodeSource)
            parentId = parentNode.data.id
          } else if (data.cursorPosition.placement === 'inside') {
            parentId = data.cursorPosition.node.data.id
          }
          const dataCreated = { ...data.dropNode[0].data }
          Folder.createFolder({
            name: dataCreated.name,
            folder_type: 'loops',
            parent_id: parentId,
            source_id: dataCreated.source_id,
            source_type: 'LoopAsset'
          })
            .then((res) => {
              this.handleUpdateFromSocket(res.data)
              this.setOpenFolderHasChildren({ parent: parentId, type: 'folder' })
              const node = window.slVueTree.getNodeById(data.dropNode[0].data.source_id)
              window.slVueTree.updateNode(node.path, {
                data: { ...node.data, ...res.data }
              })
            })
            .catch((err) => {
              this.$toast.error(
                err.message ? err.message : 'Something went wrong. Please try again.'
              )
            })
        } else if (
          data.cursorPosition.placement === 'before' ||
          data.cursorPosition.placement === 'after'
        ) {
          const nodeCurrent = data.dropNode[0]
          const nodeSource = data.cursorPosition.node
          const tempPathNodeCurrent = [...nodeCurrent.path]
          const tempPathNodeSource = [...nodeSource.path]
          tempPathNodeCurrent.pop()
          tempPathNodeSource.pop()

          if (JSON.stringify(tempPathNodeCurrent) !== JSON.stringify(tempPathNodeSource)) {
            const parentNode = window.slVueTree.getNode(tempPathNodeSource)
            const node = {
              name: data.dropNode[0].data.name,
              folder_type: 'loops',
              parent_id: parentNode ? parentNode.data.id : null
            }
            const dropNodeFolderId = get(nodeCurrent, 'data.folder.id', nodeCurrent.data.id)
            // call api
            Folder.editFolderByID(node, dropNodeFolderId)
              .then((res) => {
                this.handleUpdateFromSocket(res.data)
                if (
                  data.cursorPosition.placement !== 'before' ||
                  get(data, 'cursorPosition.node.data.parent_id', null)
                ) {
                  this.getListSmartAssetInFolder({
                    id: this.currentFolderID ? this.currentFolderID : data.dropNode[0].data.id,
                    page: 1,
                    limit: this.limitPage.value
                  })
                }
                if (data.cursorPosition.placement === 'before' && !this.currentFolderActive)
                  this.getListLoopAssetByFilter()
              })
              .catch((err) => {
                this.$toast.error(
                  err.message ? err.message : 'Something went wrong. Please try again.'
                )
              })

            // set has children
            const nodeParentOld = window.slVueTree.getNode(tempPathNodeCurrent)
            if (nodeParentOld) {
              window.slVueTree.updateNode(nodeParentOld.path, {
                data: {
                  ...nodeParentOld.data,
                  has_children: !!nodeParentOld.children.length
                }
              })
            }
          } else return
        } else if (
          data.cursorPosition.node.data.folder_type !== 'sub_organization' &&
          data.cursorPosition.placement === 'inside'
        ) {
          const node = {
            name: data.dropNode[0].data.name,
            folder_type: 'loops',
            parent_id: data.cursorPosition.node.data.id
          }
          const dropNodeFolderId = get(data, 'dropNode[0].data.folder.id', data.dropNode[0].data.id)
          Folder.editFolderByID(node, dropNodeFolderId)
            .then((res) => {
              this.handleUpdateFromSocket(res.data)
              this.getListSmartAssetInFolder({
                id: this.currentFolderID ? this.currentFolderID : data.dropNode[0].data.id,
                page: 1,
                limit: this.limitPage.value
              })
              this.getTreeFolderChildren(this.currentFolderID)
            })
            .catch((err) => {
              this.$toast.error(
                err.message ? err.message : 'Something went wrong. Please try again.'
              )
            })
        }

        this.setItemsFiter(data.dropNode[0].data.source_id)
      }
    },
    deleteItemInFolder(data) {
      this.dataModal = data
      this.showDeleteItemInFolder = true
    },
    confirmDeleteItemInFolder(node) {
      if (this.folderData.id === '' && node.children.length > 0) {
        this.moveContentFolderToItems({
          id: node.data.id,
          page: 1,
          limit: this.limitPage.value
        })
      }
      if (this.currentOrganization !== node.data.organization_id) {
        this.currentOrganization = node.data.organization_id
        this.removeSocket()
        this.subscribeChannel()
      }
      this.folderData.id = node.data.parent_id
      this.isLoading = true
      Folder.deleteFolderByID(node.data.id)
        .then((res) => {
          this.showDeleteItemInFolder = false
          // move active to parent and load content of parent
          const arr = node.path
          if (arr.length > 1) {
            arr.pop()
            const parentId = get(node, 'data.parent_id', null)
              ? get(node, 'data.parent_id', null)
              : get(node, 'data.folder.parent_id', null)
            if (node.data.file_type === 'folder' && parentId && !this.isDefaultBreadcrumb) {
              this.getListSmartAssetInFolder({
                id: parentId,
                page: 1,
                limit: this.limitPage.value
              })
            } else if (this.isDefaultBreadcrumb && node.data.file_type === 'folder') {
              this.clickFolderDefault()
            }
          } else {
            this.clickFolderDefault()
          }
          this.handleDeleteById(res.data, this.user.id)
        })
        .catch((error) => {
          this.showDeleteItemInFolder = false
          this.$toast.open({
            message: error.message || 'Something went wrong. Please try again.',
            type: 'error'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    confirmDeleteItem(data) {
      this.isLoading = true
      LoopAssetsService.deleteLoopAssetByID(data.id)
        .then((res) => {
          if (res.status === 200) {
            this.showDeleteItem = false
            this.handleDeleteById(res.data, this.user.id)
            this.$toast.open({
              message: res.message || 'Loop deleted',
              type: 'success'
            })
          }
        })
        .catch((error) => {
          this.showDeleteItem = false
          this.$toast.error(
            error.status === 400 ? error.message : 'Something went wrong. Please try again'
          )
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    confirmAddLoop(data) {
      const param = {
        loop_asset: {
          displayname: textWithoutNonBreakingSpaceEntities(data.title),
          orientation: data.orientation
        },
        organization_id: this.currentOrganization
      }
      if (this.currentFolderActive) {
        param.currentFolder = this.currentFolderActive
      }
      this.isLoading = true
      LoopAssetsService.createLoopAssets(param)
        .then((res) => {
          if (res.status === 200) {
            this.updateLoopAfterCreating(res.data)
            this.$toast.open({
              message: res.message || 'Loop created.',
              type: 'success'
            })
            this.$router.push({
              name: 'editLoop',
              params: {
                loopId: res.data.id,
                selectedNode: window.slVueTree.getSelected().length
                  ? window.slVueTree.getSelected()
                  : this.selectedNode,
                viewType: this.viewType,
                filterOrientation: cloneDeep(this.filterOrientation),
                dataSearch: this.dataSearch,
                sortedInfo: this.sortedInfo
              }
            })

            this.showAddNewLoop = false
          }
        })
        .catch((err) => {
          this.$toast.open({
            message: err.message || 'Something went wrong. Please try again.',
            type: 'error'
          })
          this.showAddNewLoop = false
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    confirmEditFolder(data) {
      this.isLoading = true
      Folder.editFolderByID({ folder: data }, data.id)
        .then((res) => {
          this.handleUpdateFromSocket(res.data)
          this.showEditFolder = false
          this.showShareFolder = false
          this.updateItem({ id: res.data.id, name: res.data.name })
          this.updateFolder({ id: res.data.id, name: res.data.name })
          const count = res.data.shared_organizations.length
          let message = 'Folder updated'
          if (count > 0) {
            message =
              count > 1
                ? 'This folder is now shared with ' + count + ' organizations'
                : 'This folder is now shared with ' + count + ' organization'
          }
          this.$toast.open({
            message: message,
            type: 'success'
          })
        })
        .catch((error) => {
          this.$toast.open({
            message: error.message || 'Something went wrong. Please try again',
            type: 'error'
          })
          this.showEditFolder = false
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    confirmEditLoop(data) {
      const param = {
        loop_asset: {
          displayname: textWithoutNonBreakingSpaceEntities(data.displayname),
          orientation: data.orientation,
          tag_list: data.tags.map((i) => i.text)
        },
        organization_id: this.currentOrganization
      }
      this.isLoading = true
      LoopAssetsService.editLoopAssetsByID(param, data.id)
        .then((res) => {
          this.$toast.open({
            message: res.message,
            type: 'success'
          })
          this.setLoopAsset({
            id: res.data.id,
            name: res.data.displayname,
            orientation: res.data.orientation
          })
          this.updateFolder({
            id: res.data.folder && res.data.folder.id,
            name: res.data.displayname
          })
          this.showLoopEditor = false
        })
        .catch((error) => {
          this.showLoopEditor = false
          this.$toast.open({
            message: error.message,
            type: 'error'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    confirmCloneLoop(data) {
      this.isLoading = true
      LoopAssetsService.cloneSmartAssetsByID(data.id)
        .then((res) => {
          const {
            folder,
            created_at,
            folder_type,
            id,
            displayname,
            organization_id,
            updated_at,
            asset_count,
            orientation,
            origin_link,
            smart_asset_type,
            thumb_link,
            loop_asset_code
          } = res.data
          const data = {
            asset_count,
            can_drop: true,
            folder: {
              id: folder.id,
              parent_id: folder.parent_id
            },
            created_at,
            folder_type,
            id,
            name: displayname,
            organization_id,
            type: 'loop_asset',
            updated_at,
            orientation,
            origin_link,
            smart_asset_type,
            thumb_link,
            loop_asset_code
          }
          this.updateLoopAfterCreating(data)
          this.$toast.open({
            message: res.message,
            type: 'success'
          })
          this.showLoopEditor = false
        })
        .catch((error) => {
          this.$toast.open({
            message: error.message,
            type: 'error'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    clickBreadcrumb(item) {
      if (item.type === 'main') {
        this.setDefaultBreadcrumb()
        this.typeShowSmartAsset = 'main'
        this.folderData.id = ''
        this.currentFolderID = ''
        this.selectedNode = null
        if (this.currentOrganization !== this.user.organization_id) {
          this.currentOrganization = this.user.organization_id
          this.removeSocket()
          this.subscribeChannel()
        }
        window.slVueTree.unSelect()
      } else if (item.type === 'folder') {
        this.folderData.id = item.id
        this.currentFolderID = item.id
        this.typeShowSmartAsset = 'folder'
        const nodeDrag = window.slVueTree.getNodeById(parseInt(item.id))
        window.slVueTree.select([...nodeDrag.path])
      }
      this.getListLoopAssetByFilter(1, this.limitPage.value)
    },
    updateShow(name, event) {
      this[name] = false
    },
    editFolder(data) {
      const self = this
      Folder.getDetailFolder(data.id)
        .then((res) => {
          self.dataModal = res.data
          self.showEditFolder = true
        })
        .catch((error) => {
          self.$toast.open({
            message: error.message,
            type: 'error'
          })
        })
    },
    shareFolder(data) {
      const self = this
      Folder.getDetailFolder(data.id)
        .then((res) => {
          self.showShareFolder = true
          self.dataModal = res.data
        })
        .catch((error) => {
          self.$toast.open({
            message: error.message,
            type: 'error'
          })
        })
    },
    editLoop(data) {
      LoopAssetsService.getLoopAssetsByID(data.id)
        .then((res) => {
          this.dataModal = res.data
          this.showLoopEditor = true
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    itemPreview(data) {
      LoopAssetsService.getLoopAssetsByID(data.id)
        .then((res) => {
          this.dataModal = data
          this.showViewBox = true
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    itemLoopEditor(data) {
      LoopAssetsService.getLoopAssetsByID(data.id)
        .then((res) => {
          this.$router.push({
            name: 'editLoop',
            params: {
              loopId: res.data.id,
              selectedNode: window.slVueTree.getSelected().length
                ? window.slVueTree.getSelected()
                : this.selectedNode,
              viewType: this.viewType,
              filterOrientation: cloneDeep(this.filterOrientation),
              dataSearch: this.dataSearch,
              sortedInfo: this.sortedInfo
            }
          })
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    itemDelete(data) {
      this.dataModal = data
      this.showDeleteItem = true
    },
    async getListAsset(page, limit, search, orientation) {
      if (!search && !orientation) {
        this.resetFilter()
      }
      if (this.typeShowSmartAsset === 'main') {
        await this.getListLoopAsset({
          page,
          limit,
          search,
          orientation
        })
        this.typeShowSmartAsset = 'main'
        const selected = window.slVueTree.getSelected()
        if (selected) {
          window.slVueTree.updateNode(selected.path, { isSelected: false })
        }
      } else if (this.typeShowSmartAsset === 'folder' && this.folderData.id) {
        await this.getListSmartAssetInFolder({
          id: this.folderData.id,
          page,
          limit,
          search,
          orientation
        })
      }
    },
    addFolder() {
      Folder.createFolder({
        name: 'New Folder',
        folder_type: 'loops',
        parent_id: this.currentFolderActive
      })
        .then((res) => {
          const {
            created_at,
            folder_type,
            id,
            name,
            organization_id,
            parent_id,
            source_id,
            updated_at,
            type
          } = res.data
          const data = {
            can_drop: true,
            created_at,
            file_type: type,
            folder_type,
            has_children: false,
            id,
            name,
            organization_id,
            parent_id,
            source_id,
            type: 'folder',
            updated_at
          }
          this.updateLoopAfterCreating(data)
          this.$toast.open({
            message: res.message || 'New folder created',
            type: 'success'
          })
        })
        .catch((error) => {
          this.$toast.open({
            message: error.message,
            type: 'error'
          })
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    sortItem(compare) {
      const nodesSort = JSON.parse(JSON.stringify(this.items))
        .map((item) => {
          item.created_at = new Date(item.created_at)
          return item
        })
        .sort((a, b) => compare(a, b))
      if (JSON.stringify(this.items) === JSON.stringify(nodesSort)) {
        this.sortedInfo.isIncrease = false
        this.setItem({ items: nodesSort.reverse() })
      } else {
        this.setItem({ items: nodesSort })
      }
    },
    sortTreeFolder(compare) {
      const node = window.slVueTree.getNodeById(this.folderData.id)
      if (!isEmpty(node) && !isEmpty(node.children)) {
        const childrenClone = cloneDeep(node.children)
        const nodesSort = node.children.sort((a, b) => compare(a, b))
        if (JSON.stringify(childrenClone) === JSON.stringify(nodesSort)) {
          this.setTreeFolderById({ id: this.folderData.id, data: nodesSort.reverse() })
        } else {
          this.setTreeFolderById({ id: this.folderData.id, data: nodesSort })
        }
      } else {
        const treeFolder = cloneDeep(this.treeFolder)
        const folderSubOrganization = !isEmpty(treeFolder)
          ? treeFolder.filter((x) => x.data.folder_type === 'sub_organization')
          : []
        let groupItems = treeFolder.filter((i) => i.data.folder_type !== 'sub_organization')
        groupItems = [...folderSubOrganization, ...groupItems.sort((a, b) => compare(a, b))]
        if (!this.sortedInfo.isIncrease) this.setTreeFolder(groupItems.reverse())
        else this.setTreeFolder(groupItems)
      }
    },
    compareName(a, b) {
      return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
    },
    compareDate(a, b) {
      return (a.created_at || Date.now()) > (b.created_at || Date.now())
        ? -1
        : (a.created_at || Date.now()) < (b.created_at || Date.now())
        ? 1
        : 0
    },
    compareNameTreeFolder(a, b) {
      return a.data.name > b.data.name ? 1 : a.data.name < b.data.name ? -1 : 0
    },
    compareDateTreeFolder(a, b) {
      return (a.data.created_at || Date.now()) > (b.data.created_at || Date.now())
        ? -1
        : (a.data.created_at || Date.now()) < (b.data.created_at || Date.now())
        ? 1
        : 0
    },
    sortByName() {
      this.sortedInfo = {
        sorted: true,
        type: 'name',
        isIncrease: true
      }
      this.sortItem(this.compareName)
      this.sortTreeFolder(this.compareNameTreeFolder)
    },
    sortByDate() {
      this.sortedInfo = {
        sorted: true,
        type: 'date',
        isIncrease: true
      }
      this.sortItem(this.compareDate)
      this.sortTreeFolder(this.compareDateTreeFolder)
    },
    getUser() {
      const user = this.$cookies.get('user')
      if (user) {
        this.user = user
      }
    },
    searchInputChange(value) {
      this.currentPage = 1
      this.dataSearch = value
      this.getListLoopAssetByFilter()
    },
    changePage(value) {
      this.currentPage = value
      this.getListLoopAssetByFilter()
    },
    changeOrientation(value) {
      this.currentPage = 1
      this.filterOrientation = cloneDeep(value)
      this.getListLoopAssetByFilter()
    },
    getListLoopAssetByFilter() {
      this.getListAsset(
        this.currentPage,
        this.limitPage.value,
        this.dataSearch,
        this.filterOrientation.value
      )
    },
    resetFilter() {
      this.$bus.$emit('resetSeach', '')
      this.dataSearch = ''
      this.filterOrientation.name = this.$t('both')
      this.filterOrientation.value = ''
    },
    listOptionOrientation() {
      return [
        {
          name: this.$t('both'),
          value: ''
        },
        {
          name: this.$t('portrait'),
          value: 'portrait'
        },
        {
          name: this.$t('landscape'),
          value: 'landscape'
        }
      ]
    },
    translateMultiselect() {
      const listOptionOrientation = this.listOptionOrientation()
      const orientation = listOptionOrientation.find(
        (option) => option.value === this.filterOrientation.value
      )
      this.filterOrientation.name = orientation.name
    },
    changeLimitPage(data) {
      this.currentPage = 1
      this.limitPage = data
      this.getListLoopAssetByFilter()
    },
    deleteItemBulk(data) {
      this.showDeleteItemBulk = true
      this.dataModal = { dataInput: data }
    },
    confirmDeleteItemBulk({ dataInput: data }) {
      const folders = []
      const assets = []
      data.forEach((obj, index) => {
        if (obj.type === 'folder') {
          folders.push(obj.id)
        } else {
          assets.push(obj.id)
        }
      })
      const ids = {
        folders: folders,
        assets: assets
      }
      this.isLoading = true
      this.showDeleteItemBulk = false
      LoopAssetsService.deleteLoopAssetByIDs(ids)
        .then((res) => {})
        .catch((error) => {
          this.$toast.open({
            message: error.message || 'Something went wrong. Please try again.',
            type: 'error'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    subscribeChannel() {
      this.$cable.subscribe({
        channel: 'LoopChannel',
        organization: this.currentOrganization
      })
      this.$cable.subscribe({
        channel: 'FolderLibraryChannel',
        organization: this.currentOrganization
      })
    },
    removeSocket() {
      this.$cable.unsubscribe('LoopChannel')
      this.$cable.unsubscribe('FolderLibraryChannel')
    },
    setFilterAndViewType() {
      if (isEmpty(this.$route.params)) return
      if (this.$route.params.viewType) {
        this.viewType = this.$route.params.viewType
      }
      if (this.$route.params.dataSearch) {
        this.dataSearch = this.$route.params.dataSearch
      }
      if (this.$route.params.filterOrientation) {
        this.filterOrientation = this.$route.params.filterOrientation
      }
    },
    handleSortBy() {
      if (!this.$route.params.sortedInfo || !this.$route.params.sortedInfo.sorted) return
      const sortedInfoClone = cloneDeep(this.$route.params.sortedInfo)
      if (sortedInfoClone.type === 'name') {
        const number = sortedInfoClone.isIncrease ? 1 : 2
        for (let index = 0; index < number; index++) {
          setTimeout(() => {
            this.sortByName()
          }, 200)
        }
      } else if (sortedInfoClone.type === 'date' && !sortedInfoClone.isIncrease) {
        this.sortByDate()
      }
    },
    updateLoopAfterCreating(data) {
      const folderId = data.type === 'folder' ? data.id : get(data, 'folder.id', null)
      const node = window.slVueTree.getNodeById(folderId)
      const isExistedInItems = this.items.filter((x) => x.id === data.id).length > 0
      if (isEmpty(node) && !isExistedInItems && data.type === 'folder') {
        this.createFromSocket({
          data,
          currentOrganization: this.user.organization_id
        })
      } else if (isEmpty(node) && !isExistedInItems && data.type !== 'folder') {
        this.createFromSocket({ data })
      }
    },
    handleDeleteById(data, userId = null, fromSocket = false) {
      const folderId = data.type === 'folder' ? data.id : get(data, 'folder.id', null)
      const node = window.slVueTree.getNodeById(folderId)
      const isExistedInItems = this.items.filter((x) => x.id === data.id).length > 0
      if (!isEmpty(node) || isExistedInItems) {
        this.deleteFromSocket({ data })
        if (
          data.id === this.currentFolderActive &&
          (this.user.id !== userId || (this.user.id === userId && fromSocket))
        ) {
          this.isDeleteFolderBySomeOne = true
          this.$nextTick(() => {
            this.clickFolderDefault()
          })
        }
      }
    },
    handleUpdateFromSocket(data, fromSocket = false) {
      if (fromSocket && this.isUpdatedFromSocket) {
        this.isUpdatedFromSocket = false
        return
      }
      if (!fromSocket) {
        this.isUpdatedFromSocket = true
      }
      this.$nextTick(() => {
        this.updateFromSocket({ data })
      })
    }
  },
  beforeDestroy() {
    this.$bus.$off('onSearchInputChange')
    this.removeSocket()
    if (this.$route.name !== 'editLoop') {
      this.setDefaultBreadcrumb()
    }
  },
  computed: {
    ...mapGetters('loop', {
      items: 'items',
      pagination: 'pagination',
      total: 'totalPagination',
      page: 'pagePagination',
      limit: 'limitPagination',
      breadcrumb: 'breadcrumb',
      treeFolder: 'treeFolder',
      isEmpty: 'isEmpty'
    }),
    ...mapState('loop', {
      error: 'error',
      loading: 'loading'
    }),
    optionOrientations() {
      return this.listOptionOrientation()
    },
    isDefaultBreadcrumb() {
      return get(this.breadcrumb, `[${this.breadcrumb.length - 1}].type`, '') === 'main'
    },
    currentFolderActive() {
      const id = get(this.breadcrumb, `[${this.breadcrumb.length - 1}].id`, null)
      return isEmpty(id) ? null : parseInt(id, 10)
    }
  },

  channels: {
    LoopChannel: {
      connected() {},
      rejected() {},
      received(data) {
        if (data.action === 'create') {
          this.updateLoopAfterCreating(data.data)
        } else if (data.action === 'delete') {
          this.deleteFromSocket({ data: data.data })
        } else if (data.action === 'update' || data.action === 'drag') {
          setTimeout(() => {
            this.handleUpdateFromSocket(data.data, true)
          }, 200)
        }
      },
      disconnected() {}
    },
    FolderLibraryChannel: {
      connected() {},
      rejected() {},
      received(data) {
        if (get(data, 'data.folder_type', null) !== 'loops') return
        if (data.action === 'create') {
          this.updateLoopAfterCreating(data.data)
        } else if (data.action === 'delete') {
          setTimeout(() => {
            this.handleDeleteById(data.data, data.user_id, true)
          }, 200)
        } else if (data.action === 'update') {
          this.handleUpdateFromSocket(data.data, true)
          if (
            (get(this.breadcrumb, `[${this.breadcrumb.length - 1}].type`, '') === 'main' &&
              !get(data, 'data.parent_id', null) &&
              !get(data, 'data.folder.parent_id', null)) ||
            (get(data, 'data.type', '') === 'folder' &&
              this.currentFolderActive === get(data, 'data.id', null))
          ) {
            this.getListLoopAssetByFilter()
          }
        }
      },
      disconnected() {}
    }
  },
  beforeUpdate() {},
  async mounted() {
    const breadcrumb = document.getElementById('breadcrumb')
    document.documentElement.style.setProperty('--div-height', '0px')
    this.breadcrumbObserver = new MutationObserver(this.setDivHeight)
    this.breadcrumbObserver.observe(breadcrumb, {
      childList: true,
      subtree: true,
      characterData: true
    })
    this.getUser()
    this.currentOrganization = this.user.organization_id
    this.$cable.subscribe({
      channel: 'LoopChannel',
      organization: this.user.organization_id
    })
    this.$cable.subscribe({
      channel: 'FolderLibraryChannel',
      organization: this.user.organization_id
    })
    const pages = document.getElementById('pages')
    pages.scrollTop = 0
    this.setFilterAndViewType()
    if (this.$route.params.selectedNode && this.$route.params.selectedNode.length > 0) {
      const selectedNode = this.$route.params.selectedNode[0]
      if (selectedNode) {
        this.folderData.id = ''
        this.typeShowSmartAsset = 'folder'
        this.currentFolderID = selectedNode.data.source_id
        await this.getListAsset(
          1,
          this.limitPage.value,
          this.$route.params.dataSearch,
          get(this.$route, 'params.filterOrientation.value', '')
        )
        await this.getTreeFolderChildren(selectedNode.data.source_id)
        await this.openFolder(selectedNode)
        window.slVueTree.emitNodeClick(selectedNode)
      }
    } else {
      await this.getListAsset(
        1,
        this.limitPage.value,
        this.$route.params.dataSearch,
        get(this.$route, 'params.filterOrientation.value', '')
      )
      this.$bus.$on('onSearchInputChange', (value) => {
        this.dataSearch = value
        this.getListLoopAssetByFilter()
      })
      await this.getListTreeFolder()
    }
    this.handleSortBy()
  }
}
</script>
<style lang="scss" scoped>
@import './LoopPage.scss';
</style>

<style lang="scss">
#loop-page {
  .b-pagination {
    margin: 0;
    z-index: 3;
    & > li.page-item {
      &:first-child,
      &:last-child {
        display: none;
      }
    }

    // .page-item {
    //   cursor: pointer;
    //   position: relative;
    //   height: 26px;
    //   margin: 0 10px;
    //   background-color: initial ;
    //   .page-link{
    //     border: transparent;
    //     width: auto;
    //     padding: 0;
    //     background-color: initial !important;
    //     height: 100%;
    //     line-height: 26px;
    //     text-align: center;
    //     color: #9fa2b1;

    //   }
    //   &.active{
    //     .page-link {
    //       cursor: pointer;
    //       user-select: none;
    //       color: white;
    //       font-weight: bold;
    //       border-radius: 13px;
    //       border: transparent !important;
    //       background-color: #0074d2 !important;
    //       width: 26px;
    //       height: 26px;

    //     }
    //   }
    // }
  }
  //end: pagination

  // begin: multiselect

  // end: mutilselect
}
.uppercase-text {
  text-transform: capitalize;
}
</style>
