<template>
  <SfModal
    :title="$t('add-countdown')"
    :width="768"
    :height="0"
    :show.sync="dataShow"
    @update:show="updateShow"
  >
    <div class="sf-modal-body mb-3">
      <div class="container">
        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">{{ $t('event') }}</label>
              </div>
              <input
                type="text"
                name="smart_asset[displayname]"
                id="smart_asset_displayname"
                class="sf-input-text"
                placeholder="Event"
                v-model="dataInput.event"
                :class="{ 'input-error-form': submitted && $v.dataInput.event.$invalid }"
                autocomplete="off"
              />
            </div>
            <div
              class="invalid-error-text text-right"
              v-if="submitted && !$v.dataInput.event.required"
            >
              {{ $t('enter-name-asset') }}
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">{{ $t('date') }} </label>
              </div>
              <datepicker
                :input-class="{ 'input-error-form': submitted && $v.dataInput.date.$invalid }"
                v-model="dataInput.date"
                :placeholder="$t('date')"
                wrapper-class="pretty"
                :disabledDates="{ to: getYesterday() }"
              >
              </datepicker>
            </div>
            <div
              class="invalid-error-text text-right"
              v-if="submitted && !$v.dataInput.date.required"
            >
              {{ $t('date-required') }}
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">{{ $t('time') }}</label>
              </div>
              <div class="sf-timepicker-wrap">
                <vue-timepicker
                  hide-clear-button
                  v-model="dataInput.time"
                  :input-class="
                    submitted && $v.dataInput.time.$invalid
                      ? 'input-error-form sf-timepicker'
                      : 'sf-timepicker'
                  "
                ></vue-timepicker>
              </div>
            </div>
            <div
              class="invalid-error-text text-right"
              v-if="submitted && !$v.dataInput.time.required"
            >
              {{ $t('time-required') }}
            </div>
            <div
              class="invalid-error-text text-right"
              v-if="submitted && !$v.dataInput.time.checkTime"
            >
              {{ $t('time-invalid') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sf-modal-footer">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="sf-modal-action">
              <button class="sf-secondary" type="button" @click="updateShow">Cancel</button>
              <button type="button" class="sf-primary" @click="onClickSave">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SfModal>
</template>

<script>
import SfModal from '@/components/SfModal'
import { required } from 'vuelidate/lib/validators'
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import { getYesterday } from '@/helper/helper'

const checkTime = (time) => {
  return !time.includes('HH') && !time.includes('mm')
}

export default {
  components: {
    SfModal,
    Datepicker,
    VueTimepicker
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    closeOnOverlay: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      addressDataFormat: '',
      listStates: [],
      dataShow: this.show,
      tag: '',
      dataInput: {
        event: '',
        date: '',
        time: ''
      },
      submitted: false,
      urlPermission: true,
      checking: false
    }
  },
  validations: {
    dataInput: {
      event: {
        required
      },
      date: {
        required
      },
      time: {
        required,
        checkTime
      }
    }
  },
  watch: {
    show(value) {
      this.dataShow = value
      this.submitted = false
      this.resetForm()
      this.$v.dataInput.$reset()
    }
  },
  methods: {
    getYesterday,
    updateShow() {
      this.$emit('update:show', false)
    },
    onClickSave() {
      this.submitted = true
      if (this.$v.dataInput.$invalid) {
        return
      }
      this.$emit('modal:save', { ...this.dataInput })
    },
    resetForm() {
      this.dataInput = {
        event: '',
        date: '',
        time: ''
      }
    }
  },
  computed: {},
  created() {
    this.$bus.$on('reset-submitted', (value) => {
      this.submitted = value
      this.resetForm()
    })
  },
  beforeDestroy() {
    this.dataInput = {
      event: '',
      date: '',
      time: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/components/library/style-modal-smart-asset';
</style>
<style lang="scss">
.vdp-datepicker div input {
  margin-top: 0 !important;
}
.pretty {
  width: 100%;
}
.form-control {
  height: auto !important;
  .vue-tags-input {
    margin: -0.375rem -0.75rem;
    border-radius: 0.25rem;
    * {
      border: transparent;
    }
    border: transparent;
    .ti-tag {
      background-color: #e0edf5;
      border-radius: 3px;
      color: #2f5d92;
      float: left;
      font-weight: 600;
      height: 28px;
      line-height: 28px;
      margin: 0 8px 6px 0;
      padding: 0 1px 0 8px;
      white-space: nowrap;
    }
  }
}
</style>
