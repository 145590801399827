export const adminClients = {
  organization: 'organization',
  player: 'player',
  user: 'user',
  setting: 'setting'
}
export const adminManagement = {
  orgType: 'orgType',
  employee: 'employee',
  post: 'post',
  smartAssetTemplate: 'smartAssetTemplate'
}
export const adminSystem = {
  adminPiFirmware: 'adminPiFirmware',
  slideTemplate: 'slideTemplate',
  term: 'term',
  role: 'role',
  update: 'update',
  adminHardwareType: 'adminHardwareType',
  language: 'language',
  templateCategory: 'templateCategory'
}
