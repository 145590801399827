<template>
  <div class="admin-page">
    <div class="admin-page-header">
      <div class="col-md-12 admin-page-menu">
        <ul class="nav nav-tabs nav-tabs-custom">
          <li v-if="checkMenu(adminManagement.orgType)">
            <router-link :to="{ path: '/admin/management/org_types' }">ORG Types</router-link>
          </li>
          <li v-if="checkMenu(adminManagement.employee)">
            <router-link :to="{ path: '/admin/management/users/employees' }">Employees</router-link>
          </li>
          <li v-if="checkMenu(adminManagement.post)">
            <router-link :to="{ path: '/admin/management/users/posts' }">Posts</router-link>
          </li>
          <li v-if="checkMenu(adminManagement.smartAssetTemplate)">
            <router-link :to="{ path: '/admin/management/users/templates' }">Templates</router-link>
          </li>
        </ul>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import permissonMixin from '@/mixins/permissionMixin'
import { adminManagement } from '@/constant/menu'
export default {
  name: 'adminTemplate',

  components: {},
  mixins: [permissonMixin],

  data() {
    return {
      adminManagement
    }
  },

  methods: {},

  computed: {},

  mounted() {}
}
</script>

<style lang="scss" scoped>
@import '../Adminpage.scss';
.alt {
  border-top: 1px dashed #cccccc;
}
.short {
  margin: 5px 0;
}
</style>
