<template>
  <div>
    <div class="d-flex justify-content-between my-3">
      <!-- <input v-debounce:400ms="searchInputChange" :value="searchvalue" class="search-input" type="text" placeholder="Search"> -->
      <SfInputSearch
        v-model="searchvalue"
        placeholder="Search"
        v-debounce:500ms="searchInputChange"
        class="sf-input-search"
      />
      <div>
        <button @click="ShownModalExport" class="sf-primary mr-2">
          <img src="@/assets/images/oppsite-arrow.svg" />
          Export
        </button>
        <a class="d-none" id="link-export" download></a>
        <button @click="showModalAddPlayer" class="sf-primary">Add New</button>
      </div>
    </div>
    <div class="sf-table">
      <b-table
        :items="dataTablePlayer.items"
        show-empty
        striped
        hover
        :fields="fields"
        head-variant="none"
        table-variant="none"
        thead-class="custom-b-table"
        :busy="loadingPlayer"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template v-slot:head(image)="field">
          {{ field.label }}
        </template>
        <template v-slot:cell(image)="field">
          <img
            v-if="field.item.orientation && field.item.rotate"
            class="img-player"
            :class="field.item.orientation"
            :src="
              require('@/assets/images/' +
                field.item.orientation +
                '-' +
                field.item.rotate +
                '.svg')
            "
          />
        </template>

        <template v-slot:head(name)="field">
          <span>{{ field.label }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:cell(name)="field">
          <p>
            <router-link
              :to="`/admin/clients/players/${field.item.id}`"
              :class="{ 'text-color-grey-40': !field.item.activated }"
              >{{ field.item.name }}</router-link
            >
          </p>
        </template>

        <template v-slot:head(hardware_type)="field">
          <span class="custome-arrows">Hardware</span>
        </template>
        <template v-slot:cell(hardware_type)="field">
          <p v-if="field.item.hardware_type">
            {{ field.item.hardware_type.displayname }}
          </p>
        </template>

        <template v-slot:head(running_campaign)="field">
          <span>{{ field.label }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:cell(running_campaign)="field">
          <p class="me-link" @click="goToEditCampaign(field.item.campaign_code)">
            {{ field.item.running_campaign }}
          </p>
        </template>

        <template v-slot:head(last_connection)="field">
          <span>{{ field.label }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:cell(last_connection)="field">
          <p
            :class="`text-${findStatusForLastConnected(field.item.last_connection)}`"
            v-if="field.item.last_connection && field.item.activated"
          >
            {{ timeSince(field.item.last_connection) }}
          </p>
        </template>

        <template v-slot:head(player_code)="field">
          <span>{{ field.label }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:cell(player_code)="field">
          <p v-if="field.item.player_code">
            {{ field.item.player_code }}
          </p>
        </template>

        <template v-slot:head(application_version)="field">
          <span>{{ field.label }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:cell(application_version)="field">
          <p v-if="field.item.application_version">
            {{ field.item.application_version }}
          </p>
        </template>

        <template v-slot:cell(actions)="field">
          <button
            @click="shownModalEdit(field.item.id, field.item.organization)"
            class="button-edit-table"
            title="Edit"
          ></button>
          <button
            @click="shownModalDelete(field.item.id)"
            class="button-delete-table"
            title="Delete"
          ></button>
        </template>
      </b-table>
    </div>
    <!-- pagination -->
    <!-- <p>{{dataTable.current_page}}</p> -->
    <div class="my-3">
      <b-pagination
        class="sf-b-pagination"
        v-model="currentPage"
        :total-rows="25 * dataTablePlayer.total_pages"
        :per-page="25"
        @change="changePage"
        :limit="6"
      />
    </div>
    <!-- Modal Create Player -->
    <SfModal
      title="Add New Player"
      :icon="'fa fa-user'"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalAddPlayer"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Name</label>
                </div>
                <input
                  v-model="newAdminPlayer.player.name"
                  placeholder="Name..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newAdminPlayer.player.name.required"
              >
                Name required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newAdminPlayer.player.name.maxLength"
              >
                Use less than 50 characters
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Address</label>
                </div>
                <gmap-autocomplete
                  class="sf-input-text"
                  @place_changed="setAddressData"
                  placeholder="Address..."
                >
                </gmap-autocomplete>
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newAdminPlayer.player.location.maxLength"
              >
                Use less than 100 characters
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Hardware</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="newAdminPlayer.player.admin_hardware_type_id"
                    :options="listHardwareOptions"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newAdminPlayer.player.admin_hardware_type_id.required"
              >
                Hardware required
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Time Zone</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="newAdminPlayer.player.time_zone_name"
                    :options="listTimezoneOptions"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newAdminPlayer.player.time_zone_name.required"
              >
                Please select Time Zone
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Description</label>
                </div>
                <textarea
                  v-model="newAdminPlayer.player.description"
                  placeholder="Description..."
                  class="sf-input-text sf-input-area"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sf-modal-footer mt-3">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button class="sf-secondary" type="button" @click="isShownModalAddPlayer = false">
                  Cancel
                </button>
                <button type="button" class="sf-primary" @click="createAdminPlayer">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfModal>
    <!-- Admin Modal Export -->
    <SfModal
      title="Export File"
      :width="400"
      :icon="'fas fa-plus-circle'"
      closeOnOverlay
      :show.sync="isShownModalExport"
    >
      <div class="row exception-row custom-row d-flex justify-content-center mt-3">
        <p>Do you want to export file?</p>
      </div>
      <div class="sf-modal-footer mt-3">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button class="sf-secondary" type="button" @click="isShownModalExport = false">
                  Cancel
                </button>
                <button type="button" class="sf-primary" @click="exportFile">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfModal>
    <!-- Admin Confirm Modal -->
    <AdminConfirm
      title="Confirm Join Organization"
      :width="400"
      closeOnOverlay
      :show.sync="isShownModalConfirm"
    >
      <div class="content-delete">
        You have no permission campaign of {{ org_name }}. You should join {{ org_name }} before
        adding, editing or viewing. Are you sure you want to join?
      </div>
      <div class="footer-button delete-confirm">
        <button @click="isShownModalConfirm = false">Cancel</button>
        <button @click="joinOrg">OK</button>
      </div>
    </AdminConfirm>

    <SfModal
      title="Edit Player"
      :icon="'fa fa-user'"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalEdit"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingPlayer">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Name</label>
                </div>
                <input
                  v-model="updateAdminPlayer.player.name"
                  placeholder="Name..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.updateAdminPlayer.player.name.required"
              >
                Name required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.updateAdminPlayer.player.name.maxLength"
              >
                Use less than 50 characters
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingPlayer">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Hardware</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="updateAdminPlayer.player.admin_hardware_type_id"
                    :options="listHardwareOptions"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.updateAdminPlayer.player.admin_hardware_type_id.required"
              >
                Hardware required
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingPlayer">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Address</label>
                </div>
                <gmap-autocomplete
                  class="sf-input-text"
                  @place_changed="setAddressDataForEditPlayer"
                  placeholder="Address..."
                  :value="updateAdminPlayer.player.location"
                ></gmap-autocomplete>
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.updateAdminPlayer.player.location.maxLength"
              >
                Use less than 100 characters
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingPlayer">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Rotate</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="updateAdminPlayer.player.rotate"
                    :options="rotate"
                    :settings="{ minimumResultsForSearch: -1 }"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.updateAdminPlayer.player.rotate.required"
              >
                Rotate required
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingPlayer">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Campaign</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="updateAdminPlayer.player.campaign_id"
                    :options="listCampaignByOrgOptions"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingPlayer">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Time Zone</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    v-model="updateAdminPlayer.player.time_zone_name"
                    :options="listTimezoneOptions"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingPlayer">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Description</label>
                </div>
                <textarea
                  v-model="updateAdminPlayer.player.description"
                  placeholder="Description..."
                  class="sf-input-text"
                />
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Browser Cache</label>
                </div>
                <div class="w-100">
                  <toggle-button
                    :value="updateAdminPlayer.player.is_browser_cache"
                    :sync="true"
                    :width="44"
                    :height="22"
                    :margin="2"
                    @change="onChangeToggleUpdateCacheBrowser"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Kiosk Mode</label>
                </div>
                <div class="w-100">
                  <toggle-button
                    :value="updateAdminPlayer.player.is_kiosk_mode"
                    :sync="true"
                    :width="44"
                    :height="22"
                    :margin="2"
                    @change="onChangeToggleUpdateKioskMode"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Wifi Type</label>
                </div>
                <div class="w-100">
                  <toggle-button
                    :value="updateAdminPlayer.player.is_wifi_type"
                    :sync="true"
                    :width="44"
                    :height="22"
                    :margin="2"
                    @change="onChangeToggleUpdateWifiType"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalEdit = false">Cancel</button>
        <button class="sf-primary" @click="editAdminPlayer">Save</button>
      </div>
    </SfModal>

    <SfDeleteModal :title="'Confirm Delete'" closeOnOverlay :show.sync="isShownModalDelete">
      <div class="content-delete">
        Deleting this player. Are you sure you want to delete this one?
      </div>
      <div class="footer-button delete-confirm">
        <button class="sf-secondary" @click="isShownModalDelete = false">Cancel</button>
        <button class="sf-primary" @click="deleteAdminPlayer">OK</button>
      </div>
    </SfDeleteModal>
  </div>
</template>
<script>
import SfModal from '@/components/SfModal.vue'
import AdminConfirm from '@/components/admin/AdminConfirm.vue'
import Select2 from 'v-select2-component'
import { timeSince, findStatusForLastConnected } from '@/helper/helper'
import AdminOrganizationService from '@/services/admin-organization.service.js'
import Vue from 'vue'
import moment from 'moment-timezone'
import { mapActions, mapState, mapGetters } from 'vuex'
import StatesService from '@/services/states.service.js'
import CountriesService from '@/services/countries.service'
import AdminPlayerService from '@/services/admin-player.service.js'
import { required, maxLength } from 'vuelidate/lib/validators'
import SfInputSearch from '@/components/SfInputSearch.vue'
import SfDeleteModal from '@/components/SfDeleteModal.vue'
import AdminHardwareService from '@/services/admin-hardware.service'

export default {
  name: 'playerTable',
  components: {
    SfModal,
    Select2,
    AdminConfirm,
    SfInputSearch,
    SfDeleteModal
  },
  props: {
    dataTable: {},
    similarOrg: {
      type: Boolean
    },
    org_id: {},
    org_name: {},
    user: {},
    show: {}
  },
  data() {
    return {
      fields: [
        {
          key: 'image',
          label: '',
          colType: 'image'
        },
        {
          key: 'name',
          sortable: true,
          colType: 'name',
          label: 'Name'
        },
        {
          key: 'hardware_type',
          sortable: true,
          colType: 'hardware-type',
          label: 'Hardware Type'
        },
        {
          key: 'running_campaign',
          sortable: true,
          colType: 'running_campaign',
          label: 'Running Campaign'
        },
        {
          key: 'last_connection',
          sortable: true,
          colType: 'last_connection',
          label: 'Last Connected'
        },
        {
          key: 'player_code',
          sortable: true,
          colType: 'player_code',
          label: 'Player Code'
        },
        {
          key: 'application_version',
          sortable: true,
          colType: 'application_version',
          label: 'Version'
        },
        {
          key: 'ACTIONS',
          colType: 'button',
          label: 'Actions'
        }
      ],
      isShownModalAddPlayer: false,
      isShownModalExport: false,
      isShownModalConfirm: false,
      isShownModalEdit: false,
      isShownModalDelete: false,
      isBusy: false,
      currentPage: 1,
      currentOrgID: '',
      newAdminPlayer: {
        player: {
          name: 'New Player',
          location: '',
          lat: '',
          lng: '',
          organization_id: '',
          postal_zip: '',
          city: '',
          province_state: '',
          country: '',
          time_zone_name: 'UTC',
          description: 'No description set',
          is_browser_cache: true,
          is_kiosk_mode: false,
          is_wifi_type: false,
          admin_hardware_type_id: ''
        }
      },
      updateAdminPlayer: {
        player: {
          name: '',
          location: '',
          lat: '',
          lng: '',
          organization_id: '',
          postal_zip: '',
          city: '',
          province_state: '',
          country: '',
          time_zone_name: '',
          rotate: 'default',
          campaign_id: '',
          description: '',
          is_browser_cache: true,
          is_kiosk_mode: false,
          is_wifi_type: false,
          admin_hardware_type_id: ''
        }
      },
      defaultAdminPlayer: {
        player: {
          name: '',
          location: '',
          lat: '',
          lng: '',
          organization_id: '',
          postal_zip: '',
          city: '',
          province_state: '',
          time_zone_value: 'UTC',
          rotate: 'default',
          campaign_id: '',
          description: 'No description set',
          organization: {
            id: ''
          },
          running_campaign: {
            id: ''
          },
          admin_hardware_type: {
            id: ''
          }
        }
      },
      addressDataFormat: '',
      listStates: [],
      submitted: false,
      url: '',
      searchvalue: '',
      isSearch: false,
      currentPlayerID: '',
      rotate: [
        {
          id: 'default',
          text: 'Default'
        },
        {
          id: 'flipped',
          text: 'Flipped'
        }
      ],
      listHardwareOptions: []
    }
  },
  validations: {
    newAdminPlayer: {
      player: {
        name: {
          required,
          maxLength: maxLength(50)
        },
        location: {
          maxLength: maxLength(250)
        },
        time_zone_name: {
          required
        },
        admin_hardware_type_id: {
          required
        }
      }
    },
    updateAdminPlayer: {
      player: {
        name: {
          required,
          maxLength: maxLength(50)
        },
        location: {
          maxLength: maxLength(250)
        },
        organization_id: {
          required
        },
        time_zone_name: {
          required
        },
        rotate: {
          required
        },
        admin_hardware_type_id: {
          required
        }
      }
    }
  },
  computed: {
    ...mapGetters('player', {
      listTimezoneOptions: 'listTimezoneOptions'
    }),
    ...mapState('adminorganization', {
      dataTablePlayer: 'dataTablePlayer',
      loadingPlayer: 'loadingPlayer'
    }),
    ...mapGetters('adminplayer', {
      isEmpty: 'isEmpty',
      listCampaignByOrgOptions: 'listCampaignByOrgOptions'
    })
  },
  methods: {
    timeSince,
    findStatusForLastConnected,
    ...mapActions('adminorganization', {
      getDataTableOfOrganization: 'getDataTableOfOrganization'
    }),
    showModalAddPlayer() {
      this.isShownModalAddPlayer = true
      this.getListTimezone()
      this.getListStates()
      this.getListCountries()
      this.setDataPlayer(this.newAdminPlayer.player, this.defaultAdminPlayer.player)
      this.newAdminPlayer.player.admin_hardware_type_id = this.hardwareTypeDefault
      this.submitted = false
    },
    getListHardwareType() {
      AdminHardwareService.getAllHardwaresNoPaging().then((res) => {
        this.listHardwareOptions = res.data
          .filter((hardwareType) => {
            return hardwareType.model_number !== 'all'
          })
          .map((hardwareType) => {
            if (hardwareType.default) this.hardwareTypeDefault = hardwareType.id
            return {
              id: hardwareType.id,
              text: hardwareType.displayname
            }
          })
      })
    },
    goToEditCampaign(id) {
      this.submitted = false
      this.currentOrgID = id
      if (this.similarOrg) {
        this.$router.push(`/campaigns/${id}/edit`)
      } else {
        this.isShownModalConfirm = true
      }
    },
    joinOrg() {
      AdminOrganizationService.joinOrganization(this.org_id).then((res) => {
        this.$bus.$emit('update_joins_organization', res.data)
        this.$toast.success('Joined Organization')
        // set cookies
        const tokenExpired = Vue.$cookies.get('tokenExpired')
        const remember = Vue.$cookies.get('rememberMe') === 'true'
        this.user.organization = res.data.organization.name
        this.user.is_parent_organization = res.data.organization.is_parent_organization
        if (tokenExpired && remember) {
          Vue.$cookies.set(
            'user',
            this.user,
            moment(tokenExpired).format('ddd, DD MMM YYYY HH:mm:ss ZZ')
          )
        } else {
          Vue.$cookies.set('user', this.user, 0)
        }
        // go to campaign page
        this.$router.push(`/campaigns/${this.currentOrgID}/edit`)
      })
    },
    getListTimezone() {
      this.$store.dispatch('player/getListTimezone')
    },
    getListStates(country) {
      StatesService.getAllStates(country).then((res) => {
        this.listStates = res.data
      })
    },
    setAddressData(place) {
      const self = this
      this.formatAddress(place.address_components)
      // set country_id
      const country_id = this.listCountries.filter(function (elem) {
        if (elem.name === self.addressDataFormat.country) return elem.id
      })
      this.newAdminPlayer.player.country_id = country_id[0] ? country_id[0].id : 0
      // set province_state
      this.getListStates(this.newAdminPlayer.player.country_id)
      const province_state = this.listStates.filter(function (elem) {
        if (elem.text === self.addressDataFormat.administrative_area_level_1) return elem.id
      })
      this.newAdminPlayer.player.province_state = province_state[0]
        ? province_state[0].id
        : self.addressDataFormat.administrative_area_level_1

      this.newAdminPlayer.player.lat = place.geometry.location.lat()
      this.newAdminPlayer.player.lng = place.geometry.location.lng()
      this.newAdminPlayer.player.location = place.formatted_address
      this.newAdminPlayer.player.postal_zip = self.addressDataFormat.postal_code
      this.newAdminPlayer.player.city = self.addressDataFormat.locality
    },
    formatAddress(address) {
      const components = {}
      address.map((key, index) => {
        key.types.map((key1, index1) => {
          components[key1] = key.long_name
        })
      })
      this.addressDataFormat = components
    },
    getListCountries() {
      CountriesService.getAllCountries().then((res) => {
        this.listCountries = res.data
      })
    },
    createAdminPlayer() {
      this.submitted = true
      if (this.$v.newAdminPlayer.$invalid) {
        return
      }
      this.newAdminPlayer.player.organization_id = this.org_id
      AdminPlayerService.createAdminPlayer(this.newAdminPlayer)
        .then((_) => {
          this.$toast.success('Player successfully created')
          this.isShownModalAddPlayer = false
          this.fetchData(this.currentPage, this.searchvalue)
        })
        .catch((err) => {
          this.isShownModalAddPlayer = false
          this.$toast.error(err.message)
        })
    },
    setDataPlayer(player, new_player) {
      player.name = new_player.name
      player.location = new_player.location
      player.rotate = new_player.rotate
      player.time_zone_name = new_player.time_zone_value
      player.organization_id =
        new_player.organization && new_player.organization.id ? new_player.organization.id : ''
      player.campaign_id =
        new_player.running_campaign && new_player.running_campaign.id
          ? new_player.running_campaign.id
          : ''
      player.description = new_player.description
      player.is_browser_cache = new_player.is_browser_cache
      player.is_kiosk_mode = new_player.is_kiosk_mode
      player.is_wifi_type = new_player.is_wifi_type
      player.admin_hardware_type_id =
        new_player.hardware_type && new_player.hardware_type.id ? new_player.hardware_type.id : ''
    },
    ShownModalExport() {
      this.isShownModalExport = true
      AdminOrganizationService.getDataToExport({ type: 'players', id: this.org_id }).then((res) => {
        this.url = res.data.url
      })
    },
    exportFile() {
      const tag = document.getElementById('link-export')
      tag.setAttribute('target', '_blank')
      tag.setAttribute('href', this.url)
      tag.click()
      this.isShownModalExport = false
    },
    searchInputChange(val) {
      this.searchvalue = val
      this.currentPage = 1
      this.fetchData(this.currentPage, this.searchvalue)
    },
    fetchData(currentPage, search) {
      this.getDataTableOfOrganization({
        id: this.org_id,
        type: 'players',
        page: currentPage,
        limit: 25,
        search: this.searchvalue
      })
    },
    changePage(page) {
      this.currentPage = page
      this.getDataTableOfOrganization({
        id: this.org_id,
        type: 'players',
        page: this.currentPage,
        limit: 25,
        search: this.searchvalue
      })
    },
    shownModalEdit(playerID, org) {
      this.submitted = false
      this.setDataPlayer(this.updateAdminPlayer.player, this.defaultAdminPlayer.player)
      this.isShownModalEdit = true
      this.currentPlayerID = playerID
      this.getListTimezone()
      this.getAllCampaigns(this.currentOrgID)
      this.getItemByID()
      this.getListCountries()
      this.getListStates()
    },
    getItemByID() {
      AdminPlayerService.getDetailAdminPlayer(this.currentPlayerID)
        .then((response) => {
          this.setDataPlayer(this.updateAdminPlayer.player, response.data)
        })
        .catch((err) => {
          this.isShownModalEdit = true
          this.$toast.error(err.message)
        })
    },
    shownModalDelete(id) {
      this.currentPlayerID = id
      this.isShownModalDelete = true
    },
    deleteAdminPlayer() {
      AdminPlayerService.deleteAdminPlayer(this.currentPlayerID).then((_) => {
        this.isShownModalDelete = false
        if (this.dataTablePlayer.items.length === 1 && this.currentPage > 1) {
          this.currentPage = this.currentPage - 1
        }
        this.$toast.success('Player successfully deleted')
        this.fetchData(this.currentPage, this.searchvalue)
      })
    },
    editAdminPlayer() {
      this.submitted = true
      if (this.$v.updateAdminPlayer.$invalid) {
        return
      }
      AdminPlayerService.updateAdminPlayer(this.currentPlayerID, this.updateAdminPlayer)
        .then((_) => {
          this.$toast.success('Player successfully updated')
          this.fetchData(this.currentPage, this.searchvalue)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
        .finally(() => {
          this.isShownModalEdit = false
        })
    },
    onChangeToggleUpdateCacheBrowser(value) {
      this.updateAdminPlayer.player.is_browser_cache = value.value
    },
    onChangeToggleUpdateKioskMode(value) {
      this.updateAdminPlayer.player.is_kiosk_mode = value.value
    },
    onChangeToggleUpdateWifiType(value) {
      this.updateAdminPlayer.player.is_wifi_type = value.value
    },
    setAddressDataForEditPlayer(place) {
      const self = this
      this.formatAddress(place.address_components)
      this.updateAdminPlayer.player.country = self.addressDataFormat.country
      this.updateAdminPlayer.player.province_state =
        self.addressDataFormat.administrative_area_level_1
      this.updateAdminPlayer.player.lat = place.geometry.location.lat()
      this.updateAdminPlayer.player.lng = place.geometry.location.lng()
      this.updateAdminPlayer.player.location = place.formatted_address
      this.updateAdminPlayer.player.postal_zip = self.addressDataFormat.postal_code
        ? self.addressDataFormat.postal_code
        : ''
      this.updateAdminPlayer.player.city = self.addressDataFormat.locality
        ? self.addressDataFormat.locality
        : ''
    },
    getAllCampaigns(orgID) {
      this.$store.dispatch('adminplayer/getCampaignByOrg', orgID)
    }
  },
  watch: {
    show(value) {
      if (value === 'players') {
        this.fetchData(this.currentPage, this.searchvalue)
        this.getListHardwareType()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import './bTable.scss';
@import '@/pages/admin/Adminpage.scss';
.text-area-control {
  padding: 12px 0px;
  width: 90%;
  border: none;
  outline: none;
  height: 100px;
  max-height: 150px;
  min-height: 37px;
  margin: 1px;
}

.input-group-text-area {
  height: auto !important;
  .custom-input-group-text {
    align-items: flex-start;
    .input-group-text {
      height: 37px !important;
    }
  }
}
.player-active {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #76c322;
  margin-top: 5px;
}

.player-inactive {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #e00008;
  margin-top: 5px;
}
.img-player {
  max-width: 40px;
  max-height: 32px;
  &.portrait {
    margin-left: 18px;
    margin-top: -5px;
  }
}
</style>
