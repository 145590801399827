<template>
  <div class="admin-org-detail">
    <div class="container-fluid">
      <div class="row">
        <div class="col-8 d-flex align-items-center">
          <div v-if="!loading">
            <div v-if="itemsByID.status" class="active-org"></div>
            <div v-else class="inactive-org"></div>
          </div>
          <div class="title-organization ml-30" v-if="loading">
            <content-placeholders>
              <content-placeholders-text :lines="1" />
            </content-placeholders>
          </div>
          <div v-else class="org-information">
            <div class="org-name d-flex align-items-center justify-content-center">
              <h2>{{ itemsByID.name }}</h2>
              <button @click="showModalEditName" class="button-edit-pen ml-2"></button>
            </div>
            <h5 class="org-type pt-2">{{ itemsByID.org_type && itemsByID.org_type.name }}</h5>
          </div>
        </div>
        <div class="col-4 d-flex justify-content-end align-items-center">
          <button
            v-if="!loading"
            @click="showJoinOrganization()"
            class="button-join-organization mr-1"
            :title="'Join'"
          ></button>
          <div v-if="loading" class="title-organization">
            <content-placeholders>
              <content-placeholders-text :lines="1" />
            </content-placeholders>
          </div>
          <p v-else class="parent-org">{{ typeNameOrganization }}</p>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="sf-card">
            <div class="card-body editable">
              <div class="row">
                <div class="col-12">
                  <button
                    v-if="!loading"
                    @click="showModalEditLocation"
                    class="button-edit-pen"
                    type="button"
                  ></button>
                  <div class="text-group">
                    <div class="text_lable">Address</div>
                    <div v-if="loading" class="text-placeholder">
                      <content-placeholders>
                        <content-placeholders-text :lines="1" />
                      </content-placeholders>
                    </div>
                    <div v-else class="text_content">{{ itemsByID.address }}</div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-6">
                      <div class="text-group">
                        <div class="text_lable">Country</div>
                        <div v-if="loading" class="text-placeholder">
                          <content-placeholders>
                            <content-placeholders-text :lines="1" />
                          </content-placeholders>
                        </div>
                        <div v-else class="text_content">{{ itemsByID.country }}</div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="text-group">
                        <div class="text_lable long-text">Province/State</div>
                        <div v-if="loading" class="text-placeholder">
                          <content-placeholders>
                            <content-placeholders-text :lines="1" />
                          </content-placeholders>
                        </div>
                        <div v-else class="text_content">{{ itemsByID.province_state }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="text-group">
                        <div class="text_lable">City</div>
                        <div v-if="loading" class="text-placeholder">
                          <content-placeholders>
                            <content-placeholders-text :lines="1" />
                          </content-placeholders>
                        </div>
                        <div v-else class="text_content">{{ itemsByID.city }}</div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="text-group">
                        <div class="text_lable long-text">Postal/Zip</div>
                        <div v-if="loading" class="text-placeholder">
                          <content-placeholders>
                            <content-placeholders-text :lines="1" />
                          </content-placeholders>
                        </div>
                        <div v-else class="text_content">{{ itemsByID.postal_zip }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="sf-card">
            <div class="card-body editable">
              <div class="row">
                <div class="col-12">
                  <button
                    v-if="!loading"
                    @click="showModalEditInfoUser"
                    class="button-edit-pen"
                    type="button"
                  ></button>
                  <div class="text-group">
                    <div class="text_lable">Name</div>
                    <div v-if="loading" class="text-placeholder">
                      <content-placeholders>
                        <content-placeholders-text :lines="1" />
                      </content-placeholders>
                    </div>
                    <div v-else class="text_content">{{ itemsByID.name }}</div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="text-group">
                    <div class="text_lable">Email</div>
                    <div v-if="loading" class="text-placeholder">
                      <content-placeholders>
                        <content-placeholders-text :lines="1" />
                      </content-placeholders>
                    </div>
                    <div v-else class="text_content">{{ itemsByID.email }}</div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="text-group">
                    <div class="text_lable">Phone</div>
                    <div v-if="loading" class="text-placeholder">
                      <content-placeholders>
                        <content-placeholders-text :lines="1" />
                      </content-placeholders>
                    </div>
                    <div v-else class="text_content">{{ itemsByID.phone }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Tab -->
      <div class="admin-tab">
        <b-tabs content-class="mt-3" fill>
          <b-tab title="ORGANIZATION" active>
            <OrganizationTable
              :dataOrg="itemsByID.org_type"
              :org_id="organizationID"
              :itemsByID="itemsByID"
              :loadingOrganization="loading"
            ></OrganizationTable>
          </b-tab>
          <b-tab title="PLAYERS" @click="changeTab('players')">
            <PlayerTable
              :show.sync="statusTab"
              :similarOrg="isItsSelf"
              :org_id="organizationID"
              :org_name="itemsByID.name"
              :user="user"
            ></PlayerTable>
          </b-tab>
          <b-tab title="CAMPAIGNS" @click="changeTab('campaigns')">
            <CampaignTable
              :show.sync="statusTab"
              :similarOrg="isItsSelf"
              :org_id="organizationID"
              :org_name="itemsByID.name"
              :user="user"
            ></CampaignTable>
          </b-tab>
          <b-tab title="USERS" @click="changeTab('users')">
            <UserTable :show.sync="statusTab" :org_id="organizationID"></UserTable>
          </b-tab>
          <b-tab title="ROLES" @click="changeTab('roles')">
            <RoleTable :show.sync="statusTab" :org_id="organizationID"></RoleTable>
          </b-tab>
        </b-tabs>
      </div>
      <!-- Modal Edit Name -->
      <SfModal
        title="Edit Organization"
        :width="768"
        closeOnOverlay
        :show.sync="isShownModalEditName"
      >
        <div class="sf-modal-body">
          <div class="container">
            <div class="row mt-3">
              <div class="col-9">
                <div class="sf-form-group">
                  <div class="sf-label-group">
                    <label class="primary-label">Organization Name</label>
                  </div>
                  <input
                    :value="infoUpdateOrgName.name"
                    @input="changeNameOrg"
                    placeholder="Name ..."
                    class="sf-input-text"
                  />
                </div>
                <div
                  class="invalid-error text-right"
                  v-if="submitted && !$v.infoUpdateOrgName.name.required"
                >
                  Name required
                </div>
                <div
                  class="invalid-error text-right"
                  v-if="submitted && !$v.infoUpdateOrgName.name.maxLength"
                >
                  Use less than 50 characters
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-9">
                <div class="sf-form-group">
                  <div class="sf-label-group">
                    <label class="primary-label">Industry</label>
                  </div>
                  <input
                    v-model="infoUpdateOrgName.industry"
                    placeholder="Industry ..."
                    class="sf-input-text"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-9">
                <div class="sf-form-group">
                  <div class="sf-label-group">
                    <label class="primary-label">Type</label>
                  </div>
                  <div class="sf-select-group">
                    <Select2
                      class="sf-select2"
                      v-model="infoUpdateOrgName.org_type_id"
                      :options="orgTypeOptions"
                      :settings="{ minimumResultsForSearch: -1 }"
                    />
                    <span class="lb-right caret"></span>
                  </div>
                </div>
                <div
                  class="invalid-error text-right"
                  v-if="submitted && !$v.infoUpdateOrgName.org_type_id.required"
                >
                  Select Organization Type
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-9">
                <div class="sf-form-group">
                  <div class="sf-label-group">
                    <label class="primary-label">Pricing</label>
                  </div>
                  <div class="sf-select-group">
                    <Select2
                      class="sf-select2"
                      v-model="infoUpdateOrgName.pricing"
                      :options="pricingOptions"
                      :settings="{ minimumResultsForSearch: -1 }"
                    />
                    <span class="lb-right caret"></span>
                  </div>
                </div>
                <div
                  class="invalid-error text-right"
                  v-if="submitted && !$v.infoUpdateOrgName.org_type_id.required"
                >
                  Select Pricing
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-9">
                <div class="sf-form-group">
                  <div class="sf-label-group">
                    <label class="primary-label">Active</label>
                  </div>
                  <div class="w-100">
                    <toggle-button
                      :value="infoUpdateOrgName.status"
                      :sync="true"
                      @change="onChangeToggleDetail"
                      :width="44"
                      :height="22"
                      :margin="2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sf-modal-footer mt-3">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="sf-modal-action">
                  <button class="sf-secondary" type="button" @click="isShownModalEditName = false">
                    Cancel
                  </button>
                  <button type="button" class="sf-primary" @click="updateNameOrg">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SfModal>
      <!-- Modal Edit Location -->
      <SfModal
        title="Edit Organization"
        :width="768"
        closeOnOverlay
        :show.sync="isShownModalEditLocation"
      >
        <div class="sf-modal-body">
          <div class="container">
            <div class="row mt-3">
              <div class="col-9">
                <div class="sf-form-group">
                  <div class="sf-label-group">
                    <label class="primary-label">Address</label>
                  </div>
                  <gmap-autocomplete
                    class="sf-input-text"
                    @change="checkAdress"
                    @place_changed="setAddressData"
                    placeholder="Address ..."
                    :value="infoUpdateOrgLocation.address"
                  ></gmap-autocomplete>
                </div>
                <div
                  class="invalid-error"
                  v-if="submitted && !$v.infoUpdateOrgLocation.address.required"
                >
                  Address required
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-9">
                <div class="sf-form-group">
                  <div class="sf-label-group">
                    <label class="primary-label">Country</label>
                  </div>
                  <input
                    v-model="infoUpdateOrgLocation.country"
                    placeholder="Country ..."
                    class="sf-input-text"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-9">
                <div class="sf-form-group">
                  <div class="sf-label-group">
                    <label class="primary-label">State</label>
                  </div>
                  <input
                    v-model="infoUpdateOrgLocation.state"
                    placeholder="State ..."
                    class="sf-input-text"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-9">
                <div class="sf-form-group">
                  <div class="sf-label-group">
                    <label class="primary-label">City</label>
                  </div>
                  <input
                    v-model="infoUpdateOrgLocation.city"
                    placeholder="City ..."
                    class="sf-input-text"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-9">
                <div class="sf-form-group">
                  <div class="sf-label-group">
                    <label class="primary-label">Postal/Zip</label>
                  </div>
                  <input
                    v-model="infoUpdateOrgLocation.zip"
                    placeholder="Postal/Zip ..."
                    class="sf-input-text"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sf-modal-footer mt-3">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="sf-modal-action">
                  <button
                    class="sf-secondary"
                    type="button"
                    @click="isShownModalEditLocation = false"
                  >
                    Cancel
                  </button>
                  <button type="button" class="sf-primary" @click="saveEditLocation">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SfModal>
      <!-- Modal Edit Info Of User -->
      <SfModal
        :title="'Edit Organization'"
        :width="600"
        closeOnOverlay
        :show.sync="isShownModalEditInfoUser"
      >
        <div class="sf-modal-body">
          <div class="container">
            <div class="row mt-3">
              <div class="col-9">
                <div class="sf-form-group">
                  <div class="sf-label-group">
                    <label class="primary-label">Name</label>
                  </div>
                  <input
                    v-model="infoUpdateOrgUser.name"
                    placeholder="Name..."
                    class="sf-input-text"
                  />
                </div>
                <div class="invalid-error" v-if="submitted && !$v.infoUpdateOrgUser.name.required">
                  Name required
                </div>
                <div class="invalid-error" v-if="submitted && !$v.infoUpdateOrgUser.name.maxLength">
                  Use less than 50 characters
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-9">
                <div class="sf-form-group">
                  <div class="sf-label-group">
                    <label class="primary-label">Email</label>
                  </div>
                  <input
                    v-model="infoUpdateOrgUser.email"
                    v-on:keyup="checkEmail(infoUpdateOrgUser.email)"
                    placeholder="Email..."
                    class="sf-input-text"
                  />
                </div>
                <div class="invalid-error" v-if="submitted && !$v.infoUpdateOrgUser.email.email">
                  Email should be valids
                </div>
                <div
                  class="invalid-error"
                  v-if="submitted && !$v.infoUpdateOrgUser.email.maxLength"
                >
                  Use less than 100 characters
                </div>
                <div class="invalid-error" v-if="!emailCanUsed">Email is currently used</div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-9">
                <div class="sf-form-group">
                  <div class="sf-label-group">
                    <label class="primary-label">Phone</label>
                  </div>
                  <input
                    v-model="infoUpdateOrgUser.phone"
                    placeholder="Phone..."
                    class="sf-input-text"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sf-modal-footer mt-3">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="sf-modal-action">
                  <button
                    class="sf-secondary"
                    type="button"
                    @click="isShownModalEditInfoUser = false"
                  >
                    Cancel
                  </button>
                  <button type="button" class="sf-primary" @click="updateInfoUser">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SfModal>
      <SfModal
        title="Confirm Join Organization"
        :width="400"
        closeOnOverlay
        :show.sync="isShownModalConfirm"
      >
        <div class="content-delete">Joining this organization. Are you sure you want to join?</div>
        <div class="sf-modal-footer mt-3">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="sf-modal-action">
                  <button class="sf-secondary" type="button" @click="isShownModalConfirm = false">
                    Cancel
                  </button>
                  <button type="button" class="sf-primary" @click="joinOrganization">OK</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SfModal>
    </div>
  </div>
</template>
<script>
import OrganizationTable from './btable/OrganizationTable'
import PlayerTable from './btable/PlayerTable'
import CampaignTable from './btable/CampaignTable'
import UserTable from './btable/UserTable'
import RoleTable from './btable/RoleTable'
import SfModal from '@/components/SfModal.vue'
import { mapActions, mapState, mapMutations } from 'vuex'
import Select2 from 'v-select2-component'
import { required, maxLength, email } from 'vuelidate/lib/validators'
import Vue from 'vue'
import moment from 'moment-timezone'
import AdminOrganizationService from '@/services/admin-organization.service'

export default {
  name: 'adminPanel',
  components: {
    OrganizationTable,
    PlayerTable,
    CampaignTable,
    UserTable,
    RoleTable,
    SfModal,
    Select2
  },
  data() {
    return {
      isShownModalEditName: false,
      isShownModalEditLocation: false,
      isShownModalEditInfoUser: false,
      organizationID: '',
      currentType: '',
      infoUpdateOrgName: {
        name: '',
        org_type_id: '',
        pricing: '',
        status: '',
        industry: ''
      },
      infoUpdateOrgLocation: {
        address: '',
        country: '',
        state: '',
        city: '',
        zip: ''
      },
      infoUpdateOrgUser: {
        name: '',
        email: '',
        phone: ''
      },
      addressDataFormat: '',
      submitted: false,
      user: '',
      isItsSelf: false,
      emailCanUsed: true,
      typeNameOrganization: '',
      statusTab: '',
      isShownModalConfirm: false
    }
  },
  validations: {
    infoUpdateOrgName: {
      name: {
        required,
        maxLength: maxLength(50)
      },
      org_type_id: {
        required
      },
      pricing: {
        required
      }
    },
    infoUpdateOrgLocation: {
      address: {
        required
      }
    },
    infoUpdateOrgUser: {
      name: {
        required,
        maxLength: maxLength(50)
      },
      email: {
        maxLength: maxLength(100),
        email
      }
    }
  },
  methods: {
    ...mapActions('adminorganization', {
      getDetailOrganization: 'getDetailOrganization',
      getDataTableOfOrganization: 'getDataTableOfOrganization'
    }),
    ...mapMutations('adminorganization', {
      setItemsName: 'SET_ITEMS_NAME',
      setItemsInfoUser: 'SET_ITEMS_INFO_USER',
      setItemsLocation: 'SET_ITEMS_LOCATION'
    }),
    // update Name Organization
    showModalEditName() {
      this.submitted = false
      this.isShownModalEditName = true
      this.infoUpdateOrgName.name = this.itemsByID.name
      this.loadPricing()
      this.loadOrgType()
      this.infoUpdateOrgName.org_type_id = this.itemsByID.org_type ? this.itemsByID.org_type.id : ''
      this.infoUpdateOrgName.pricing = this.itemsByID.pricing ? this.itemsByID.pricing : ''
      this.infoUpdateOrgName.status = this.itemsByID.status
      this.infoUpdateOrgName.industry = this.itemsByID.industry ? this.itemsByID.industry : ''
    },
    changeNameOrg(e) {
      this.infoUpdateOrgName.name = e.target.value
    },
    updateNameOrg() {
      this.submitted = true
      if (this.$v.infoUpdateOrgName.$invalid) {
        return
      }
      const data = {
        name: this.infoUpdateOrgName.name,
        org_type_id: this.infoUpdateOrgName.org_type_id,
        pricing: this.infoUpdateOrgName.pricing,
        status: this.infoUpdateOrgName.status,
        industry: this.infoUpdateOrgName.industry
      }
      AdminOrganizationService.updateOrganization(this.organizationID, {
        organization: data
      }).then((_) => {
        this.$toast.success('Update organization successfully')
        this.isShownModalEditName = false
        this.setItemsName(data)

        // set cookies
        if (this.isItsSelf) {
          this.setNameToHeader(this.infoUpdateOrgName)
        }
      })
    },

    // edit location
    showModalEditLocation() {
      this.submitted = false
      this.isShownModalEditLocation = true
      this.infoUpdateOrgLocation.address = this.itemsByID.address
      this.infoUpdateOrgLocation.country = this.itemsByID.country
      this.infoUpdateOrgLocation.state = this.itemsByID.province_state
      this.infoUpdateOrgLocation.city = this.itemsByID.city
      this.infoUpdateOrgLocation.zip = this.itemsByID.postal_zip
    },
    setAddressData(place) {
      const self = this
      this.formatAddress(place.address_components)
      this.infoUpdateOrgLocation.state = self.addressDataFormat.administrative_area_level_1
      this.infoUpdateOrgLocation.country = self.addressDataFormat.country
      this.infoUpdateOrgLocation.zip = self.addressDataFormat.postal_code
        ? self.addressDataFormat.postal_code
        : ''
      this.infoUpdateOrgLocation.city = self.addressDataFormat.locality
        ? self.addressDataFormat.locality
        : ''
      this.infoUpdateOrgLocation.address = place.formatted_address
    },
    formatAddress(address) {
      const components = {}
      address.map((key, index) => {
        key.types.map((key1, index1) => {
          components[key1] = key.long_name
        })
      })
      this.addressDataFormat = components
    },
    saveEditLocation() {
      this.submitted = true
      const data = {
        address: this.infoUpdateOrgLocation.address,
        country: this.infoUpdateOrgLocation.country,
        province_state: this.infoUpdateOrgLocation.state,
        postal_zip: this.infoUpdateOrgLocation.zip,
        city: this.infoUpdateOrgLocation.city
      }
      if (this.$v.infoUpdateOrgLocation.$invalid) {
        return
      }
      AdminOrganizationService.updateOrganization(this.organizationID, {
        organization: data
      })
        .then((_) => {
          this.$toast.success('Edit organization successfully')
          this.setItemsLocation(data)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
        .finally(() => {
          this.isShownModalEditLocation = false
        })
    },

    checkAdress(e) {
      this.infoUpdateOrgLocation.address = e.target.value
    },

    // edit Info User
    showModalEditInfoUser() {
      this.isShownModalEditInfoUser = true
      this.infoUpdateOrgUser.name = this.itemsByID.name
      this.infoUpdateOrgUser.email = this.itemsByID.email
      this.infoUpdateOrgUser.phone = this.itemsByID.phone
      this.submitted = false
      this.emailCanUsed = true
    },
    updateInfoUser() {
      this.submitted = true
      if (this.$v.infoUpdateOrgUser.$invalid || !this.emailCanUsed) {
        return
      }
      AdminOrganizationService.updateOrganization(this.organizationID, {
        organization: {
          name: this.infoUpdateOrgUser.name,
          email: this.infoUpdateOrgUser.email,
          phone: this.infoUpdateOrgUser.phone
        }
      })
        .then((_) => {
          this.$toast.success('Edit organization successfully')
          this.isShownModalEditInfoUser = false
          this.setItemsInfoUser({
            name: this.infoUpdateOrgUser.name,
            email: this.infoUpdateOrgUser.email,
            phone: this.infoUpdateOrgUser.phone
          })

          // set cookies
          if (this.isItsSelf) {
            this.setNameToHeader(this.infoUpdateOrgUser)
          }
        })
        .catch((err) => {
          this.$toast.error(err.message)
          this.isShownModalEditInfoUser = false
        })
    },

    changeTab(value) {
      if (value !== this.currentType) {
        this.currentType = value
        if (value === 'campaigns') {
          this.statusTab = 'campaigns'
        } else if (value === 'players') {
          this.statusTab = 'players'
        } else if (value === 'users') {
          this.statusTab = 'users'
        } else if (value === 'roles') {
          this.statusTab = 'roles'
        }
      }
    },
    fetchData(type, currentPage, search) {
      this.getDataTableOfOrganization({
        id: this.organizationID,
        type: type,
        page: currentPage,
        limit: 10,
        search: search
      })
    },
    setNameToHeader(data) {
      const tokenExpired = Vue.$cookies.get('tokenExpired')
      const remember = Vue.$cookies.get('rememberMe') === 'true'
      this.user.organization = data.name
      if (tokenExpired && remember) {
        Vue.$cookies.set(
          'user',
          this.user,
          moment(tokenExpired).format('ddd, DD MMM YYYY HH:mm:ss ZZ')
        )
      } else {
        Vue.$cookies.set('user', this.user, 0)
      }
      this.$bus.$emit('update_joins_organization', {
        organization: { name: data.name }
      })
    },
    checkEmail(email) {
      AdminOrganizationService.checkEmailOrgExist({
        organization_id: this.organizationID,
        email: email
      }).then((res) => {
        this.emailCanUsed = res.data.can_use
      })
    },
    loadPricing() {
      this.$store.dispatch('adminorganization/getListPricing')
    },
    loadOrgType() {
      this.$store.dispatch('adminorganization/getListOrgtype')
    },
    onChangeToggleDetail(value) {
      this.infoUpdateOrgName.status = value.value
    },
    joinOrganization() {
      AdminOrganizationService.joinOrganization(this.organizationID)
        .then((response) => {
          this.$toast.success('Joined Organization')
          this.setCookiesAndUpdateHeader(response.data)
          this.getInfoUser()
          this.$cable.unsubscribe('AccountChannel')
          this.$cable.subscribe({
            channel: 'AccountChannel',
            organization: this.organizationID
          })
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalConfirm = false
    },
    getInfoUser() {
      this.$store.dispatch('user/getCurrent')
    },
    showJoinOrganization(organization_id) {
      this.isShownModalConfirm = true
    },
    setCookiesAndUpdateHeader(data) {
      const tokenExpired = this.$cookies.get('tokenExpired')
      const remember = this.$cookies.get('rememberMe') === 'true'
      this.user.organization = data.organization.name
      this.user.is_parent_organization = data.organization.is_parent_organization
      if (tokenExpired && remember) {
        this.$cookies.set(
          'user',
          this.user,
          moment(tokenExpired).format('ddd, DD MMM YYYY HH:mm:ss ZZ')
        )
      } else {
        this.$cookies.set('user', this.user, 0)
      }
      this.$bus.$emit('update_joins_organization', {
        organization: { name: data.organization.name }
      })
    }
  },
  mounted() {
    this.organizationID = this.$route.params.id
    this.getDetailOrganization(this.organizationID)
  },
  watch: {
    itemsByID(value) {
      const user = Vue.$cookies.get('user')
      if (user) {
        this.user = user
        if (this.itemsByID.name === this.user.organization) {
          this.isItsSelf = true
        }
      }
      this.typeNameOrganization = !this.itemsByID.parent
        ? 'Parent Organization'
        : 'Child Organization'
    }
  },
  computed: {
    ...mapState('adminorganization', {
      itemsByID: 'itemsByID',
      orgTypeOptions: 'orgTypeOptions',
      pricingOptions: 'pricingOptions',
      loading: 'loading'
    })
  }
}
</script>
<style lang="scss" scoped>
@import '@/pages/admin/Adminpage.scss';
.admin-panel {
  margin-right: 20px;
  margin-left: 20px;
}

.active-org {
  width: 20px;
  height: 20px;
  background: #76c322;
  border-radius: 20px;
  margin-right: 10px;
}
.inactive-org {
  width: 20px;
  height: 20px;
  background: #e00008;
  border-radius: 20px;
  margin-right: 10px;
}

.header-admin {
  margin-top: 15px;
  background-color: inherit !important;

  .org-name {
    font: 24px 'Open Sans', sans-serif;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #00325b;
  }

  button {
    margin-left: 10px;
  }
  .join-content {
    display: flex;
    justify-content: space-between;
    .parent-org {
      font: 14px 'Opens sans', sans-serif;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #525f7f;
      text-align: center;
      line-height: 35px;
    }
  }
}

.long-text {
  width: 128px;
}

.text-lable-icon {
  display: flex;

  p {
    width: 70px;
    text-align: left;
    padding-left: 10px;
  }
}

.col-custom {
  width: 50%;
  padding: 0;

  &:nth-child(1) {
    padding-right: 15px;
  }

  &:nth-child(2) {
    padding-left: 15px;
  }
}

.button-join-organization {
  background-image: url('../../../../assets/images/pointing-right.svg');
  width: 34px;
  height: 34px;
  background-size: cover;
  background-color: transparent;
  border: 0;
  margin-right: 12px;
  &:hover {
    background-image: url('../../../../assets/images/pointing-right-active.svg');
  }
}

.custom-row {
  margin-top: 20px;
}
.icon-none {
  opacity: 0;
}
.active-text {
  font: 15px 'Opens Sans', sans-serif;
  padding-right: 20px;
}
.title-organization {
  width: 250px;
}
.ml-30 {
  margin-left: 30px;
}
.text-placeholder {
  width: 120px;
  padding-left: 10px;
  padding-top: 5px;
}
</style>
