<template>
  <div class="admin-page">
    <div class="admin-page-header">
      <div class="col-md-12 admin-page-menu">
        <ul class="nav nav-tabs nav-tabs-custom">
          <li v-if="checkMenu(adminSystem.adminPiFirmware)">
            <router-link :to="{ path: '/admin/system/player_apps' }"
              >Player Applications</router-link
            >
          </li>
          <li v-if="checkMenu(adminSystem.slideTemplate)">
            <router-link :to="{ path: '/admin/system/slide_templates' }"
              >Slide Templates</router-link
            >
          </li>
          <li v-if="checkMenu(adminSystem.term)">
            <router-link :to="{ path: '/admin/system/terms' }">Term And Conditions</router-link>
          </li>
          <li v-if="checkMenu(adminSystem.role)">
            <router-link :to="{ path: '/admin/system/roles' }">Roles</router-link>
          </li>
          <li v-if="checkMenu(adminSystem.update)">
            <router-link :to="{ path: '/admin/system/update' }">Update</router-link>
          </li>
          <li v-if="checkMenu(adminSystem.adminHardwareType)">
            <router-link :to="{ path: '/admin/system/hardwares' }">Hardware</router-link>
          </li>
          <li v-if="checkMenu(adminSystem.language)">
            <router-link :to="{ path: '/admin/system/languages' }">Language</router-link>
          </li>
          <li v-if="checkMenu(adminSystem.templateCategory)">
            <router-link :to="{ path: '/admin/system/template_categories' }"
              >Template Category</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import permissonMixin from '@/mixins/permissionMixin'
import { adminSystem } from '@/constant/menu'

export default {
  name: 'admin-system',
  mixins: [permissonMixin],
  data() {
    return {
      user: {},
      adminSystem
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../Adminpage.scss';
.term-nowrap {
  p {
    width: 100vh;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.text-area-control {
  width: 100%;
  border: 1px solid #ced4da;
  outline: none;
  height: 500px;
}
</style>
