<template>
  <SfModal
    :title="'Add Google Sildes'"
    :width="768"
    :height="0"
    closeOnOverlay
    :show.sync="dataShow"
    @update:show="updateShow"
  >
    <div class="sf-modal-body mb-3">
      <div class="container">
        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">Title</label>
              </div>
              <input
                type="text"
                name="smart_asset[displayname]"
                id="smart_asset_displayname"
                class="sf-input-text"
                placeholder="Title"
                v-model.trim="dataInput.title"
                :class="{ 'input-error-form': submitted && $v.dataInput.title.$invalid }"
              />
            </div>
            <div
              class="invalid-error-text text-right"
              v-if="submitted && !$v.dataInput.title.required"
            >
              {{ $t('enter-name-asset') }}
            </div>
            <div
              class="invalid-error-text text-right"
              v-if="submitted && !$v.dataInput.title.minLength"
            >
              {{ $t('title-minLength-2') }}
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">URL</label>
              </div>
              <input
                type="text"
                name="property[url]"
                id="property_url"
                class="sf-input-text"
                placeholder="URL of Google Sildes"
                v-model="dataInput.url"
                :class="{ 'input-error-form': submitted && $v.dataInput.url.$invalid }"
                v-debounce:500ms="checkShared"
              />
            </div>
            <div class="invalid-error-text text-right" v-if="submitted && !$v.dataInput.url.url">
              URL must have 'https://' or 'http://'
            </div>
            <div
              class="invalid-error-text text-right"
              v-if="submitted && !$v.dataInput.url.required"
            >
              Please enter url of website
            </div>
            <div
              class="invalid-error-text text-right"
              v-if="submitted && !$v.dataInput.url.maxLength"
            >
              Use less than 250 characters
            </div>
            <div
              class="invalid-error-text text-right"
              v-if="submitted && !$v.dataInput.url.vadidationLinkGoogleSlide"
            >
              Please enter Google Slide Url
            </div>
            <div
              class="invalid-error-text text-right"
              v-if="submitted && $v.dataInput.url.vadidationLinkGoogleSlide && !urlPermission"
            >
              Please share your Google Slide
            </div>
          </div>
        </div>

        <!--       <div class="row mt-3">
        <div class="col-9">
          <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">Position (Pixels)</label>
              </div>
              <input
                type="number"
                name="property[position]"
                id="property_position"
                value="0"
                class="sf-input-text"
                placeholder="Position"
                v-model="dataInput.position"
              />
          </div>
        </div>
      </div> -->
        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">Tags</label>
              </div>
              <vue-tags-input
                class="sf-tag"
                v-model="tag"
                :tags="dataInput.tags"
                @tags-changed="(newTags) => (dataInput.tags = newTags)"
              />
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group label-top">
                <label class="primary-label">Orientation</label>
              </div>
              <div class="row orientation-type">
                <div class="col-6">
                  <label class="sf-radio"
                    >Landscape (Default)
                    <input
                      checked
                      id="landscape-radio"
                      name="smart_asset[orientation]"
                      type="radio"
                      value="landscape"
                      class="sf-radio"
                      v-model="dataInput.orientation"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="col-6">
                  <label class="sf-radio"
                    >Portrait
                    <input
                      class="sf-radio"
                      id="portrait-radio"
                      name="smart_asset[orientation]"
                      type="radio"
                      value="portrait"
                      v-model="dataInput.orientation"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="col-12 mt-3">
                  <div class="row orientation-img-wrap">
                    <div
                      for="landscape-radio"
                      :class="{ active: dataInput.orientation === 'landscape' }"
                      class="col-6 landscape orientation-img"
                    >
                      <img src="~@/assets/images/landscape-review.svg" />
                    </div>
                    <div
                      for="portrait-radio"
                      :class="{ active: dataInput.orientation === 'portrait' }"
                      class="col-6 orientation-img portrait"
                    >
                      <img src="~@/assets/images/portrait-review.svg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sf-modal-footer">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="sf-modal-action">
              <button class="sf-secondary" type="button" @click="updateShow">Cancel</button>
              <button type="button" class="sf-primary" @click="onClickSave">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SfModal>
</template>

<script>
import SfModal from '@/components/SfModal'
import VueTagsInput from '@johmun/vue-tags-input'
import { required, url, maxLength, minLength } from 'vuelidate/lib/validators'
import axios from 'axios'

const vadidationLinkGoogleSlide = (value) => value.indexOf('docs.google.com/presentation') >= 0 // && value.indexOf('/pub') >= 0
// const vadidationLinkGoogleSlide = (value) => value.indexOf('docs.google.com/presentation') >= 0
export default {
  components: {
    SfModal,
    VueTagsInput
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    closeOnOverlay: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dataShow: this.show,
      tag: '',
      dataInput: {
        title: '',
        tags: [],
        orientation: 'landscape',
        url: ''
      },
      submitted: false,
      urlPermission: true,
      checking: false
    }
  },
  validations: {
    dataInput: {
      title: {
        required,
        minLength: minLength(2)
      },
      url: {
        required,
        url,
        maxLength: maxLength(250),
        vadidationLinkGoogleSlide
      }
    }
  },
  watch: {
    show(value) {
      this.dataShow = value
    }
  },
  methods: {
    updateShow() {
      this.$emit('update:show', false)
    },
    onClickSave() {
      this.submitted = true
      if (this.$v.dataInput.$invalid || this.checking) {
        return
      }
      // check url when user shared url but don't close modal
      axios
        .get(this.dataInput.url)
        .then((response) => {
          this.urlPermission = true
          this.checking = false
          this.$emit('modal:save', { ...this.dataInput })
        })
        .catch((_) => {
          this.urlPermission = false
          this.checking = false
        })
    },
    resetForm() {
      this.dataInput = {
        title: '',
        position: 0,
        tags: [],
        orientation: 'landscape',
        url: '',
        run_script: false,
        is_cache: true,
        custom_script: ''
      }
    },
    checkShared() {
      this.checking = true
      axios
        .get(this.dataInput.url)
        .then((response) => {
          this.urlPermission = true
          this.checking = false
        })
        .catch((_) => {
          this.urlPermission = false
          this.checking = false
        })
    }
  },
  computed: {},
  created() {
    this.$bus.$on('reset-submitted', (value) => {
      this.submitted = value
      this.resetForm()
    })
  },
  beforeDestroy() {
    this.dataInput = {
      title: '',
      tags: [],
      orientation: 'landscape',
      url: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import './style-modal-smart-asset';
</style>
<style lang="scss">
.form-control {
  height: auto !important;
  .vue-tags-input {
    margin: -0.375rem -0.75rem;
    border-radius: 0.25rem;
    * {
      border: transparent;
    }
    border: transparent;
    .ti-tag {
      background-color: #e0edf5;
      border-radius: 3px;
      color: #2f5d92;
      float: left;
      font-weight: 600;
      height: 28px;
      line-height: 28px;
      margin: 0 8px 6px 0;
      padding: 0 1px 0 8px;
      white-space: nowrap;
    }
  }
}
.custom-textarea {
  border: 0 !important;
  max-height: 150px;
  width: 100%;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
}

.have-toggle {
  display: flex;
  justify-content: space-between;
}
</style>
