export default {
  data() {
    return {
      user: null
    }
  },
  created() {
    const user = this.$cookies.get('user')
    if (user) {
      this.user = user
    }
  },
  methods: {
    // if employee && not subadmin return true, otherwise check if menu is in user menu
    checkMenu(menu) {
      if (this.user) {
        if (this.user.user_type === 'employee' && !this.user.is_subadmin) {
          return true
        } else {
          for (const key in this.user.menu) {
            if (menu === key) {
              return this.user.menu[`${menu}`]
            }
          }
        }
      }
      return false
    },
    checkMenuAll(menu) {
      const allow = Object.entries(menu).some(([key, value]) => {
        return this.checkMenu(value)
      })
      return allow
    }
  }
}
