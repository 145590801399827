<script>
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import {
  FONT_SIZE_DATE_TIME_DEFAULT,
  SMART_ASSET_TYPE_DATE_TIME,
  HEIGHT_DATE_TIME_DEFAULT,
  SMART_ASSET_TYPE_WEATHER,
  WIDTH_24H_DATE_TIME_DEFAULT,
  WIDTH_12H_DATE_TIME_DEFAULT,
  FONT_SIZE_DAILY_FORECAST_WEATHER,
  FONT_SIZE_WEEKLY_FORECAST_WEATHER,
  WIDTH_DAILY_FORECAST_WEATHER,
  WIDTH_WEEKLY_FORECAST_WEATHER,
  HEIGHT_WEATHER_DEFAULT
} from '@/constant'
import moment from 'moment-timezone'
import textIcon from '@/assets/images/smart-assets/text-style.svg'
import rssIcon from '@/assets/images/smart-assets/rss-icon.svg'
import websiteIcon from '@/assets/images/smart-assets/network-browser.svg'
import instagramThumb from '@/assets/images/smart-assets/instagram-icon.svg'
import twitterThumb from '@/assets/images/smart-assets/twitter-icon.png'
import instagramIcon from '@/assets/images/smart-assets/social-instagram.svg'
import svgIcon from '@/assets/images/smart-assets/image-file-svg.svg'
import gifIcon from '@/assets/images/smart-assets/picture-stack-landscape.svg'
import qrIcon from '@/assets/images/smart-assets/qr-code-scan.svg'
import videoIcon from '@/assets/images/smart-assets/video-player.svg'
import paragraphIcon from '@/assets/images/smart-assets/paragraph-normal.svg'
import shapeIcon from '@/assets/images/smart-assets/shape-icon.svg'
import clockIcon from '@/assets/images/smart-assets/clock-icon.png'
import imgIcon from '@/assets/images/smart-assets/img-icon.svg'
import weatherIcon from '@/assets/images/smart-assets/weather-icon.png'
import countdownIcon from '@/assets/images/smart-assets/countdown-icon.svg'

export default {
  name: 'SmartAssetEditorMixins',
  data() {
    return {
      icon: {
        instagramThumb: instagramThumb,
        twitterThumb: twitterThumb,
        instagram: instagramIcon,
        image: imgIcon,
        text: textIcon,
        rss: rssIcon,
        svg: svgIcon,
        gif: gifIcon,
        website: websiteIcon,
        paragraph: paragraphIcon,
        qr: qrIcon,
        video: videoIcon,
        shape: shapeIcon,
        datetime: clockIcon,
        weather: weatherIcon,
        fontSizeSelected: FONT_SIZE_DAILY_FORECAST_WEATHER,
        widthSelected: WIDTH_DAILY_FORECAST_WEATHER,
        countdown: countdownIcon
      }
    }
  },

  methods: {
    createNavBlocks() {
      const blocks = [
        {
          name: 'Text',
          items: [
            {
              title: 'Title',
              type: 'title',
              eventClick: this.addTitleText,
              icon: this.icon.text
            },
            {
              title: 'Paragraph',
              type: 'paragraph',
              eventClick: this.addParagraphText,
              icon: this.icon.paragraph
            }
          ]
        },
        {
          name: 'Media',
          items: [
            {
              title: 'Image',
              type: 'image',
              eventClick: this.uploadImage,
              icon: this.icon.image
            },
            {
              title: 'SVG',
              type: 'svg',
              eventClick: this.uploadSvg,
              icon: this.icon.svg
            },
            {
              title: 'GIF',
              type: 'gif',
              eventClick: this.uploadGif,
              icon: this.icon.gif
            },
            {
              title: 'Video',
              type: 'video',
              eventClick: this.uploadVideo,
              icon: this.icon.video
            }
          ]
        },
        {
          name: 'Shape',
          items: [
            {
              title: 'Shape',
              type: 'shape',
              eventClick: this.addShape,
              icon: this.icon.shape
            }
          ]
        },
        {
          name: 'QR code',
          items: [
            {
              title: 'QR code',
              type: 'qrCode',
              eventClick: this.addQrCode,
              icon: this.icon.qr
            }
          ]
        },
        {
          name: 'Date Time',
          items: [
            {
              title: 'Date Time',
              type: this.dateTimeType,
              eventClick: this.addDateTime,
              icon: this.icon.datetime
            }
          ]
        },
        {
          name: 'Weather',
          items: [
            {
              title: 'Weather',
              type: this.weatherType,
              eventClick: this.addWeather,
              icon: this.icon.weather
            }
          ]
        },
        {
          name: 'Countdown',
          items: [
            {
              title: 'Countdown',
              type: this.countdown,
              eventClick: this.openAddCountdownModal,
              icon: this.icon.countdown
            }
          ]
        }
      ]
      if (this.nodeEnv !== 'production') {
        blocks.push(
          {
            name: 'Embed',
            items: [
              {
                title: 'Website',
                type: 'website',
                eventClick: this.addWebsite,
                icon: this.icon.website
              }
            ]
          },
          {
            name: 'Instagram',
            items: [
              {
                title: 'Caption',
                type: 'igCaption',
                eventClick: this.addIgCaption,
                icon: this.icon.paragraph
              },
              {
                title: 'Image',
                type: 'igImage',
                eventClick: this.addIgImage,
                icon: this.icon.image
              }
            ]
          },
          {
            name: 'RSS (Beta)',
            items: [
              {
                title: 'RSS Title',
                type: 'rssTitle',
                eventClick: this.addRssTitle,
                icon: this.icon.text
              },
              {
                title: 'RSS Body',
                type: 'rssDesc',
                eventClick: this.addRssDesc,
                icon: this.icon.paragraph
              }
            ]
          }
        )
      }
      return blocks
    },
    addDateTime() {
      this.fontSizeSelected = FONT_SIZE_DATE_TIME_DEFAULT
      this.widthSelected = this.widthDateTimeDefault
      const block = this.createDateTimeBlock('DateTime', SMART_ASSET_TYPE_DATE_TIME, '', 30, 50)
      block.h = HEIGHT_DATE_TIME_DEFAULT
      block.hour12 = 'false'
      const timezoneName = this.findTimezoneName()
      block.timezone = timezoneName ? `${moment.tz.guess()}|${timezoneName}` : 'Etc/UTC|UTC'
      this.pushBlock(block)
    },
    findTimezoneName() {
      const timezoneStateList = get(this.$store, 'state.smartasseteditor.listTimezone', [])
      const timezoneData = timezoneStateList.find((tz) => tz.value === moment.tz.guess())
      return timezoneData ? timezoneData.name : null
    },
    addWeather() {
      this.fontSizeSelected = FONT_SIZE_DAILY_FORECAST_WEATHER
      this.widthSelected = WIDTH_DAILY_FORECAST_WEATHER
      const block = this.createWeatherBlock('Weather', SMART_ASSET_TYPE_WEATHER, '', 30, 50)
      this.pushBlock(block)
    },
    pushBlock(block) {
      this.activeDefaultPropertiesTab(block.type)
      this.smartAsset.blocks.push(block)
      this.activeBlockIndex = this.smartAsset.blocks.length - 1
      this.activeAddBlock = false
      this.activeEditBlock = true
      this.pushToHistory()
    },
    createDateTimeBlock(name, type, text, x, y) {
      const block = {
        id: '',
        blockId: this.totalBlock,
        is_destroy: false,
        parent_id: '',
        aspectRatio: false,
        x: x,
        y: y,
        w: this.widthDateTimeDefault,
        type: type,
        text: text,
        name: name,
        zIndex: this.smartAsset.blocks.length + 1,
        style: {
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: this.smartAsset.backgroundColor || 'transparent',
          flexDirection: 'column'
        },
        textStyle: {
          textAlign: 'center',
          color:
            this.smartAsset.textStyle.color && this.smartAsset.textStyle.color !== 'undefined'
              ? this.smartAsset.textStyle.color
              : '#223144',
          fontSize: `${FONT_SIZE_DATE_TIME_DEFAULT}px`,
          fontFamily:
            this.smartAsset.textStyle.fontFamily &&
            this.smartAsset.textStyle.fontFamily !== 'undefined'
              ? this.smartAsset.textStyle.fontFamily
              : 'Roboto',
          fontWeight: '700',
          lineHeight: '100%',
          letterSpacing: '0',
          textShadow: '0px 0px 0px transparent',
          padding: '10px 10px 10px 10px'
        },
        textShadowValue: {
          h: 0,
          v: 0,
          blur: 0,
          color: '#223144'
        },
        borderRadiusValue: {
          topLeft: 0,
          topRight: 0,
          bottomLeft: 0,
          bottomRight: 0
        },
        paddingValue: {
          top: 10,
          left: 10,
          right: 10,
          bottom: 10
        },
        fontFamily: {
          id: 'Roboto',
          text: 'Roboto'
        }
      }
      block.ratio = block.w / block.h
      return block
    },
    createWeatherBlock(name, type, text, x, y) {
      const block = {
        id: '',
        blockId: this.totalBlock,
        is_destroy: false,
        parent_id: '',
        aspectRatio: false,
        x: x,
        y: y,
        w: WIDTH_DAILY_FORECAST_WEATHER,
        h: HEIGHT_WEATHER_DEFAULT,
        type: type,
        text: text,
        name: name,
        json_data: '',
        isWeeklyForecast: false,
        unit: 'fahrenheit',
        lat: '45.4215296',
        lon: '-75.69719309999999',
        location: 'Ottawa, ON, Canada',
        zIndex: this.smartAsset.blocks.length + 1,
        style: {
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: this.smartAsset.backgroundColor || 'transparent',
          flexDirection: 'column'
        },
        textStyle: {
          textAlign: 'center',
          color:
            this.smartAsset.textStyle.color && this.smartAsset.textStyle.color !== 'undefined'
              ? this.smartAsset.textStyle.color
              : '#223144',
          fontSize: `${FONT_SIZE_DAILY_FORECAST_WEATHER}px`,
          fontFamily:
            this.smartAsset.textStyle.fontFamily &&
            this.smartAsset.textStyle.fontFamily !== 'undefined'
              ? this.smartAsset.textStyle.fontFamily
              : 'Roboto',
          fontWeight: '700',
          lineHeight: '100%',
          letterSpacing: '0',
          textShadow: '0px 0px 0px transparent',
          padding: '10px 10px 10px 10px'
        },
        textShadowValue: {
          h: 0,
          v: 0,
          blur: 0,
          color: '#223144'
        },
        borderValue: {
          borderWidth: 0,
          color: '#223144'
        },
        borderRadiusValue: {
          topLeft: 0,
          topRight: 0,
          bottomLeft: 0,
          bottomRight: 0
        },
        fontFamily: {
          id: 'Roboto',
          text: 'Roboto'
        }
      }
      block.ratio = block.w / block.h
      return block
    },
    setLatAndLon(place) {
      if (!place || !place.formatted_address || !place.geometry || !place.geometry.location) return
      this.activeBlock.location = place.formatted_address
      this.activeBlock.lat = place.geometry.location.lat()
      this.activeBlock.lon = place.geometry.location.lng()
    },
    setWeatherToDefault() {
      if (this.activeBlock.isWeeklyForecast) {
        this.activeBlock.w = WIDTH_WEEKLY_FORECAST_WEATHER
        this.activeBlock.h = HEIGHT_WEATHER_DEFAULT
        this.activeBlock.textStyle.fontSize = `${FONT_SIZE_WEEKLY_FORECAST_WEATHER}px`
        this.fontSizeSelected = FONT_SIZE_WEEKLY_FORECAST_WEATHER
        this.widthSelected = WIDTH_WEEKLY_FORECAST_WEATHER
      } else {
        this.activeBlock.w = WIDTH_DAILY_FORECAST_WEATHER
        this.activeBlock.h = HEIGHT_WEATHER_DEFAULT
        this.activeBlock.textStyle.fontSize = `${FONT_SIZE_DAILY_FORECAST_WEATHER}px`
        this.fontSizeSelected = FONT_SIZE_DAILY_FORECAST_WEATHER
        this.widthSelected = WIDTH_DAILY_FORECAST_WEATHER
      }
    },
    calcFontSizeDateTime(w, h) {
      const activeBlock = this._.cloneDeep(this.activeBlock)
      const fontSize = parseInt(this.activeBlock.textStyle.fontSize, 10)
      const minHeight = (fontSize * HEIGHT_DATE_TIME_DEFAULT) / FONT_SIZE_DATE_TIME_DEFAULT
      const minWidth = (fontSize * this.widthDateTimeDefault) / FONT_SIZE_DATE_TIME_DEFAULT
      if (h < minHeight) {
        this.activeBlock.textStyle.fontSize = `${
          (FONT_SIZE_DATE_TIME_DEFAULT * h) / HEIGHT_DATE_TIME_DEFAULT
        }px`
      } else if (w < minWidth) {
        this.activeBlock.textStyle.fontSize = `${
          (FONT_SIZE_DATE_TIME_DEFAULT * w) / this.widthDateTimeDefault
        }px`
      } else if (w !== activeBlock.w || h !== activeBlock.h) {
        // scale font size
        this.activeBlock.textStyle.fontSize = `${
          (w * this.fontSizeSelected) / this.widthSelected
        }px`
      }
    },
    calcFontSizeWeather(w, h) {
      const activeBlock = cloneDeep(this.activeBlock)
      const fontSize = parseInt(activeBlock.textStyle.fontSize, 10)
      const minHeight = (fontSize * HEIGHT_WEATHER_DEFAULT) / this.fontSizeWeatherDefault
      const minWidth = (fontSize * this.widthWeatherDefault) / this.fontSizeWeatherDefault
      if (h < minHeight) {
        this.activeBlock.textStyle.fontSize = `${
          (this.fontSizeWeatherDefault * h) / HEIGHT_WEATHER_DEFAULT
        }px`
      } else if (w < minWidth) {
        this.activeBlock.textStyle.fontSize = `${
          (this.fontSizeWeatherDefault * w) / this.widthWeatherDefault
        }px`
      } else if (w !== activeBlock.w || h !== activeBlock.h) {
        // scale font size
        this.activeBlock.textStyle.fontSize = `${
          (w * this.fontSizeSelected) / this.widthSelected
        }px`
      }
    },
    changeForecastType(e) {
      this.setWeatherToDefault()
    },
    weatherBlockParams(paramName, formdata, value) {
      formdata.append(`${paramName}[content]`, value.text)
      formdata.append(`${paramName}[asset_file]`, '')

      formdata.append(
        `${paramName}[property_attributes][isWeeklyForecast][data_property]`,
        value.isWeeklyForecast
      )
      formdata.append(`${paramName}[property_attributes][lat][data_property]`, value.lat)
      formdata.append(`${paramName}[property_attributes][lon][data_property]`, value.lon)
      formdata.append(`${paramName}[property_attributes][location][data_property]`, value.location)
      formdata.append(`${paramName}[property_attributes][unit][data_property]`, value.unit)
      formdata.append(
        `${paramName}[property_attributes][json_data][data_property]`,
        value.json_data
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][data_property]`,
        value.blockType
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textShadowValue][blur]`,
        value.textShadowValue.blur
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textShadowValue][color]`,
        value.textShadowValue.color
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textShadowValue][h]`,
        value.textShadowValue.h
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textShadowValue][v]`,
        value.textShadowValue.v
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textStyle][color]`,
        value.textStyle.color
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textStyle][fontFamily]`,
        value.textStyle.fontFamily
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textStyle][fontSize]`,
        value.textStyle.fontSize
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textStyle][fontWeight]`,
        value.textStyle.fontWeight
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textStyle][letterSpacing]`,
        value.textStyle.letterSpacing
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textStyle][lineHeight]`,
        value.textStyle.lineHeight
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textStyle][padding]`,
        value.textStyle.padding
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textStyle][textAlign]`,
        value.textStyle.textAlign
      )
      formdata.append(
        `${paramName}[property_attributes][${value.blockType}][asset_attribute][textStyle][textShadow]`,
        value.textStyle.textShadow
      )
    },
    updateJsonData(index, json) {
      this.widthSelected = this.activeBlock.w
      this.fontSizeSelected = parseInt(this.activeBlock.textStyle.fontSize, 10)
      this.smartAsset.blocks[index].json_data = json
    }
  },
  computed: {
    widthDateTimeDefault() {
      if (!this.activeBlock || this.activeBlock.type !== SMART_ASSET_TYPE_DATE_TIME)
        return WIDTH_24H_DATE_TIME_DEFAULT
      return this.activeBlock.hour12 === 'true'
        ? WIDTH_12H_DATE_TIME_DEFAULT
        : WIDTH_24H_DATE_TIME_DEFAULT +
            parseInt(this.activeBlock.paddingValue.left) +
            parseInt(this.activeBlock.paddingValue.right)
    },
    widthWeatherDefault() {
      if (!this.activeBlock) return WIDTH_DAILY_FORECAST_WEATHER
      return this.activeBlock.isWeeklyForecast
        ? WIDTH_WEEKLY_FORECAST_WEATHER
        : WIDTH_DAILY_FORECAST_WEATHER
    },
    fontSizeWeatherDefault() {
      return get(this.activeBlock, 'isWeeklyForecast', false)
        ? FONT_SIZE_WEEKLY_FORECAST_WEATHER
        : FONT_SIZE_DAILY_FORECAST_WEATHER
    }
  }
}
</script>
