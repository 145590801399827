<template>
  <div id="library-page">
    <div class="top-header row mx-0" id="topheader">
      <div class="col-6 d-flex">
        <div class="row">
          <div class="col mr-3 mb-1">
            <SfInputSearch
              v-model="dataSearch"
              v-bind:placeholder="$t('search')"
              v-debounce:500ms="searchInputChange"
              :height="'40px'"
            />
          </div>
          <div class="col d-none d-sm-block mb-1">
            <multiselect
              style="width: 185px"
              v-model="filterAssets"
              :options="optionAssests"
              :searchable="false"
              :allow-empty="false"
              deselectLabel=""
              selectLabel=""
              selectedLabel=""
              label="name"
              track-by="name"
              class="sf-multiselect mr-1"
              @select="filterForType"
            >
            </multiselect>
          </div>
          <div class="col mb-1">
            <multiselect
              class="sf-multiselect"
              style="width: 150px"
              v-model="filterOrientation"
              :options="optionOrientations"
              :searchable="false"
              :allow-empty="false"
              deselectLabel=""
              selectLabel=""
              selectedLabel=""
              label="name"
              track-by="name"
              @select="changeOrientation"
            >
            </multiselect>
          </div>
          <div class="col mb-1">
            <button
              type="button"
              v-if="!selectAllButton && items.length > 0"
              @click="selectAllButton = !selectAllButton"
              class="sf-primary btn btn-block multiselect sf-multiselect select-btn"
            >
              <img class="data-image" :src="squareCheck" /> Select
            </button>
            <button
              type="button"
              v-if="selectAllButton && items.length > 0"
              @click="
                selectAllButton = !selectAllButton
                selectedItems = []
              "
              class="sf-primary btn btn-block multiselect sf-multiselect select-btn"
            >
              <img class="data-image" :src="squareCross" /> Unselect
            </button>
          </div>
        </div>
      </div>
      <div class="d-none d-sm-block col-6">
        <div class="group-button d-flex justify-content-end align-items-center">
          <button
            class="btn btn-show switcher_grid d-none d-sm-block"
            id="btn-gridview"
            :class="{ active: viewType == 'grid-view' }"
            @click="viewType = 'grid-view'"
          ></button>
          <button
            class="btn btn-show switcher_list d-none d-sm-block"
            id="btn-listview"
            :class="{ active: viewType == 'list-view' }"
            @click="viewType = 'list-view'"
          ></button>
          <div class="btn-group btn-group-header d-none d-sm-block" role="group">
            <button class="sf-sort limit-text" type="button" @click="sortByName">
              {{ $t('name') }}
            </button>
            <button class="sf-sort limit-text" type="button" @click="sortBySize">
              {{ $t('size') }}
            </button>
            <button class="sf-sort" type="button" @click="sortByDuration">
              {{ $t('duration') }}
            </button>
            <button class="sf-sort limit-text" type="button" @click="sortByDate">
              {{ $t('date') }}
            </button>
          </div>
          <button class="sf-primary-add ml-3" type="button" @click="shownNewAsset">
            {{ $t('newasset') }}
          </button>
        </div>
      </div>
    </div>
    <div class="content-page-library">
      <div class="content">
        <div class="top-block row">
          <div class="sf-breadcrumbs d-none d-sm-block col-6" id="breadcrumb">
            <ul>
              <template v-if="breadcrumb.length">
                <li v-for="item in breadcrumb" :key="item.url" @click="clickBreadcrumb(item)">
                  <a class="sf-link"
                    ><span>{{ item.name }}</span></a
                  >
                </li>
              </template>
              <template v-else>
                <li
                  @click="
                    clickBreadcrumb({
                      url: '/apisite/v1/library',
                      type: 'main'
                    })
                  "
                >
                  <a class="sf-link"
                    ><span>{{ $t('library') }}</span></a
                  >
                </li>
              </template>
            </ul>
          </div>
          <div
            v-if="!isEmpty && !loading"
            class="col-12 col-sm-6 d-flex justify-content-end align-items-center"
          >
            <b-pagination
              v-model="currentPage"
              :pills="true"
              :total-rows="total"
              per-page="1"
              limit="6"
              class="sf-b-pagination"
              @change="changePage"
            >
            </b-pagination>
            <SfOptionLimitPage @changeLimitPage="changeLimitPage" :limitPage.sync="limitPage" />
          </div>
        </div>
        <div class="slim-div">
          <div v-if="isEmpty && !loading">
            <p class="d-flex justify-content-center">{{ $t('no-data-available-in-table') }}</p>
          </div>
          <itemFolder
            :viewType="viewType"
            :nodes="items"
            :loading.sync="loading"
            :limitPage="limitPage"
            :selectAllButton="selectAllButton"
            @edit:folder="editfolder"
            @edit:shareFolder="shareFolder"
            @edit:video="editvideo"
            @edit:image="editimage"
            @edit:pdf="editpdf"
            @edit:canva="editcanva"
            @edit:instagram="editinstagram"
            @edit:custom_asset="editImageCustom"
            @edit:web="editwebsite"
            @edit:youtube="edityoutube"
            @edit:google_sheet="editGoogleSheet"
            @edit:google_slide="editGoogleSlide"
            @deleteItem="deleteItem"
            @deleteItemBulk="deleteItemBulk"
            @openFolder="openFolder"
          />
        </div>
      </div>

      <div class="d-none d-sm-block" id="tree-view">
        <div class="button-create">
          <p>{{ $t('folder') }}</p>
          <button class="btn btn-default" id="create-new" @click="addFolder">
            <icon-plus />
          </button>
        </div>
        <div class="folder_default" @click="clickFolderDefault">
          <img src="~@/assets/images/home-folder-library.svg" />
          <div class="wrap_p">
            <p>{{ $t('library') }}</p>
          </div>
        </div>
        <vueTree
          :loading.sync="loading"
          :nodesTree="treeFolder"
          @selectedFolder="onSelect"
          @dataChanged="treeFolderChange"
          @drop="onDropItem"
          @editNameItem="editNameFolder"
          @deleteItem="deleteItemInFolder"
          @openFolder="openFolder"
        />
      </div>
    </div>
    <ModalNewAsset
      :show.sync="showNewAsset"
      @update:show="updateShow('showNewAsset', $event)"
      @click:uploadAsset="clickNewAssetUpload"
      @click:startAsset="clickNewAssetStart"
    />
    <ModalNewSmartAsset
      :show.sync="showNewSmartAsset"
      @update:show="updateShow('showNewSmartAsset')"
      @continue:instagram="continueInstagram"
      @continue:smartAsset="continueSmartAsset"
      @continue:pdf="continuePdf"
      @continue:website="continueWebsite"
      @continue:youtube="continueYoutube"
      @continue:googleSheets="continueGoogleSheet"
      @continue:googleSlides="continueGoogleSlide"
      @continue:canva="continueCanva"
    />

    <ModalInstagramEditor :show.sync="showInstagramEditor" />
    <ModalSmartAssetEditor
      :show.sync="showSmartAssetEditor"
      :editData.sync="editImageCustomDataModal"
      @create:success="updateAssetAfterCreating"
    />
    <ModalAddInstagram
      :show.sync="showAddInstagram"
      @update:show="updateShow('showAddInstagram')"
      @modal:next="ModalAddInstagramNext"
    />
    <ModalAddSmartAsset
      :show.sync="showAddSmartAsset"
      @update:show="updateShow('showAddSmartAsset')"
      @modal:next="ModalAddSmartAssetNext"
    />
    <ModalAddPdf
      :show.sync="showAddPdf"
      @update:show="updateShow('showAddPdf')"
      @modal:save="ModalAddPdfSave"
    />
    <ModalAddWebsite
      :show.sync="showAddWebsite"
      @update:show="updateShow('showAddWebsite')"
      @modal:save="ModalAddWebsiteSave"
    />
    <ModalAddYoutube
      :show.sync="showAddYoutube"
      @update:show="updateShow('showAddYoutube')"
      @modal:save="ModalAddYoutubeSave"
    />
    <ModalUppy
      :show.sync="showUppy"
      :folderID.sync="currentFolderActive"
      :filterOrientation.sync="filterOrientation.value"
      @update:show="updateShow('showUppy')"
      @upload:success="updateAssetAfterCreating"
    />
    <ModalEditFolder
      :show.sync="showEditFolder"
      :data.sync="dataModal"
      @update:show="updateShow('showEditFolder')"
      @modal:save="ModalEditFolderSave"
    />
    <ModalEditInstagram
      :show.sync="showEditInstagram"
      :data.sync="dataModal"
      @update:show="updateShow('showEditInstagram')"
      @modal:next="ModalEditInstagramNext"
    />
    <ModalEditImageCustom
      :show.sync="showEditImageCustom"
      :data.sync="dataModal"
      @update:show="updateShow('showEditImageCustom')"
      @modal:next="ModalEditImageCustomNext"
    />
    <ModalEditPdf
      :show.sync="showEditPdf"
      :data.sync="dataModal"
      @update:show="updateShow('showEditPdf')"
      @modal:save="confirmEditPDF"
    />
    <ModalEditPiture
      :show.sync="showEditPicture"
      :data.sync="dataModal"
      @update:show="updateShow('showEditPicture')"
      @modal:save="saveimage"
    />
    <ModalEditVideo
      :show.sync="showEditVideo"
      :data.sync="dataModal"
      @update:show="updateShow('showEditVideo')"
      @modal:save="savevideo"
    />
    <ModalEditWebsite
      :show.sync="showEditWebsite"
      :data.sync="dataModal"
      @update:show="updateShow('showEditWebsite')"
      @modal:save="savewebsite"
    />
    <ModalEditYoutube
      :show.sync="showEditYoutube"
      :data.sync="dataModal"
      @update:show="updateShow('showEditYoutube')"
      @modal:save="saveYoutube"
    />
    <ModalDeleteItem
      :show.sync="showDeleteItem"
      :data.sync="dataModal"
      @update:show="updateShow('showDeleteItem')"
      @modal:delete="confirmDeleteItem"
    />

    <ModalDeleteItemBulk
      :show.sync="showDeleteItemBulk"
      :data.sync="dataModal"
      @update:show="updateShow('showDeleteItemBulk')"
      @modal:delete="confirmDeleteItemBulk"
    />

    <ModalDeleteItemInFolder
      :show.sync="showDeleteItemInFolder"
      :data.sync="dataModal"
      @update:show="updateShow('showDeleteItemInFolder')"
      @modal:delete="confirmDeleteItemInFolder"
    />
    <ModalShareFolder
      :show.sync="showShareFolder"
      :data.sync="dataModal"
      @update:show="updateShow('showShareFolder')"
      @modal:save="ModalEditFolderSave"
    />
    <ModalAddGoogleSheet
      :show.sync="showAddGoogleSheet"
      @update:show="updateShow('showAddGoogleSheet')"
      @modal:save="ModalAddGoogleSheetSave"
    />
    <ModalAddGoogleSlide
      :show.sync="showAddGoogleSlide"
      @update:show="updateShow('showAddGoogleSlide')"
      @modal:save="ModalAddGoogleSlideSave"
    />
    <ModalAddCanvaSlide
      :show.sync="showCanvaSlide"
      @update:show="updateShow('showCanvaSlide')"
      @modal:save="ModalAddCanvaSlideSave"
    />
    <ModalEditCanvaSlide
      :show.sync="showEditCanva"
      :data.sync="dataModal"
      @update:show="updateShow('showEditCanva')"
      @modal:save="saveCanva"
    />

    <ModalEditGoogleSheet
      :show.sync="showEditGoogleSheet"
      :data.sync="dataModal"
      @update:show="updateShow('showEditGoogleSheet')"
      @modal:save="saveGoogleSheet"
    />
    <ModalEditGoogleSlide
      :show.sync="showEditGoogleSlide"
      :data.sync="dataModal"
      @update:show="updateShow('showEditGoogleSlide')"
      @modal:save="saveGoogleSlide"
    />
    <SfWarningModal
      :title="$t('modal.title.warning')"
      closeOnOverlay
      :show.sync="isDeleteFolderBySomeOne"
    >
      <div class="content-delete">
        <p class="cantact-number">{{ $t('someone-else-delete-folder') }}</p>
      </div>
      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isDeleteFolderBySomeOne = false">
          {{ $t('close') }}
        </button>
      </div>
    </SfWarningModal>

    <loading :active.sync="isLoading" backgroundColor="rgba(0,0,0,0.6)" color="#126CDB"></loading>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import Multiselect from 'vue-multiselect'
import vueTree from '@/components/VueTree'
import ItemFolder from '@/components/itemFolder/Library'
import prepareQueryParamsMixin from '@/mixins/prepareQueryParamsMixin'
import prepareFetchParamsMixin from '@/mixins/prepareFetchParamsMixin'
import typeFile from '@/constant/typeFile'
import iconPlus from '@/assets/images/plus.svg?inline'
import ModalNewAsset from '@/components/library/ModalNewAsset'
import ModalNewSmartAsset from '@/components/library/ModalNewSmartAsset'
import ModalAddInstagram from '@/components/library/ModalAddInstagram'
import ModalAddSmartAsset from '@/components/library/ModalAddSmartAsset'
import ModalAddPdf from '@/components/library/ModalAddPdf'
import ModalAddWebsite from '@/components/library/ModalAddWebsite'
import ModalAddYoutube from '@/components/library/ModalAddYoutube'
import ModalUppy from '@/components/library/ModalUppy'
import ModalEditFolder from '@/components/library/ModalEditFolder'
import ModalEditInstagram from '@/components/library/ModalEditInstagram'
import ModalEditImageCustom from '@/components/library/ModalEditImageCustom'
import ModalEditPdf from '@/components/library/ModalEditPdf'
import ModalEditPiture from '@/components/library/ModalEditPicture'
import ModalEditVideo from '@/components/library/ModalEditVideo'
import ModalEditWebsite from '@/components/library/ModalEditWebsite'
import ModalEditCanvaSlide from '@/components/library/ModalEditCanvaSlide'
import ModalEditYoutube from '@/components/library/ModalEditYoutube'
import ModalDeleteItem from '@/components/library/ModalDeleteItem'
import ModalDeleteItemInFolder from '@/components/library/ModalDeleteItemInFolder'
import ModalShareFolder from '@/components/library/ModalShareFolder'
import ModalInstagramEditor from '@/components/instagramEditor/ModalInstagramEditor'
import ModalSmartAssetEditor from '@/components/smartAssetEditor/ModalSmartAssetEditor'
import SmartAssets from '@/services/smart-assets.service'
import Folder from '@/services/folder.service'
import SfInputSearch from '@/components/SfInputSearch.vue'
import ModalAddGoogleSheet from '@/components/library/ModalAddGoogleSheet'
import ModalEditGoogleSheet from '@/components/library/ModalEditGoogleSheet'
import ModalAddGoogleSlide from '@/components/library/ModalAddGoogleSlide'
import ModalAddCanvaSlide from '@/components/library/ModalAddCanvaSlide'
import ModalEditGoogleSlide from '@/components/library/ModalEditGoogleSlide'
import SfOptionLimitPage from '@/components/SfOptionLimitPage.vue'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import ModalDeleteItemBulk from '@/components/library/ModalDeleteItemBulk.vue'
import SfWarningModal from '@/components/SfWarningModal.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import squareCheck from '@/assets/square-check.svg'
import squareCross from '@/assets/square-cross.svg'

export default {
  name: 'LibraryPage',
  mixins: [prepareQueryParamsMixin, prepareFetchParamsMixin],
  components: {
    Multiselect,
    iconPlus,
    vueTree,
    ItemFolder,
    ModalNewAsset,
    ModalNewSmartAsset,
    ModalAddInstagram,
    ModalAddSmartAsset,
    ModalAddPdf,
    ModalAddWebsite,
    ModalAddYoutube,
    ModalUppy,
    ModalEditFolder,
    ModalEditInstagram,
    ModalEditImageCustom,
    ModalEditPdf,
    ModalEditPiture,
    ModalEditVideo,
    ModalEditWebsite,
    ModalEditCanvaSlide,
    ModalEditYoutube,
    ModalDeleteItem,
    ModalDeleteItemInFolder,
    ModalInstagramEditor,
    ModalSmartAssetEditor,
    ModalShareFolder,
    SfInputSearch,
    ModalAddGoogleSheet,
    ModalAddGoogleSlide,
    ModalAddCanvaSlide,
    ModalEditGoogleSheet,
    ModalEditGoogleSlide,
    SfOptionLimitPage,
    ModalDeleteItemBulk,
    Loading,
    SfWarningModal
  },
  data() {
    return {
      isLoading: false,
      typeFile,
      dataSearch: '',
      viewType: 'grid-view',
      result: '',
      typeShowSmartAsset: 'main',
      folderData: {
        id: ''
      },
      currentPage: 1,
      showNewAsset: false,
      showNewSmartAsset: false,
      showCanvaSlide: false,
      showEditCanva: false,
      showAddInstagram: false,
      showAddSmartAsset: false,
      showAddPdf: false,
      showAddWebsite: false,
      showAddYoutube: false,
      showUppy: false,
      showEditFolder: false,
      showEditInstagram: false,
      showEditImageCustom: false,
      showEditPdf: false,
      showEditPicture: false,
      showEditVideo: false,
      showEditWebsite: false,
      showEditYoutube: false,
      showDeleteItem: false,
      showDeleteItemBulk: false,
      showDeleteItemInFolder: false,
      showInstagramEditor: false,
      showSmartAssetEditor: false,
      showShareFolder: false,
      dataModal: {},
      editImageCustomDataModal: {},
      currentFolderID: null,
      showAddGoogleSheet: false,
      showEditGoogleSheet: false,
      showAddGoogleSlide: false,
      showEditGoogleSlide: false,
      squareCheck,
      squareCross,
      filterAssets: {
        name: `${this.$t('all-assets')} (0)`,
        value: ''
      },
      filterOrientation: {
        name: this.$t('both'),
        value: ''
      },
      limitPage: {
        name: `25 / ${this.$t('page')}`,
        value: '25'
      },
      user: '',
      currentOrganization: '',
      selectAllButton: false,
      clickSortNumber: 0,
      isDeleteFolderBySomeOne: false,
      isUpdatedFromSocket: false
    }
  },

  watch: {
    statistical: {
      deep: true,
      handler: function (val, oldVal) {
        this.translateMultiselect()
      }
    },
    '$i18n.locale'() {
      this.translateMultiselect()
    },
    currentOrganization: {
      handler: function (val) {
        this.setOrganizationId(val)
      }
    }
  },

  methods: {
    ...mapActions('library', {
      getListSmartAsset: 'getListSmartAsset',
      getListTreeFolder: 'getListFolder',
      getTreeFolderChildren: 'getTreeFolderChildById',
      getListSmartAssetInFolder: 'getListSmartAssetInFolder',
      moveContentFolderToItems: 'moveContentFolderToItems'
    }),
    ...mapMutations('library', {
      setItem: 'SET_ITEMS',
      setPage: 'SET_PAGINATION_PAGE',
      setTreeFolder: 'SET_TREE_FOLDER_CHANGED',
      addNewFolderItem: 'SET_NEW_FOLDER_ITEMS',
      setItemsFiter: 'SET_ITEMS_DROP_BY_ID',
      updateItem: 'SET_UPDATE_ITEM',
      updateItemWithFolder: 'SET_UPDATE_ITEM_WITH_FOLDER',
      updateFolder: 'SET_UPDATE_FOLDER',
      addNewFolderTreeFolder: 'SET_NEW_FOLDER_TREE_FOLDER',
      addNewItemTreeFolder: 'SET_NEW_ITEM_TREE_FOLDER',
      removeFolderItems: 'REMOVE_FOLDER_ITEMS',
      removeItemItems: 'REMOVE_ITEM_ITEMS',
      removeItemFolder: 'REMOVE_ITEM_FOLDER',
      setOpenFolderHasChildren: 'SET_OPEN_FOLDER_HAS_CHILDREN',
      sortNameFolder: 'SORT_NAME_FOLDER',
      createFromSocket: 'CREATE_FROM_SOCKET',
      deleteFromSocket: 'DELETE_FROM_SOCKET',
      updateFromSocket: 'UPDATE_FROM_SOCKET',
      dragFromSocket: 'DRAG_FROM_SOCKET',
      updateCountAssetStatistical: 'UPDATE_COUNT_ASSET_STATISTICAL',
      setDefaultBreadcrumb: 'SET_DEFAULT_BREADCRUMB',
      setNewSmartAssetDataModal: 'SET_NEW_SMART_ASSET_DATA_MODAL',
      setTreeFolderById: 'SET_TREE_FOLDER_BY_ID',
      setOrganizationId: 'SET_ORGANIZATION_ID'
    }),
    changeOrientation(value) {
      this.filterOrientation = cloneDeep(value)
      this.getListSmartAssetByFilter()
    },
    filterForType(value) {
      this.filterAssets = value
      this.getListSmartAssetByFilter()
    },
    trackingResponsive(id) {
      const item = document.getElementById(id)
      document.documentElement.style.setProperty(`--${id}-height`, '0px')
      this.observer = new MutationObserver(() => {
        this.setDivHeight(id)
      })
      this.observer.observe(item, {
        childList: true,
        subtree: true,
        characterData: true
      })
    },
    setDivHeight(id) {
      const height = document.getElementById(id).offsetHeight
      document.documentElement.style.setProperty(`--${id}-height`, `${height}px`)
    },
    onDropItem(data) {
      this.oldFolderID = get(data, 'dropNode[0].data.parent_id', null)
        ? get(data, 'dropNode[0].data.parent_id', null)
        : get(data, 'dropNode[0].data.folder.parent_id', null)
      this.currentFolderID =
        get(data, 'cursorPosition.node.data.file_type', null) !== 'folder'
          ? get(data, 'cursorPosition.node.data.parent_id', null)
          : get(data, 'cursorPosition.node.data.id', null)
      if (get(data, 'dropNode[0].data.folder.parent_id', null) === this.currentFolderID) return
      if (get(data, 'dropNode[0].data.shared_folder', null)) {
        this.$toast.error('This folder belongs to your parent organization.')
        return
      }

      if (data.dropNode[0].data.id !== data.cursorPosition.node.data.id) {
        if (data.dropNode[0].data.hasOwnProperty('newNode')) {
          let parentId = -1
          if (
            data.cursorPosition.placement === 'before' ||
            data.cursorPosition.placement === 'after'
          ) {
            const nodeSource = data.cursorPosition.node
            const tempPathNodeSource = [...nodeSource.path]
            tempPathNodeSource.pop()
            const parentNode = window.slVueTree.getNode(tempPathNodeSource)
            parentId = parentNode.data.id
          } else if (data.cursorPosition.placement === 'inside') {
            parentId = data.cursorPosition.node.data.id
          }
          const dataCreated = { ...data.dropNode[0].data }
          Folder.createFolder({
            name: dataCreated.name,
            folder_type: 'library',
            parent_id: parentId,
            source_id: dataCreated.source_id,
            source_type: 'SmartAsset'
          })
            .then((res) => {
              this.handleUpdateFromSocket(res.data)
              this.setOpenFolderHasChildren({ parent: parentId, type: 'folder' })
              const node = window.slVueTree.getNodeById(data.dropNode[0].data.source_id)
              window.slVueTree.updateNode(node.path, {
                data: { ...node.data, ...res.data }
              })
              if (this.currentFolderID === res.data.parent_id) {
                this.updateCountAsset(res.data.file_type)
              } else {
                this.updateCountAsset(res.data.file_type, 'delete')
              }
            })
            .catch((err) => {
              this.$toast.error(
                err.message ? err.message : 'Something went wrong. Please try again.'
              )
            })
        } else if (
          data.cursorPosition.placement === 'before' ||
          data.cursorPosition.placement === 'after'
        ) {
          const nodeCurrent = data.dropNode[0]
          const nodeSource = data.cursorPosition.node
          const tempPathNodeCurrent = [...nodeCurrent.path]
          const tempPathNodeSource = [...nodeSource.path]
          tempPathNodeCurrent.pop()
          tempPathNodeSource.pop()

          if (JSON.stringify(tempPathNodeCurrent) !== JSON.stringify(tempPathNodeSource)) {
            const parentNode = !isEmpty(tempPathNodeSource)
              ? window.slVueTree.getNode(tempPathNodeSource)
              : null
            const node = {
              name: data.dropNode[0].data.name,
              folder_type: 'library',
              parent_id: parentNode ? parentNode.data.id : null
            }
            const dropNodeFolderId = get(nodeCurrent, 'data.folder.id', nodeCurrent.data.id)
            // call api
            Folder.editFolderByID(node, dropNodeFolderId)
              .then((res) => {
                this.handleUpdateFromSocket(res.data)
                if (
                  data.cursorPosition.placement !== 'before' ||
                  get(data, 'cursorPosition.node.data.parent_id', null)
                )
                  this.getListSmartAssetInFolder({
                    id: this.currentFolderID ? this.currentFolderID : data.dropNode[0].data.id,
                    page: 1,
                    limit: this.limitPage.value
                  })
                if (data.cursorPosition.placement === 'before' && !this.currentFolderActive)
                  this.getListSmartAssetByFilter()
              })
              .catch((err) => {
                this.$toast.error(
                  err.message ? err.message : 'Something went wrong. Please try again.'
                )
              })

            // set has children
            const nodeParentOld = window.slVueTree.getNode(tempPathNodeCurrent)
            if (nodeParentOld) {
              window.slVueTree.updateNode(nodeParentOld.path, {
                data: {
                  ...nodeParentOld.data,
                  has_children: !!nodeParentOld.children.length
                }
              })
            }
          } else return
        } else if (
          data.cursorPosition.node.data.folder_type !== 'sub_organization' &&
          data.cursorPosition.placement === 'inside'
        ) {
          const node = {
            name: data.dropNode[0].data.name,
            folder_type: 'library',
            parent_id: data.cursorPosition.node.data.id
          }
          const dropNodeFolderId = get(data, 'dropNode[0].data.folder.id', data.dropNode[0].data.id)
          Folder.editFolderByID(node, dropNodeFolderId)
            .then((res) => {
              this.handleUpdateFromSocket(res.data)
              if (this.currentFolderID === res.data.parent_id) {
                this.updateCountAsset(data.dropNode[0].data.file_type)
              } else {
                this.updateCountAsset(data.dropNode[0].data.file_type, 'delete')
              }
              this.getListSmartAssetInFolder({
                id: this.currentFolderID ? this.currentFolderID : data.dropNode[0].data.id,
                page: 1,
                limit: this.limitPage.value
              })
              this.getTreeFolderChildren(this.currentFolderID)
            })
            .catch((err) => {
              this.$toast.error(
                err.message ? err.message : 'Something went wrong. Please try again.'
              )
            })
        }

        this.setItemsFiter(data.dropNode[0].data.source_id)
      }
    },
    openFolder(node) {
      const data = node.data ? node.data : node
      window.slVueTree.unSelect()
      const oldFolderID = this.currentFolderID
      this.currentFolderID = data.id
      this.typeShowSmartAsset = 'folder'
      if (!data) {
        // open folder in list items
        this.getListAsset(1, this.limitPage.value)
        this.getTreeFolderChildren(this.currentFolderID)
        this.setOpenFolderHasChildren({ parent: this.currentFolderID, type: 'item' })
      }
      if (data && oldFolderID !== this.currentFolderID) {
        // open folder in tree folder
        this.getListAsset(1, this.limitPage.value)
        this.getTreeFolderChildren(this.currentFolderID)
      }
      if (data.folder_type === 'organizations') {
        this.currentOrganization = data.source_id
        this.removeSocket()
        this.subscribeChannel()
      } else {
        if (this.currentOrganization !== data.organization_id) {
          this.currentOrganization = data.organization_id
          this.removeSocket()
          this.subscribeChannel()
        }
      }
    },

    ModalEditFolderSave(data) {
      this.showEditFolder = false
      this.showShareFolder = false
      const self = this
      this.isLoading = true
      Folder.editFolderByID({ folder: data }, data.id)
        .then((res) => {
          this.handleUpdateFromSocket(res.data)
          self.dataModal = res.data
          self.typeShowSmartAsset = 'main'
          const count = res.data.shared_organizations.length
          let message = 'Folder updated'
          if (count > 0) {
            message =
              count > 1
                ? 'This folder is now shared with ' + count + ' organizations'
                : 'This folder is now shared with ' + count + ' organization'
          }
          this.$toast.open({
            message: message,
            type: 'success'
          })
        })
        .catch((error) => {
          this.$toast.open({
            message: error.message,
            type: 'error'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    editNameFolder(node, name) {
      const data = cloneDeep(node.data)
      delete data.folder
      Folder.editFolderByID({ data, name }, node.data.id)
        .then((res) => {
          this.handleUpdateFromSocket(res.data)
        })
        .catch((err) => {
          // will revert name of the node if error occurs
          window.slVueTree.updateNode(node.path, {
            data: {
              ...node.data,
              isEdit: false
            }
          })
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    clickFolderDefault() {
      this.setDefaultBreadcrumb()
      this.typeShowSmartAsset = 'main'
      this.currentFolderID = null
      window.slVueTree.unSelect()
      this.getListAsset(1, this.limitPage.value)
      if (this.currentOrganization !== this.user.organization_id) {
        this.currentOrganization = this.user.organization_id
        this.removeSocket()
        this.subscribeChannel()
      }
    },
    onSelect(node) {
      const data = node.data
      window.slVueTree.unSelect()
      if (data.file_type === 'folder' && data.id !== this.currentFolderID) {
        this.resetFilter()
        this.getListSmartAssetInFolder({
          id: data.id,
          page: 1,
          limit: this.limitPage.value
        })
        this.currentFolderID = data.id
        this.typeShowSmartAsset = 'folder'
      }

      if (data.folder_type === 'organizations') {
        this.currentOrganization = data.source_id
      }

      if (this.currentOrganization !== data.organization_id) {
        this.currentOrganization = data.organization_id
        this.removeSocket()
        this.subscribeChannel()
      }
      if (!data.isGetData && data.has_children) {
        this.getTreeFolderChildren(data.id)
      }
    },
    clickBreadcrumb(item) {
      if (item.name === 'Library') {
        this.setDefaultBreadcrumb()
      }
      if (item.type === 'main') {
        this.typeShowSmartAsset = 'main'
        this.currentFolderID = null
        if (this.currentOrganization !== this.user.organization_id) {
          this.currentOrganization = this.user.organization_id
          this.removeSocket()
          this.subscribeChannel()
        }
        window.slVueTree.unSelect()
      } else if (item.type === 'folder') {
        this.currentFolderID = item.id
        const nodeDrag = window.slVueTree.getNodeById(parseInt(item.id))
        window.slVueTree.select([...nodeDrag.path])
        this.typeShowSmartAsset = 'folder'
      }
      this.getListSmartAssetByFilter()
    },
    treeFolderChange(treeFolder) {
      this.setTreeFolder(treeFolder)
    },
    saveSmartAsset(data) {
      this.isLoading = true
      Object.keys(data.properties).forEach((key) => {
        data.properties[key] = { data_property: data.properties[key] }
      })
      const smart_asset = {
        displayname: data.displayname,
        orientation: data.orientation,
        smart_asset_type: data.smart_asset_type,
        tag_list: data.tags,
        organization_id: this.currentOrganization
      }
      const property = { property: data.properties }
      const param = { ...smart_asset, ...property }
      const self = this
      SmartAssets.editSmartAssetsByID(param, data.id)
        .then((res) => {
          if (res.status === 200) {
            this.updateItem({
              id: res.data.id,
              name: res.data.displayname,
              orientation: res.data.orientation,
              updatedAt: res.data.updated_at
            })
            this.updateFolder({ id: res.data.folder.id, name: res.data.displayname })
            if (self.typeShowSmartAsset === 'main') {
              self.typeShowSmartAsset = 'main'
            }
            self.$toast.open({
              message: res.message || 'Asset updated',
              type: 'success'
            })
          }
        })
        .catch((error) => {
          this.$toast.open({
            message: error.message || 'Something went wrong. Please try again',
            type: 'error'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    addFolder() {
      const parentId = this.currentFolderActive || ''
      Folder.createFolder({
        name: 'New Folder',
        folder_type: 'library',
        parent_id: parentId,
        source_type: null
      })
        .then((res) => {
          const {
            created_at,
            file_type,
            folder_type,
            id,
            name,
            organization_id,
            parent_id,
            source_id,
            updated_at
          } = res.data
          const data = {
            can_drop: true,
            created_at,
            file_type,
            folder_type,
            has_children: false,
            id,
            name,
            organization_id,
            parent_id,
            source_id,
            type: 'folder',
            updated_at
          }
          this.updateAssetAfterCreating(data)
          this.$toast.open({
            message: res.message || 'New folder created',
            type: 'success'
          })
        })
        .catch((error) => {
          this.$toast.open({
            message: error.message,
            type: 'error'
          })
        })
    },
    deleteItem(data) {
      this.showDeleteItem = true
      this.dataModal = data
    },
    deleteItemBulk(data) {
      this.showDeleteItemBulk = true
      this.dataModal = { dataInput: data }
    },
    deleteItemInFolder(node) {
      this.showDeleteItemInFolder = true
      this.dataModal = node
      if (!node.data.isGetData && node.data.has_children) {
        this.getTreeFolderChildren(node.data.id)
      }
    },
    confirmDeleteItemInFolder(node) {
      this.isLoading = true
      if (this.currentFolderID === '' && node.children.length > 0) {
        this.moveContentFolderToItems({
          id: node.data.id,
          page: 1,
          limit: this.limitPage.value
        })
      }

      if (this.currentOrganization !== node.data.organization_id) {
        this.currentOrganization = node.data.organization_id
        this.removeSocket()
        this.subscribeChannel()
      }
      if (node.data.root_id && !node.data.parent_id) {
        this.currentFolderID = node.data.root_id
      } else {
        this.currentFolderID = node.data.parent_id
          ? node.data.parent_id
          : node.data.file_type === 'folder'
          ? node.data.id
          : node.data.folder.parent_id
      }
      Folder.deleteFolderByID(node.data.id)
        .then((res) => {
          this.showDeleteItemInFolder = false
          // move active to parent and load content of parent
          const arr = node.path
          if (arr.length > 1) {
            arr.pop()
            const nodeParent = window.slVueTree.getNode(arr)
            if (nodeParent && node.data.file_type === 'folder' && !this.isDefaultBreadcrumb) {
              this.getListSmartAssetInFolder({
                id: nodeParent.data.id,
                page: 1,
                limit: this.limitPage.value
              })
            } else if (this.isDefaultBreadcrumb && node.data.file_type === 'folder')
              this.clickFolderDefault()
          } else {
            this.clickFolderDefault()
          }
          this.handleDeleteById(res.data, this.user.id)
        })
        .catch((error) => {
          this.showDeleteItemInFolder = false
          this.$toast.open({
            message: error.message || 'Something went wrong. Please try again.',
            type: 'error'
          })
        })
        .finally(() => {
          this.currentFolderID = null
          this.isLoading = false
        })
    },
    updateShow(name, event) {
      this[name] = false
    },
    ModalAddWebsiteSave(data) {
      this.isLoading = true
      const smart_asset = {
        displayname: data.title,
        orientation: data.orientation,
        smart_asset_type: 'web',
        tag_list: data.tags.map((t) => t.text)
      }
      const property = {
        property: {
          url: { data_property: data.url },
          position: { data_property: data.position },
          run_script: { data_property: data.run_script },
          is_cache: { data_property: data.is_cache },
          custom_script: { data_property: data.custom_script }
        }
      }
      const param = { ...smart_asset, ...property }
      if (this.currentFolderActive) {
        param.current_folder = this.currentFolderActive || this.currentFolderID
        param.organization_id = this.currentOrganization || ''
      }
      SmartAssets.createSmartAssets(param)
        .then((res) => {
          if (res.status === 200) {
            this.updateAssetAfterCreating(res.data)
            this.showAddWebsite = false
            this.$toast.open({
              message: res.message || 'New Asset created',
              type: 'success'
            })
          }
        })
        .catch((error) => {
          this.showAddWebsite = false
          this.$toast.open({
            message: error.message || 'Something went wrong. Please try again',
            type: 'error'
          })
        })
        .finally(() => {
          this.showAddWebsite = false
          this.isLoading = false
        })
    },
    ModalAddYoutubeSave(data) {
      this.isLoading = true
      const smart_asset = {
        displayname: data.title,
        orientation: data.orientation,
        smart_asset_type: 'youtube',
        tag_list: data.tags.map((t) => t.text)
      }
      const property = {
        property: {
          url: { data_property: data.url },
          youtube_id: { data_property: data.youtube_id },
          seconds: { data_property: data.seconds }
        }
      }
      const param = { ...smart_asset, ...property }
      param.current_folder = this.currentFolderActive || ''
      SmartAssets.createSmartAssets(param)
        .then((res) => {
          if (res.status === 200) {
            this.updateAssetAfterCreating(res.data)
            this.showAddYoutube = false
            this.$toast.open({
              message: res.message || 'New Asset created',
              type: 'success'
            })
          }
        })
        .catch((error) => {
          this.showAddYoutube = false
          this.$toast.open({
            message: error.message || 'Something went wrong. Please try again',
            type: 'error'
          })
        })
        .finally(() => {
          this.showAddYoutube = false
          this.isLoading = false
        })
    },

    ModalAddGoogleSheetSave(data) {
      this.isLoading = true
      const smart_asset = {
        displayname: data.title,
        orientation: data.orientation,
        smart_asset_type: 'google_sheet',
        tag_list: data.tags.map((t) => t.text)
      }
      const property = {
        property: {
          url: { data_property: data.url },
          is_cache: { data_property: data.is_cache }
        }
      }
      const param = { ...smart_asset, ...property }
      if (this.currentFolderActive) {
        param.current_folder = this.currentFolderActive || this.currentFolderID
        param.organization_id = this.currentOrganization || ''
      }
      SmartAssets.createSmartAssets(param)
        .then((res) => {
          if (res.status === 200) {
            this.updateAssetAfterCreating(res.data)
            this.showAddGoogleSheet = false
            this.$toast.open({
              message: 'New Asset created',
              type: 'success'
            })
          }
        })
        .catch((error) => {
          this.showAddGoogleSheet = false
          this.$toast.open({
            message: error.message || 'Something went wrong. Please try again',
            type: 'error'
          })
        })
        .finally(() => {
          this.showAddGoogleSheet = false
          this.isLoading = false
        })
    },

    ModalAddGoogleSlideSave(data) {
      this.isLoading = true
      const smart_asset = {
        displayname: data.title,
        orientation: data.orientation,
        smart_asset_type: 'google_slide',
        tag_list: data.tags.map((t) => t.text)
      }
      const property = {
        property: {
          url: { data_property: data.url },
          is_cache: { data_property: data.is_cache }
        }
      }
      const param = { ...smart_asset, ...property }
      if (this.currentFolderActive) {
        param.current_folder = this.currentFolderActive || this.currentFolderID
        param.organization_id = this.currentOrganization || ''
      }
      SmartAssets.createSmartAssets(param)
        .then((res) => {
          if (res.status === 200) {
            this.updateAssetAfterCreating(res.data)
            this.showAddGoogleSlide = false
            this.$toast.open({
              message: 'New Asset created',
              type: 'success'
            })
          }
        })
        .catch((error) => {
          this.showAddGoogleSlide = false
          this.$toast.open({
            message: error.message || 'Something went wrong. Please try again',
            type: 'error'
          })
        })
        .finally(() => {
          this.showAddGoogleSlide = false
          this.isLoading = false
        })
    },

    ModalAddCanvaSlideSave(data) {
      this.isLoading = true
      const smart_asset = {
        displayname: data.title,
        orientation: data.orientation,
        smart_asset_type: 'canva',
        tag_list: data.tags.map((t) => t.text),
        organization_id: this.currentOrganization
      }
      const property = {
        property: {
          url: { data_property: data.url },
          is_cache: { data_property: data.is_cache }
        }
      }
      const param = { ...smart_asset, ...property }
      if (this.currentFolderActive) {
        param.current_folder = this.currentFolderActive || this.currentFolderID
        param.organization_id = this.currentOrganization || ''
      }
      SmartAssets.createSmartAssets(param)
        .then((res) => {
          if (res.status === 200) {
            this.updateAssetAfterCreating(res.data)
            this.showCanvaSlide = false
            this.$toast.open({
              message: 'New Asset created',
              type: 'success'
            })
          }
        })
        .catch((error) => {
          this.showCanvaSlide = false
          this.$toast.open({
            message: error.message || 'Something went wrong. Please try again',
            type: 'error'
          })
        })
        .finally(() => {
          this.showCanvaSlide = false
          this.isLoading = false
        })
    },
    ModalAddPdfSave(data) {
      this.isLoading = true
      let formdata = false
      if (window.FormData) {
        formdata = new FormData()
      }
      const tags = data.tags.map((item) => item.text)
      if (this.currentFolderID) {
        formdata.append('current_folder', this.currentFolderActive || this.currentFolderID)
        formdata.append('organization_id', this.currentOrganization)
      }
      formdata.append('smart_asset[displayname]', data.title)
      formdata.append('smart_asset[tag_list][]', tags)
      formdata.append('smart_asset[orientation]', data.orientation)
      formdata.append('smart_asset[smart_asset_type]', 'pdf')
      formdata.append('smart_asset_details[asset_file]', data.file)
      formdata.append('property[page_number][data_property]', data.pageNumber)
      formdata.append('property[fit][data_property]', data.orientationfit)
      SmartAssets.createSmartAssets(formdata)
        .then((res) => {
          if (res.status === 200) {
            this.updateAssetAfterCreating(res.data)
            this.showAddPdf = false
            this.$toast.open({
              message: res.message || 'New Asset created.',
              type: 'success'
            })
          }
        })
        .catch((error) => {
          this.showAddPdf = false
          this.$toast.open({
            message: error.message || 'Something went wrong. Please try again.',
            type: 'error'
          })
        })
        .finally(() => {
          this.showAddPdf = false
          this.isLoading = false
        })
    },
    ModalAddSmartAssetNext(data) {
      // data.filterOrientation = this.filterOrientation.value
      if (this.currentFolderID) {
        data.currentFolderID = this.currentFolderID
      }
      this.setNewSmartAssetDataModal(data)
      this.showSmartAssetEditor = true
      this.showAddSmartAsset = false
    },
    ModalAddInstagramNext(data) {
      this.showInstagramEditor = true
      this.showAddInstagram = false
      data.filterOrientation = this.filterOrientation.value
      if (this.currentFolderID) {
        data.currentFolderID = this.currentFolderID
      }
      localStorage.setItem('instagram', JSON.stringify(data))
    },
    ModalEditInstagramNext(data) {
      this.showInstagramEditor = true
      this.showEditInstagram = false
    },
    ModalEditImageCustomNext(data) {
      data.blocks.forEach((block) => {
        if (block.type === 'weather') {
          block.isWeeklyForecast = block.isWeeklyForecast === 'true'
        }
      })
      const { orientation, displayname, tags, smart_asset_type, blocks } = data
      this.setNewSmartAssetDataModal({ orientation, displayname, tags, smart_asset_type })
      blocks.forEach((block) => {
        if (block.type === 'datetime')
          block.timezone = block.timezoneName
            ? `${block.timezone}|${block.timezoneName}`
            : block.timezone
      })
      this.editImageCustomDataModal = data
      this.showSmartAssetEditor = true
      this.showEditImageCustom = false
    },
    shownNewAsset() {
      SmartAssets.getPermissionCreate()
        .then((res) => {
          this.showNewAsset = true
          this.$bus.$emit('reset-submitted', false)
          this.editImageCustomDataModal = {}
        })
        .catch((error) => {
          this.$toast.open({
            message: error.message || 'Something went wrong. Please try again.',
            type: 'error'
          })
        })
    },
    continueInstagram() {
      this.showNewSmartAsset = false
      this.showAddInstagram = true
    },
    continueSmartAsset() {
      this.showNewSmartAsset = false
      this.showAddSmartAsset = true
    },
    continuePdf() {
      this.showNewSmartAsset = false
      this.showAddPdf = true
    },
    continueWebsite() {
      this.showNewSmartAsset = false
      this.showAddWebsite = true
    },
    continueYoutube() {
      this.showNewSmartAsset = false
      this.showAddYoutube = true
    },
    continueGoogleSheet() {
      this.showNewSmartAsset = false
      this.showAddGoogleSheet = true
    },
    continueGoogleSlide() {
      this.showNewSmartAsset = false
      this.showAddGoogleSlide = true
    },
    continueCanva() {
      this.showNewSmartAsset = false
      this.showCanvaSlide = true
    },
    clickNewAssetUpload() {
      this.showNewAsset = false
      this.showUppy = true
    },
    clickNewAssetStart() {
      this.showNewAsset = false
      this.showNewSmartAsset = true
    },
    editfolder(data) {
      const self = this
      Folder.getDetailFolder(data.id)
        .then((res) => {
          self.showEditFolder = true
          self.dataModal = res.data
        })
        .catch((error) => {
          self.$toast.open({
            message: error.message,
            type: 'error'
          })
        })
    },
    shareFolder(data) {
      const self = this
      Folder.getDetailFolder(data.id)
        .then((res) => {
          self.showShareFolder = true
          self.dataModal = res.data
        })
        .catch((error) => {
          self.$toast.open({
            message: error.message,
            type: 'error'
          })
        })
    },
    editvideo(data) {
      const self = this
      SmartAssets.getSmartAssetsByID(data.id)
        .then((res) => {
          self.showEditVideo = true
          self.dataModal = res.data
        })
        .catch((error) => {
          this.$toast.open({
            message: error.message,
            type: 'error'
          })
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    savevideo(data) {
      data.tags = data.tags.map((i) => i.text)
      this.saveSmartAsset(data)
      this.showEditVideo = false
    },
    editimage(data) {
      const self = this
      SmartAssets.getSmartAssetsByID(data.id)
        .then((res) => {
          self.showEditPicture = true
          self.dataModal = res.data
        })
        .catch((error) => {
          this.$toast.open({
            message: error.message,
            type: 'error'
          })
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    saveimage(data) {
      data.tags = data.tags.map((i) => i.text)
      this.saveSmartAsset(data)
      this.showEditPicture = false
    },
    editpdf(data) {
      const self = this
      SmartAssets.getSmartAssetsByID(data.id)
        .then((res) => {
          res.data.tags = res.data.tags.map((i) => i.name)
          self.dataModal = res.data
          self.showEditPdf = true
        })
        .catch((error) => {
          this.$toast.open({
            message: error.message,
            type: 'error'
          })
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    editcanva(data) {
      const self = this
      SmartAssets.getSmartAssetsByID(data.id)
        .then((res) => {
          self.showEditCanva = true
          if (res.data.properties) {
            const properties = res.data.properties
            properties.run_script =
              properties.run_script !== undefined ? properties.run_script : false
            properties.is_cache = properties.is_cache !== undefined ? properties.is_cache : true
          }
          self.dataModal = res.data
        })
        .catch((error) => {
          this.$toast.open({
            message: error.message,
            type: 'error'
          })
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    confirmEditPDF(data) {
      if (data.tags.length && data.tags[0].text) {
        data.tags = data.tags.map((i) => i.text)
      }
      if (data.tags.length === 0) {
        data.tags = []
      }
      this.saveSmartAsset(data)
      this.showEditPdf = false
    },
    editinstagram(data) {
      const self = this
      SmartAssets.getSmartAssetsByID(data.id)
        .then((res) => {
          self.showEditInstagram = true
          res.data.tags = res.data.tags.map((i) => i.name)
          res.data.name = res.data.displayname
          self.dataModal = res.data
        })
        .catch((error) => {
          this.$toast.open({
            message: error.message,
            type: 'error'
          })
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    editImageCustom(data) {
      const self = this
      SmartAssets.getSmartAssetsByID(data.id)
        .then((res) => {
          self.showEditImageCustom = true
          res.data.tags = res.data.tags.map((i) => i.name)
          res.data.name = res.data.displayname
          self.dataModal = res.data
        })
        .catch((error) => {
          this.$toast.open({
            message: error.message,
            type: 'error'
          })
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    saveinstagram(data) {
      data.tags = data.tags.map((i) => i.text)
      this.saveSmartAsset(data)
      this.showEditInstagram = false
    },
    editwebsite(data) {
      const self = this
      SmartAssets.getSmartAssetsByID(data.id)
        .then((res) => {
          self.showEditWebsite = true
          if (res.data.properties) {
            const properties = res.data.properties
            properties.run_script =
              properties.run_script !== undefined ? properties.run_script : false
            properties.is_cache = properties.is_cache !== undefined ? properties.is_cache : true
          }
          self.dataModal = res.data
        })
        .catch((error) => {
          this.$toast.open({
            message: error.message,
            type: 'error'
          })
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    edityoutube(data) {
      const self = this
      SmartAssets.getSmartAssetsByID(data.id)
        .then((res) => {
          self.showEditYoutube = true
          self.dataModal = res.data
        })
        .catch((error) => {
          this.$toast.open({
            message: error.message,
            type: 'error'
          })
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    editGoogleSheet(data) {
      const self = this
      SmartAssets.getSmartAssetsByID(data.id)
        .then((res) => {
          self.showEditGoogleSheet = true
          if (res.data.properties) {
            const properties = res.data.properties
            properties.is_cache = properties.is_cache !== undefined ? properties.is_cache : true
          }
          self.dataModal = res.data
        })
        .catch((error) => {
          this.$toast.open({
            message: error.message,
            type: 'error'
          })
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    editGoogleSlide(data) {
      const self = this
      SmartAssets.getSmartAssetsByID(data.id)
        .then((res) => {
          self.showEditGoogleSlide = true
          if (res.data.properties) {
            const properties = res.data.properties
            properties.is_cache = properties.is_cache !== undefined ? properties.is_cache : true
          }
          self.dataModal = res.data
        })
        .catch((error) => {
          this.$toast.open({
            message: error.message,
            type: 'error'
          })
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    savewebsite(data) {
      data.tags = data.tags.map((i) => i.text)
      this.saveSmartAsset(data)
      this.showEditWebsite = false
    },
    saveYoutube(data) {
      data.tags = data.tags.map((i) => i.text)
      this.saveSmartAsset(data)
      this.showEditYoutube = false
    },
    saveGoogleSheet(data) {
      data.tags = data.tags.map((i) => i.text)
      this.saveSmartAsset(data)
      this.showEditGoogleSheet = false
    },
    saveGoogleSlide(data) {
      data.tags = data.tags.map((i) => i.text)
      this.saveSmartAsset(data)
      this.showEditGoogleSlide = false
    },
    saveCanva(data) {
      data.tags = data.tags.map((i) => i.text)
      this.saveSmartAsset(data)
      this.showEditCanva = false
    },
    confirmDeleteItem(data) {
      this.isLoading = true
      const self = this
      SmartAssets.deleteSmartAssetsByID(data.id)
        .then((res) => {
          self.showDeleteItem = false
          this.handleDeleteById(res.data, this.user.id)
          this.$toast.open({
            type: 'success',
            message: res.message || 'Asset deleted'
          })
        })
        .catch((err) => {
          this.showDeleteItem = false
          if (!err.data) {
            this.$toast.open({
              type: 'error',
              message: err.message
            })
            return false
          }
          let customErrMessage = ''
          if (err.data && err.data.length > 1) {
            const arrLoop = err.data.slice(0, 3)
            let loops = ''
            arrLoop.forEach((element, idx, array) => {
              if (idx === array.length - 1) {
                loops += `${element.displayname}`
              } else {
                loops += `${element.displayname}, `
              }
            })
            customErrMessage = `This asset is used in multiple loops including ${loops}. Please remove it from these loops before deleting it`
          } else {
            customErrMessage = `This asset is used in the loop ${err.data[0].displayname}. Please remove it from the loop before deleting it`
          }

          const errorMessage = err.data ? customErrMessage : err.message
          this.$toast.open({
            type: 'error',
            message: errorMessage,
            duration: 5000,
            onClick: () => {
              this.$router.push(`loop_assets/${err.data[0].id}/edit`)
            }
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    confirmDeleteItemBulk({ dataInput: data }) {
      const folders = []
      const assets = []

      data.forEach((obj, index) => {
        if (obj.type === 'folder') {
          folders.push(obj.id)
        } else {
          assets.push(obj.id)
        }
      })

      const ids = {
        folders: folders,
        assets: assets
      }
      this.isLoading = true
      this.showDeleteItemBulk = false
      Folder.deleteFolderByIDs(ids)
        .then((res) => {
          this.isLoading = false
        })
        .catch((error) => {
          this.isLoading = false
          this.$toast.open({
            message: error.message || 'Something went wrong. Please try again.',
            type: 'error'
          })
        })
    },
    getListAsset(page, limit, search = '', type, orientation) {
      if (this.typeShowSmartAsset === 'main') {
        this.getListSmartAsset({
          page,
          limit,
          search,
          type,
          orientation
        })
        this.typeShowSmartAsset = 'main'
        const selected = window.slVueTree.getSelected()
        if (selected) {
          window.slVueTree.updateNode(selected.path, { isSelected: false })
        }
      } else if (this.typeShowSmartAsset === 'folder') {
        this.getListSmartAssetInFolder({
          id: this.currentFolderID,
          page_name: 'library',
          page,
          limit,
          search,
          type,
          orientation
        })
      }
    },
    sortItem(type = '') {
      const group = this.items.reduce((r, a) => {
        r[a.type] = [...(r[a.type] || []), a]
        return r
      }, {})
      const folderSubOrganization = group.folder
        ? group.folder.filter((x) => x.folder_type === 'sub_organization')
        : []
      let groupItems = this.items.filter((i) => i.folder_type !== 'sub_organization')

      switch (type) {
        case 'size':
          groupItems.sort((a, b) => this.compareByKey(a, b, 'size'))
          break
        case 'duration':
          groupItems.sort((a, b) => this.compareByKey(a, b, 'duration'))
          break
        case 'date':
          groupItems.sort((a, b) => {
            return Date.parse(a.created_at) - Date.parse(b.created_at)
          })
          break
        case 'name':
          groupItems.sort((a, b) => {
            return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
          })
          break

        default:
          break
      }
      const items = this.items.filter((x) => x.folder_type !== 'sub_organization')
      if (JSON.stringify(items) === JSON.stringify(groupItems)) {
        switch (type) {
          case 'size':
            groupItems.sort((a, b) => this.compareByKey(a, b, 'size')).reverse()
            break
          case 'duration':
            groupItems.sort((a, b) => this.compareByKey(a, b, 'duration')).reverse()
            break
          case 'date':
            groupItems = groupItems.reverse()
            break
          case 'name':
            groupItems = groupItems.reverse()
            break

          default:
            break
        }
        // groupItems = groupItems.reverse()
      }
      // let nodesSort = JSON.parse(JSON.stringify(list))
      //   .map(item => {
      //     item.created_at = new Date(item.created_at)
      //     return item
      //   })
      //   .sort((a, b) =>
      //     compare(a, b)
      //   )
      // let items = this.items.slice(0, list.length)
      // var result = this.items.filter(o1 => list.filter(o2 => o2.id === o1.id).length === 0)

      // if (JSON.stringify(items) === JSON.stringify(nodesSort)) {
      //   nodesSort = nodesSort.reverse()
      // }
      // let afterSoft = nodesSort.concat(result)
      groupItems = [...folderSubOrganization, ...groupItems]

      const isAllSizesNull = groupItems.every(
        (item) => item.size === undefined || item.size === null || item.size === ''
      )
      const isAllDurationNull = groupItems.every(
        (item) => item.duration === undefined || item.duration === null || item.duration === ''
      )

      if ((type === 'size' && isAllSizesNull) || (type === 'duration' && isAllDurationNull)) {
        // Don't change anything
      } else {
        this.setItem({ items: groupItems })
      }
    },
    handleSortTreeFolder(type = '') {
      const node = window.slVueTree.getNodeById(this.currentFolderID)
      const children = get(node, 'children', [])
      if (!isEmpty(children)) {
        let groupItems = children.filter((i) => i.folder_type !== 'sub_organization')
        groupItems = this.sortTreeFolder(children, type)
        const isAllSizesNull = groupItems.every(
          (item) => item.data.size === undefined || item.data.size === null || item.data.size === ''
        )
        const isAllDurationNull = groupItems.every(
          (item) =>
            item.data.duration === undefined ||
            item.data.duration === null ||
            item.data.duration === ''
        )

        if ((type === 'size' && isAllSizesNull) || (type === 'duration' && isAllDurationNull)) {
          // Don't change anything
        } else {
          this.setTreeFolderById({ id: this.currentFolderID, data: groupItems })
        }
      } else {
        const treeFolder = cloneDeep(this.treeFolder)
        const folderSubOrganization = !isEmpty(treeFolder)
          ? treeFolder.filter((x) => x.data.folder_type === 'sub_organization')
          : []
        let groupItems = treeFolder.filter((i) => i.data.folder_type !== 'sub_organization')
        groupItems = this.sortTreeFolder(groupItems, type)
        groupItems = [...folderSubOrganization, ...groupItems]
        const isAllSizesNull = groupItems.every((item) => {
          const size = get(item, 'data.size', null)
          return size === undefined || size === null || size === ''
        })
        const isAllDurationNull = groupItems.every((item) => {
          const duration = get(item, 'data.duration', null)
          return duration === undefined || duration === null || duration === ''
        })

        if ((type === 'size' && isAllSizesNull) || (type === 'duration' && isAllDurationNull))
          return
        this.setTreeFolder(groupItems)
      }
    },
    sortTreeFolder(items, type) {
      let itemsClone = cloneDeep(items)
      switch (type) {
        case 'size':
          itemsClone.sort((a, b) => this.compareByKey(a.data, b.data, 'size'))
          break
        case 'duration':
          itemsClone.sort((a, b) => this.compareByKey(a.data, b.data, 'duration'))
          break
        case 'date':
          itemsClone.sort((a, b) => {
            return Date.parse(a.data.created_at) - Date.parse(b.data.created_at)
          })
          break
        case 'name':
          itemsClone.sort((a, b) => {
            return a.data.name > b.data.name ? 1 : a.data.name < b.data.name ? -1 : 0
          })
          break

        default:
          break
      }
      if (JSON.stringify(items) === JSON.stringify(itemsClone) && this.clickSortNumber > 1) {
        switch (type) {
          case 'size':
            itemsClone.sort((a, b) => this.compareByKey(a.data, b.data, 'size')).reverse()
            break
          case 'duration':
            itemsClone.sort((a, b) => this.compareByKey(a.data, b.data, 'duration')).reverse()
            break
          case 'date':
            itemsClone = itemsClone.reverse()
            break
          case 'name':
            itemsClone = itemsClone.reverse()
            break

          default:
            break
        }
      }
      return itemsClone
    },
    compareByKey(a, b, key) {
      // Handle null sizes by assigning a very large value
      const sizeA = a[key] !== null ? a[key] : Number.MAX_SAFE_INTEGER
      const sizeB = b[key] !== null ? b[key] : Number.MAX_SAFE_INTEGER

      // Compare sizes
      return sizeA - sizeB
    },
    compareName(a, b) {
      return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
    },
    compareSize(a, b) {
      return a.size > b.size ? -1 : a.size < b.size ? 1 : 0
    },
    compareDuration(a, b) {
      return (a.duration || 0) > (b.duration || 0)
        ? -1
        : (a.duration || 0) < (b.duration || 0)
        ? 1
        : 0
    },
    compareDate(a, b) {
      return (a.created_at || Date.now()) > (b.created_at || Date.now())
        ? -1
        : (a.created_at || Date.now()) < (b.created_at || Date.now())
        ? 1
        : 0
    },
    sortByName() {
      this.clickSortNumber++
      this.sortItem('name')
      this.handleSortTreeFolder('name')
    },
    sortBySize() {
      this.clickSortNumber++
      this.sortItem('size')
      this.handleSortTreeFolder('size')
    },
    sortByDuration() {
      this.clickSortNumber++
      this.sortItem('duration')
      this.handleSortTreeFolder('duration')
    },
    sortByDate() {
      this.clickSortNumber++
      this.sortItem('date')
      this.handleSortTreeFolder('date')
    },
    listOptionAsset() {
      return [
        {
          name: this.$t('all-assets') + ` (${this.statistical.total})`,
          value: ''
        },
        {
          name: this.$t('videos') + ` (${this.statistical.video})`,
          value: typeFile.video
        },
        {
          name: this.$t('images') + ` (${this.statistical.image})`,
          value: typeFile.image
        },
        {
          name: `Screen Editor (${this.statistical.custom_asset})`,
          value: typeFile.custom_asset
        },
        {
          name: this.$t('pdf') + ` (${this.statistical.pdf})`,
          value: typeFile.pdf
        },
        {
          name: this.$t('website') + ` (${this.statistical.web})`,
          value: typeFile.website
        },
        {
          name: this.$t('instagram') + ` (${this.statistical.instagram})`,
          value: typeFile.instagram
        },
        {
          name: this.$t('folder') + ` (${this.statistical.folder})`,
          value: typeFile.folder
        },
        {
          name: this.$t('youtube') + ` (${this.statistical.youtube})`,
          value: typeFile.youtube
        },
        {
          name: `Google Sheet (${this.statistical.google_sheet})`,
          value: typeFile.google_sheet
        },
        {
          name: `Google Slide (${this.statistical.google_slide})`,
          value: typeFile.google_slide
        }
      ]
    },
    listOptionOrientation() {
      return [
        {
          name: this.$t('both'),
          value: ''
        },
        {
          name: this.$t('portrait'),
          value: 'portrait'
        },
        {
          name: this.$t('landscape'),
          value: 'landscape'
        }
      ]
    },
    listOptionPage() {
      return [
        {
          name: '25 / ' + this.$t('page'),
          value: '25'
        },
        {
          name: '50 / ' + this.$t('page'),
          value: '50'
        },
        {
          name: '100 / ' + this.$t('page'),
          value: '100'
        }
      ]
    },
    getUser() {
      const user = this.$cookies.get('user')
      if (user) {
        this.user = user
        this.limitPage = {
          name: `${user.default_number_asset || 25} / ${this.$t('page')}`,
          value: `${user.default_number_asset || 25}`
        }
      }
    },
    searchInputChange(value) {
      this.dataSearch = value
      this.getListSmartAssetByFilter()
    },
    changePage(value) {
      this.currentPage = value
      this.getListSmartAssetByFilter(this.currentPage)
    },
    getListSmartAssetByFilter(page = 1) {
      this.currentPage = page
      this.getListAsset(
        this.currentPage,
        this.limitPage.value,
        this.dataSearch,
        this.filterAssets.value,
        this.filterOrientation.value
      )
    },
    resetFilter() {
      this.$bus.$emit('resetSeach', '')
      this.dataSearch = ''
      this.filterOrientation.name = this.$t('both')
      this.filterOrientation.value = ''
      this.filterAssets.value = ''
    },
    updateCountAsset(typeAsset, type = 'create') {
      this.updateCountAssetStatistical({ type: type, typeAsset: typeAsset })
    },
    subscribeChannel() {
      this.$cable.subscribe({
        channel: 'LibraryChannel',
        organization: this.currentOrganization
      })
      this.$cable.subscribe({
        channel: 'FolderLibraryChannel',
        organization: this.currentOrganization
      })
    },
    removeSocket() {
      this.$cable.unsubscribe('LibraryChannel')
      this.$cable.unsubscribe('FolderLibraryChannel')
    },
    translateMultiselect() {
      const listOptionAsset = this.listOptionAsset()
      const type = listOptionAsset.find((option) => option.value === this.filterAssets.value)
      this.filterAssets.name = type.name

      const listOptionOrientation = this.listOptionOrientation()
      const orientation = listOptionOrientation.find(
        (option) => option.value === this.filterOrientation.value
      )
      this.filterOrientation.name = orientation.name
    },
    changeLimitPage(data) {
      this.currentPage = 1
      this.limitPage = data
      this.getListSmartAssetByFilter()
    },
    updateAssetAfterCreating(data) {
      const folderId = data.type === 'folder' ? data.id : get(data, 'folder.id', null)
      const node = window.slVueTree.getNodeById(folderId)
      const isExistedInItems = this.items.filter((x) => x.id === data.id).length > 0
      if (
        isEmpty(node) &&
        !isExistedInItems &&
        (this.filterOrientation.value === '' ||
          this.filterOrientation.value === data.orientation ||
          data.type === 'folder')
      ) {
        this.updateCountAsset(data.type)
        this.createFromSocket({
          data,
          currentOrganization: this.user.organization_id
        })
      }
    },
    handleDeleteById(data, userId = null, fromSocket = false) {
      const folderId = data.type === 'folder' ? data.id : get(data, 'folder.id', null)
      const node = window.slVueTree.getNodeById(folderId)
      const isExistedInItems = this.items.filter((x) => x.id === data.id).length > 0
      if (!isEmpty(node) || isExistedInItems) {
        this.updateCountAsset(data.type, 'delete')
        this.deleteFromSocket({ data })
        if (
          data.id === this.currentFolderActive &&
          (this.user.id !== userId || (this.user.id === userId && fromSocket))
        ) {
          this.isDeleteFolderBySomeOne = true
          this.$nextTick(() => {
            this.clickFolderDefault()
          })
        }
      }
    },
    handleUpdateFromSocket(data, fromSocket = false) {
      if (fromSocket && this.isUpdatedFromSocket) {
        this.isUpdatedFromSocket = false
        return
      }
      if (!fromSocket) {
        this.isUpdatedFromSocket = true
      }
      this.$nextTick(() => {
        this.updateFromSocket({ data })
      })
    }
  },
  beforeDestroy() {
    this.$bus.$off('onSearchInputChange')
    this.removeSocket()
    this.setDefaultBreadcrumb()
    window.removeEventListener('resize', () => {
      this.setDivHeight('topheader')
    })
  },

  computed: {
    ...mapState('library', {
      libraryPage: 'libraryPage',
      error: 'error',
      loading: 'loading'
    }),
    ...mapGetters('library', {
      isEmpty: 'isEmpty',
      items: 'items',
      treeFolder: 'treeFolder',
      breadcrumb: 'breadcrumb',
      pagination: 'pagination',
      total: 'totalPagination',
      page: 'pagePagination',
      limit: 'limitPagination',
      statistical: 'statistical'
    }),
    optionAssests() {
      return this.listOptionAsset()
    },
    optionOrientations() {
      return this.listOptionOrientation()
    },
    optionPages() {
      return this.listOptionPage()
    },
    isDefaultBreadcrumb() {
      return get(this.breadcrumb, `[${this.breadcrumb.length - 1}].type`, '') === 'main'
    },
    currentFolderActive() {
      return get(this.breadcrumb, `[${this.breadcrumb.length - 1}].id`, null)
    }
  },
  channels: {
    LibraryChannel: {
      connected() {},
      rejected() {},
      received(data) {
        if (data.action === 'create') {
          this.updateAssetAfterCreating(data.data)
        } else if (data.action === 'delete') {
          this.updateCountAsset(data.data.type, 'delete')
          this.deleteFromSocket({ data: data.data })
        } else if (data.action === 'update') {
          if (this.filterOrientation.value) {
            if (this.filterOrientation.value === data.data.orientation) {
              this.updateCountAsset(data.data.type)
              if (this.items.filter((x) => x.id === data.data.id).length > 0) {
                this.updateFromSocket({ data: data.data })
              } else {
                this.createFromSocket({
                  data: data.data,
                  currentOrganization: this.user.organization_id
                })
              }
            } else {
              this.updateCountAsset(data.data.type, 'delete')
              this.deleteFromSocket({ currentFolderID: this.currentFolderID, data: data.data })
            }
          } else {
            this.updateFromSocket({ data: data.data })
          }
        } else if (data.action === 'drag') {
          this.handleUpdateFromSocket(data.data, true)
        }
      },
      disconnected() {}
    },
    FolderLibraryChannel: {
      connected() {},
      rejected() {},
      received(data) {
        if (get(data, 'data.folder_type', null) !== 'library') return
        if (data.action === 'create') {
          this.updateAssetAfterCreating(data.data)
        } else if (data.action === 'delete') {
          setTimeout(() => {
            this.handleDeleteById(data.data, data.user_id, true)
          }, 200)
        } else if (data.action === 'update') {
          this.$nextTick(() => {
            this.updateFromSocket({
              data: data.data
            })
          })
          if (
            (get(this.breadcrumb, `[${this.breadcrumb.length - 1}].type`, '') === 'main' &&
              !get(data, 'data.parent_id', null) &&
              !get(data, 'data.folder.parent_id', null)) ||
            (get(data, 'data.type', '') === 'folder' &&
              this.currentFolderActive === get(data, 'data.id', null))
          ) {
            this.getListSmartAssetByFilter()
          }
        }
      },
      disconnected() {}
    }
  },
  mounted() {
    this.trackingResponsive('breadcrumb')
    this.trackingResponsive('topheader')
    window.addEventListener('resize', () => {
      this.setDivHeight('topheader')
    })
    this.getUser()
    this.currentOrganization = this.user.organization_id
    const pages = document.getElementById('pages')
    pages.scrollTop = 0
    this.getListSmartAsset({
      page: 1,
      limit: this.limitPage.value
    })
    this.$bus.$on('onSearchInputChange', (value) => {
      this.dataSearch = value
      this.getListSmartAssetByFilter()
    })
    this.getListTreeFolder()
    this.$cable.subscribe({
      channel: 'LibraryChannel',
      organization: this.currentOrganization
    })
    this.$cable.subscribe({
      channel: 'FolderLibraryChannel',
      organization: this.currentOrganization
    })
    this.$bus.$on('closeModal', (value) => {
      // this.getListSmartAssetByFilter()
    })
  }
}
</script>
<style lang="scss" scoped>
@import './LibraryPage.scss';
</style>

<style lang="scss"></style>
